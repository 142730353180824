export default {
    catalogMain: {
        desktop:380,
        desktop2x:380,
        tablet: 330,
        tablet2x: 330,
        mobile: 300,
        mobile2x: 300,
    },
    catalogSmallColor: {
        desktop:85,
        desktop2x:85,
        tablet: 85,
        tablet2x: 85,
        mobile: 85,
        mobile2x: 85,
    },

    cardDescription: {
        desktop:600,
        desktop2x:600,
        tablet: 600,
        tablet2x: 600,
        mobile: 600,
        mobile2x: 600,
    },
    cardMain: {
        desktop:600,
        desktop2x:600,
        tablet: 800,
        tablet2x: 800,
        mobile: 450,
        mobile2x: 450,
    },
    cardMainZoom: {
        desktop:1560,
        desktop2x:3220,
        tablet: 600,
        tablet2x: 1200,
        mobile: 600,
        mobile2x: 1200,
    },
    cardThumbnail: {
        desktop:60,
        desktop2x:120,
        tablet: 60,
        tablet2x: 120,
        mobile: 60,
        mobile2x: 120,
    },
    accountCartWidget: {
        desktop:190,
        desktop2x:380,
        tablet: 240,
        tablet2x: 480,
        mobile: 140,
        mobile2x: 280,
    },
    productAddedToCart: {
        desktop:150,
        desktop2x:300,
        tablet: 150,
        tablet2x: 150,
        mobile: 150,
        mobile2x: 150,
    },

    productInOrder: {
        desktop:105,
        desktop2x:210,
        tablet: 105,
        tablet2x: 210,
        mobile: 105,
        mobile2x: 210,
    },

    productCarousel: {
        desktop:260,
        desktop2x:520,
        tablet: 240,
        tablet2x: 480,
        mobile: 150,
        mobile2x: 300,
    },

    orderProductImage: {
        desktop:70,
        desktop2x:140,
        tablet: 80,
        tablet2x: 160,
        mobile: 80,
        mobile2x: 160,
    }


}
