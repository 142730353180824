const requestsList = {}
export const state = () => ({
    list: [],
    favoriteCount: null
});

export const getters = {
    getFavoriteList: ({ list }) => list,
    isProductFavorite: ({ list }) => id => { return  !!(list.length && !!list.find(i => i.id === id)) },
    getFavoriteCount: ({ favoriteCount }) => favoriteCount
};

export const mutations = {
    [types.SET_FAVORITE_LIST] (state, data) {
        state.list = data
    },
    [types.SET_FAVORITE_COUNT] (state, data) {
        state.favoriteCount = data
    },
};

export const actions = {
    async getFavorite ({dispatch}) {
        requestsList.getFavorite = this.$axios.CancelToken.source()
        await this.$axios.get(`${API.main}/favorites/get`, {
            params: {
                expand: AppConfig.store.loadFavoriteExpand.join(',')
            },
            cancelToken: requestsList.getFavorite.token
        }).then(function (response) {
            dispatch('updateFavoriteState', response.data)
        });
    },
    async addToFavorite({dispatch}, id ) {
        await this.$axios.get(`${API.main}/favorites/add-product`, {
            params: {
                productId: id
            }
        }).then(async function (response) {
            await dispatch('getFavorite');
        });
    },
    async removeFromFavorite({dispatch}, id ) {
        await this.$axios.get(`${API.main}/favorites/remove-product`, {
            params: {
                productId: id
            }
        }).then(async function (response) {
            await dispatch('getFavorite');
        });
    },

    updateFavoriteState({commit, dispatch}, data) {
        commit(types.SET_FAVORITE_LIST, data.products);
        commit(types.SET_FAVORITE_COUNT, data.count);
    },


};
