var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"resizer",rawName:"v-resizer",value:(_vm.getWindowWidth),expression:"getWindowWidth"}],staticClass:"product-carousel",class:{
        'product-carousel--add-pad': _vm.showAllItemsLink,
     }},[(_vm.computedTitle)?_c('SectionHeader',{attrs:{"title":_vm.computedTitle,"description":_vm.innerData.description,"link":_vm.innerData.link}}):_vm._e(),_vm._v(" "),_c('slide-carousel',{attrs:{"show-dots":true,"id":("" + (_vm.innerData.id)),"top-position":'117px'}},_vm._l((_vm.items),function(item,index){return _c('item',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
                  callback: function (isVisible, entry) {_vm.productVisibilityChanged(isVisible, entry, item, index+1)},
                  once: true,
                  intersection: {
                    threshold: 0.7,
                  },
                }),expression:"{\n                  callback: (isVisible, entry) => {productVisibilityChanged(isVisible, entry, item, index+1)},\n                  once: true,\n                  intersection: {\n                    threshold: 0.7,\n                  },\n                }"}],key:index,attrs:{"item":item,"position":index+1,"widget-name":_vm.widgetInfo.name,"slide-width":_vm.slideWidth}})}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }