export default {
    notFound: 'Таблиця не знайдена',
    shoes: {
        title: "ЧОЛОВІЧІ І ЖІНОЧІ РОЗМІРИ ВЗУТТЯ",
        description: "У таблиці вказана довжина стопи. Довжина устілки більше довжини стопи на величину функціонального припуску 10-15 мм.",
        howFindSize: "ЯК ДІЗНАТИСЯ СВІЙ РОЗМІР",
        tabs: {
            title1: "дюйми",
            title2: "см"
        },
        table: {
            headers: {
                inch: {
                    title: "Довжина стопи (INCH)",
                },
                centimeter: {
                    title: "Довжина стопи"
                }
            },
            body: {
                inch: {
                    subtitle1: "UK",
                    subtitle2: "EU",
                    subtitle3: "US - Чоловіки",
                    subtitle4: "US - Жінки",
                    subtitle5: "Довжина стопи (см)",
                },
                centimeter: {
                    subtitle1: "UA",
                    subtitle2: "EU",
                    subtitle3: "US - Чоловіки",
                    subtitle4: "US - Жінки",
                    subtitle5: "UK",
                }
            },
            description: "Прокрути, щоб побачити більше"
        },
        questions: {
            block1: {
                title: "ОБИРАЄШ між двома РОЗМІРАМИ?",
                description1: "Для більш щільної посадки вибери модель на розмір менше.",
                description2: "Для більш вільної посадки вибери модель на розмір більше."
            },
            block2: {
                title: "ЯК ДІЗНАТИСЯ СВІЙ РОЗМІР?",
                description: "Слідуй цим простим порадам, щоб визначити свій розмір. Для кращого результату, краще проводити вимірювання ввечері. Звертаємо увагу, що довжина устілки більше довжини стопи на величину функціонального припуску 10-15 мм. Прохання орієнтуватися на довжину стопи, яка вказана в таблиці розмірів.",
                option1: "Постав ногу на аркуш паперу, щоб п'ята торкалася до стіни.",
                option2: "Відзнач місце, де закінчується найдовший палець (можливо, тобі знадобиться допомога ще однієї людини), і виміряй відстань від цієї позначки до стіни.",
                option3: "Виконай те ж саме з іншою ногою і порівняй виміри з нашою таблицею розмірів, щоб знайти потрібний розмір.",
            },
            block3: {
                title: "Не підійшов РОЗМІР АБО КОЛІР?",
                description: "Нічого страшного, ти можеш безкоштовно повернути товар."
            }
        },
        goUp: "Угору"
    },
    childrenShoes: {
        title: "ПІДІБРАТИ РОЗМІР?",
        anchor1: "МАЛЮКИ (0-4 РОКИ)",
        anchor2: "ДІТИ (4-8 РОКІВ)",
        anchor3: "ПІДЛІТКИ (8-16 РОКІВ)",
        anchor4: "ЯК ДІЗНАТИСЯ СВІЙ РОЗМІР",
        howFindSize: "ЯК ДІЗНАТИСЯ СВІЙ РОЗМІР",
        table: {
            body: {
                subtitle1: "UK",
                subtitle2: "US",
                subtitle3: "EU",
            },
        },
        group1: {
            title: "ХЛОПЧИКИ ТА ДІВЧАТА 0-4 РОКИ",
            bannerTitle: "МАЛЮКИ"
        },
        group2: {
            title: "ХЛОПЧИКИ ТА ДІВЧАТА 4-8 РОКІВ",
            bannerTitle: "ДІТИ"
        },
        group3: {
            title: "ХЛОПЧИКИ ТА ДІВЧАТА 8-16 РОКІВ",
            bannerTitle: "ПІДЛІТКИ"
        },
        buy: "Купити",
        slider: {
            title: "Корисна порада",
            sliderItem1: {
                title: "ВИЗНАЧЕННЯ розміра по устілці",
                description: "У взуття для малюків і дітей є вимірювальна устілка adiFIT. Вийміть устілку adiFIT з кросівок"
            },
            sliderItem2: {
                title: "Поставте стопи на устілці",
                description: "Попроси дитини поставить ногу на устілку adiFIT, щоб легко визначити потрібний розмір"
            },
            sliderItem3: {
                title: "Поставте стопи на устілці",
                description: "Якщо стопа НЕ виходить за маркування, значить, цей розмір підійде."
            }
        }
    },
    childrenClothes: {
        kidTable: {
            headers: {
                subtitle1: "мітка",
                subtitle2: "0 МІСЯЦІВ | 56",
                subtitle3: "0-3 МІСЯЦІВ | 62",
                subtitle4: "3-6 МІСЯЦІВ | 68",
                subtitle5: "6-9 МІСЯЦІВ | 74",
                subtitle6: "9-12 МІСЯЦІВ | 80",
                subtitle7: "12-18 МІСЯЦІВ | 86",
                subtitle8: "1-2 роки | 92",
                subtitle9: "2-3 роки | 98"
            },
            body: {
                subtitle1: "ЗРІСТ",
                subtitle2: "ГРУДИ",
                subtitle3: "ТАЛІЯ",
                subtitle4: "БЕДРА",
                subtitle5: "Довжина внутрішнього шва",
            }
        },
        childTable: {
            headers: {
                subtitle1: "мітка",
                subtitle2: "3-4 роки | 104",
                subtitle3: "4-5 років | 110",
                subtitle4: "5-6 років | 116",
                subtitle5: "6-7 років | 122 "
            },
        },
        teenTable: {
            headers: {
                subtitle1: "мітка",
                subtitle2: "7-8 років | 128",
                subtitle3: "8-9 років | 134",
                subtitle4: "9-10 років | 140",
                subtitle5: "10-11 років | 146 ",
                subtitle6: " 11-12 років | 152 ",
                subtitle7: " 12-13 років | 158 ",
                subtitle8: " 13-14 років | 164 ",
                subtitle9: " 14-15 років | 170 ",
                subtitle10: " 15-16 років | 176 ",
            },
        },
        questions: {
            block2: {
                description: "Візьми сантиметр, зніми мірки, запиши отримані результати і подивися в нашій таблиці, " +
                    "який розмір підійде найкраще. Твій друг або подруга зможуть тобі в цьому допомогти.",
                option1: "Тримай сантиметр горизонтально, якщо хочеш виміряти:",
                option2: "<span> 1. Груди, </span> Обхват самої виступаючої частини грудей",
                option3: "<span> 2. Талія, </span> Обсяг самої вузької частини талії",
                option4: "<span> 3. Стегна, </span> Обсяг найширшої частини стегон, поставивши ноги разом.",
                option5: "Тримай сантиметр вертикально, якщо хочеш виміряти такі параметри:",
                option6: "<span> 4. Довжина по внутрішньому шву, </span> Це відстань від клина крокового шва до підлоги",
                option7: "<span> 5. Зріст, </span> Відстань від п'ят до маківки. При знятті мірок потрібно стояти прямо."
            },
        },
    },
    womenClothes: {
        title: "ЖІНОЧИЙ ОДЯГ",
        table: {
            header: {
                title1: "Розмір",
                title2: "Розмір<br>UA",
                title3: "Німецький розмір<br>(D)",
                title4: "Груди<br>(см)",
                title5: "Талія<br>(см)",
                title6: "Стегна<br>(см)",
            },
            description1: "Для деяких моделей доступна різна довжина:",
            description2: "long - вибери, якщо бажаєш подовжену модель",
            description3: "short - вибери, якщо бажаєш укорочену модель",
            description4: "tall - вибери, якщо потрібно, щоб модель була розрахована на ріст вище середнього",
        },
        tip1: {
            title: "ЯК ПІДІБРАТИ СВІЙ<br> РОЗМІР",
            description: "Слідкуй інструкції, щоб дізнатися свій розмір одягу:",
            option1: {
                title: "ГРУДИ",
                description: "Щоб визначити свій розмір футболки, виміряйте Кравецьким сантиметром обхват найширшої частини грудей. Тримайте сантиметр горизонтально."
            },
            option2: {
                title: "ТАЛІЯ",
                description: "Виміряйте обсяг самої вузької частини талії, тримаючи кравецький сантиметр горизонтально."
            },
            option3: {
                title: "СТЕГНА",
                description: "Поставте ноги разом і виміряйте об'єм найширшої частини стегон. Тримайте кравецький сантиметр горизонтально."
            }
        },
        tip2: {
            title: "ОБЕРІТЬ ПРАВИЛЬНИЙ<br> РОЗМІР",
            description1: "Якщо значення обхвату грудей і талії відповідають різним розмірам, виберіть той, якому відповідає значення обхвату грудей",
            description2: "Розмір не підійшов? Не хвилюйтесь! Проста і безкоштовна процедура повернення доступна протягом 60 днів. Ознайомтеся з умовами повернення, щоб дізнатися більше."
        }
    },
    womenSwimsuits: {
        title: "ЖІНОЧІ КУПАЛЬНИКИ",
        table: {
            header: {
                title1: "Розмір",
                title2: "Розмір<br>UA",
                title3: "Розмір на ярлику<br>(D)",
                title4: "Груди<br>(см)",
                title5: "Талія<br>(см)",
                title6: "Стегна<br>(см)",
                title7: "Тулуб<br>(обхват)<br>(см)",
            },
        },
        tip1: {
            option4: {
                title: "ВЕРТИКАЛЬНИЙ Обхват ТУЛУБА",
                description: "Почніть вимірювати від точки основи шиї на правому плечі, зійдіть по прямій через тулуб і поверніться до вихідної точки з боку спини, як показано на малюнку."
            }
        },
        tip2: {
            title: "ОБЕРІТЬ ПРАВИЛЬНИЙ РОЗМІР",
            description1: "Якщо значення стегон і талії відповідають різним розмірам, виберіть той, якому відповідає значення обхвату стегон.",
            description2: "Розмір не підійшов? Не хвилюйтесь! Проста і безкоштовна процедура повернення доступна протягом 60 днів. Ознайомтеся з умовами повернення, щоб дізнатися більше."
        }
    },
    womenTrousers: {
        title: "ЖІНОЧІ БРЮКИ ТА<br>  ШОРТИ",
        table: {
            header: {
                title1: "Розмір",
                title2: "Розмір<br>UA",
                title3: "Німецький розмір<br>(D)",
                title4: "Талія<br>(см)",
                title5: "Стегна<br>(см)",
            }
        },
        tip1: {
            description: "Наступні дії, щоб визначити свій розмір одягу:",
            option4: {
                title: "ДОВЖИНА ПО ВНУТРІШНЬОМУ ШВУ",
                description: "Виміряйте відстань від верхньої частини стегон до підлоги, як показано на малюнку"
            }
        }
    },
    manTrousers: {
        title: "ЧОЛОВІЧІ БРЮКИ ТА<br>  ШОРТИ",
    },
    manClothes: {
        title: "ЧОЛОВІЧИЙ ОДЯГ",
    },
    summerShoes: {
        title: "Шльопанці і сандалії",
        subtitle1: "<span>Увага! На коробці і бирці виробу вказані іноземні розміри </span><br>Дізнайся відповідність UA c UK, US, FR по таблиці.",
        subtitle2: "Довжина устілки більше довжини стопи на величину функціонального припуску 10-15 мм.",
        tip: {
            title: "Виміряйте довжину ВІД носка до п'яти",
            description1: "Ми не рекомендуємо вимірювати стопу вранці. Зазвичай ноги злегка набрякають до вечора через спеку і навантажень. Найкраще вимірювати стопу ввечері.",
            description2: "Наступні дії, щоб визначити свій розмір взуття:",
            description3: "Покладіть аркуш паперу на підлогу однією стороною до стіни. Поставте ногу на лист паперу п'ятою до стіни. Олівцем відзначте лінію кінчиків пальців.",
            description4: "Виміряйте відстань від краю аркуша до позначки і зіставте зі значенням в таблиці.",
        },
        tip1: {
            description1: "Легше сказати, ніж зробити. Що робити, якщо Ви потрапили сюди в проміжку між двома розмірами?",
            description2: "В цьому випадку безпечніше вибрати більший розмір.",
            description3: "Розмір не підійшов? Не переживайте! Проста і безкоштовна процедура повернення доступна протягом 60 днів. Ознайомтеся з умовами повернення, щоб дізнатися більше.",
        },
        table: {
            header: {
                title1: "UA",
                title2: "FR / EUR",
                title3: "UK",
                title4: "US ЧОЛ",
                title5: "US ЖІН",
                title6: "Довжина стопи",
            }
        }
    },
    wTights: {
        title: "ЛЕГІНСИ ДЛЯ ЖІНОК - ПОРАДИ ЩОДО РОЗМІРУ",
        anchors: [
            {
                title: 'ІНСТРУКЦІЯ З ПОСАДКИ',
                link: 'info'
            },
            {
                title: 'СТАНДАРТ',
                link: 'standart'
            },
            {
                title: 'ПОДОВЖЕНІ',
                link: 'tall'
            },
            {
                title: 'УКОРОЧЕНІ',
                link: 'petite'
            },
            {
                title: 'ПЛЮС САЙЗ',
                link: 'plusSize'
            },
            {
                title: 'ДЛЯ ВАГІТНИХ',
                link: 'caizowe'
            },
            {
                title: 'ЯК ВИМІРЯТИ?',
                link: 'howToKnowSize'
            },
        ],
        table_description: "Проведіть у бік, щоб побачити більше.",
        collName: 'Етикетка',
        rows: {
            row1: 'Талія',
            row2: 'Стегна',
            row3: 'Внутрішня частина ноги',
        },
        tabs: {
            title1: "Дюйми",
            title2: "см"
        },
        block1: {
            title: "ІНСТРУКЦІЯ З ПОСАДКИ",
            tip1: {
                title: "Вибір правильної висоти талії",
                text1: "Якщо ти віддаєш перевагу легінсам, які забезпечують повне покриття і підтримку, а також підкреслюють твою талію, наші легінси з високою талією - це те, що тобі потрібно.",
                text2: "Наші легінси із середньою та низькою посадкою (нижче пупка) не перетискають талію, тому ти можеш насолоджуватися повною свободою рухів і зосередитися на тренуванні.",
            },
            tip2: {
                title: "Вибір правильної довжини",
                text1: "Подовжений крій забезпечує додатковий захист і підтримку, а укорочений - більшу свободу рухів під час тренувань.",
                text2: "Якщо твій зріст 165 см (5 футів і 5 дюймів) або менше, ми рекомендуємо довжину 7/8 або менше.",
            }
        },
        table1: {
            title: "СТАНДАРТ",
            description: "",
        },
        table2: {
            title: "МІЖНАРОДНІ РОЗМІРИ (СТАНДАРТ)",
            description: "",
        },
        table3: {
            title: "ПОДОВЖЕНІ",
            description: "Подовжені розміри призначені для жінок зростом понад 179 см (5 футів і 10 дюймів).",
        },
        table4: {
            title: "УКОРОЧЕНІ",
            description: "Укорочені розмір призначений для жінок зростом менше 165 см (5 футів і 5 дюймів).",
        },
        table5: {
            title: "ПЛЮС САЙЗ",
            description: "Великі розміри: легінси, топи та футболки в розмірах до 4X.",
        },
        table6: {
            title: "МІЖНАРОДНІ РОЗМІРИ (ПЛЮС САЙЗ)",
            description: "",
        },
        table7: {
            title: "ДЛЯ ВАГІТНИХ",
            description: "Замов легінси в розмірі, який ти носила до вагітності. Легінси для вагітних будуть підходити тобі за розміром. Приблизно в третьому триместрі тобі можуть знадобитися на розмір більші, але не хвилюйся, ці легінси будуть підходити тобі протягом усієї вагітності.",
        },
        block2: {
            title: "ЯК ВИМІРЯТИ?",
            description: "Виміряй за допомогою вимірювальної стрічки, запиши результат і порівняй його з розмірами в нашій таблиці, щоб підібрати правильний розмір.",
            tips: {
                title1: "Для вимірювання тримайте вимірювальну стрічку горизонтально:",
                list1: "<b>1. Груди</b>, у найширшому місці",
                list2: "<b>2. Талія</b>, у найвужчому місці",
                list3: "<b>3. Стегна</b>, у найширшому місці, тримаючи ноги разом",
                title2: "Для вимірювання тримайте вимірювальну стрічку вертикально:",
                list4: "<b>4. Внутрішня частина ноги</b>, від пахової ділянки до підлоги",
                list5: "<b>5. Зріст</b>, від маківки до підлоги, при вертикальному положенні тіла",
            }
        },
        block4: {
            title: "Неправильний розмір або колір?",
            description: "Не проблема! У тебе є 60 днів на обмін розміру товару або повернення."
        },
        goUp: "Вгору"
    },
    unitefitBottom: {
        title: "РОЗМІРИ ШТАНІВ/ШОРТІВ UNITEFIT",
        description: "UNITEFIT - це наша гендерно-нейтральна система відповідності. Це викрійки одягу, створені за різними типами статури, формами та розмірами, а не за гендерними нормами та стандартами. Твій розмір UNITEFIT може відрізнятися від того, який ти зазвичай носиш.",
        table_description: "Проведіть у бік, щоб побачити більше.",
        collName: 'Етикетка',
        rows: {
            row1: 'Талія',
            row2: 'Стегна',
            row3: 'Внутрішня частина ноги',
        },
        tabs: {
            title1: "Дюйми",
            title2: "см"
        },
        block1: {
            title: "ВІЛЬНА/ПРИТАЛЕННА ПОСАДКА",
            text1: "Якщо ти купуєш чоловічі розміри, вибирай розмір, який ти зазвичай носиш, щоб отримати вільну посадку. Одяг на 1 розмір менший забезпечить більш щільне прилягання.",
            text2: "Якщо ти купуєш жіночі розміри, вибирай одяг на 1 розмір менший, ніж зазвичай, щоб отримати вільну посадку. Одяг на 2 розміри менший забезпечить більш щільне прилягання.",
        },
        info: {
            title: "ДЛЯ ВИСОКИХ/ПОДОВЖЕНІ ТА УКОРОЧЕНІ РОЗМІРИ",
            text1: "Подовжені, призначені для чоловіків зростом понад 189 см (6'2\")",
            text2: "Укорочені, розміри призначені для чоловіків зростом до 175 см (5'9\").",
        },
        tableBottom: {
            title: "ПІДБЕРИ СВІЙ РОЗМІР",
        },
        block2: {
            title: "ЯК ВИМІРЯТИ?",
            description: "Виміряй за допомогою вимірювальної стрічки, запиши результат і порівняй його з розмірами в нашій таблиці, щоб підібрати правильний розмір.\n",
            tips: {
                title1: "Для вимірювання тримайте вимірювальну стрічку горизонтально:",
                list1: "<b>1. Груди</b>, у найширшому місці",
                list2: "<b>2. Талія</b>, у найвужчому місці",
                list3: "<b>3. Стегна</b>, у найширшому місці, тримаючи ноги разом",
                title2: "Для вимірювання тримайте вимірювальну стрічку вертикально:",
                list4: "<b>4. Внутрішня частина ноги</b>, від пахової ділянки до підлоги",
                list5: "<b>5. Зріст</b>, від маківки до підлоги, при вертикальному положенні тіла",
            }
        },
        bottom: {
            title: "Неправильний розмір або колір?",
            description: "Не проблема! У тебе є 60 днів на обмін розміру товару або повернення.",
        },
        goUp: "Вгору"
    },
    unitefitTops: {
        title: "РОЗМІРНА СІТКА UNITEFIT ДЛЯ ВЕРХНЬОГО ОДЯГУ",
        description: "UNITEFIT - це наша гендерно-нейтральна система відповідності. Це викрійки одягу, створені за різними типами статури, формами та розмірами, а не за гендерними нормами та стандартами. Твій розмір UNITEFIT може відрізнятися від того, який ти зазвичай носиш.",
        table_description: "Проведіть у бік, щоб побачити більше.",
        collName: 'Етикетка',
        rows: {
            row1: 'Грудна клітина',
            row2: 'Талія',
            row3: 'Стегна',
        },
        tabs: {
            title1: "Дюйми",
            title2: "см"
        },
        block1: {
            title: "ВІЛЬНА/ПРИТАЛЕННА ПОСАДКА",
            text1: "Якщо ти купуєш чоловічі розміри, вибирай розмір, який ти зазвичай носиш, щоб отримати вільну посадку. Одяг на 1 розмір менший забезпечить більш щільне прилягання.",
            text2: "Якщо ти купуєш жіночі розміри, вибирай одяг на 1 розмір менший, ніж зазвичай, щоб отримати вільну посадку. Одяг на 2 розміри менший забезпечить більш щільне прилягання.",
        },
        info: {
            title: 'НЕПРАВИЛЬНИЙ РОЗМІР АБО КОЛІР?',
            text: 'Не проблема! У тебе є 60 днів на обмін товару за розміром або повернення.',
        },
        block2: {
            title: "ЯК ВИМІРЯТИ?",
            description: "Виміряй за допомогою вимірювальної стрічки, запиши результат і порівняй його з розмірами в нашій таблиці, щоб підібрати правильний розмір.\n",
            tips: {
                title1: "Для вимірювання тримайте вимірювальну стрічку горизонтально:",
                list1: "<b>1. Груди</b>, у найширшому місці",
                list2: "<b>2. Талія</b>, у найвужчому місці",
                list3: "<b>3. Стегна</b>, у найширшому місці, тримаючи ноги разом",
                title2: "Для вимірювання тримайте вимірювальну стрічку вертикально:",
                list4: "<b>4. Внутрішня частина ноги</b>, від пахової ділянки до підлоги",
                list5: "<b>5. Зріст</b>, від маківки до підлоги, при вертикальному положенні тіла",
            }
        },
        goUp: "Вгору"
    },
    footballBoots: {
        title: "РЕКОМЕНДАЦІЇ З ВИБОРУ РОЗМІРУ ФУТБОЛЬНОГО ВЗУТТЯ",
        description: "У таблиці вказана довжина стопи. Довжина устілки більше довжини стопи на величину функціонального припуску 10-15 мм.",
        anchors: [
            {
                title: 'РОЗМІРНА СІТКА ВЗУТТЯ',
                link: 'findSize'
            },
            {
                title: 'ПОВНОТА ВЗУТТЯ',
                link: 'shoesComfortable'
            },
            {
                title: 'МАТЕРІАЛИ ТА ПОСАДКА',
                link: 'materialsAndFit'
            },
            {
                title: 'ЯК ПРИМІРЯТИ ФУТБОЛЬНІ БУТСИ',
                link: 'howToFitShoes'
            },
            {
                title: 'ЯК ДІЗАНИТИСЯ СВІЙ РОЗМІР',
                link: 'howToKnowSize'
            },
        ],
        sizeTableTitle: 'РОЗМІРНА СІТКА ВЗУТТЯ',
        tabs: {
            title1: "Дюйми",
            title2: "cm"
        },
        table: {
            headers: {
                inch: {
                    title: "Довжина стопи (INCH)",
                },
                centimeter: {
                    title: "Довжина стопи"
                }
            },
            body: {
                inch: {
                    subtitle1: "UK",
                    subtitle2: "EU",
                    subtitle3: "US - Чоловіки",
                    subtitle4: "US - Жінки",
                    subtitle5: "Довжина стопи (см)",
                },
                centimeter: {
                    subtitle1: "UA",
                    subtitle2: "EU",
                    subtitle3: "US - Чоловіки",
                    subtitle4: "US - Жінки",
                    subtitle5: "UK",
                }
            },
            description: "Прокрути, щоб побачити більше"
        },
        block1: {
            title: "ПОВНОТА ВЗУТТЯ",
            description1: "Для оптимальної посадки зважай на ширину стопи.",
            description2: "Футбольні бутси X та Copa розраховані на стандартну ширину стопи. Бутси Predator підійдуть на стандартну та ширшу стопу."
        },
        block2: {
            title: "МАТЕРІАЛИ ТА ПОСАДКА",
            description: "Щоб забезпечити ідеальне почуття м'яча та точність торкання, бутси повинні щільно та комфортно облягати стопу. На посадку впливають матеріали, що використовуються при створенні верху, шнурівки та гомілкостопу.",
            tips: [
                {
                title: 'Матеріал верха',
                description: "Згодом верх бутс, виготовлений з такого органічного матеріалу, як шкіра, розтягуватиметься. І тоді бутси стануть вільнішими. Верх синтетичних матеріалів, таких як Primeknit або текстильних сіток, практично не розтягується і зберігає початкову форму."
                },
                {
                    title: 'Шнурівка',
                    description: "Футбольні бутси без шнурків надійно фіксують стопу. Вони практично не відчуваються на нозі. Футбольні бутси зі шнурівкою дозволяють регулювати плотність посадки."
                },
                 {
                    title: 'Гомілкостоп',
                    description: "Високі бутси (гомілкостоп закриває всю щиколотку) і низькі бутси (гомілкостоп не досягає або рівно по щиколотку) по-різному відчуваються на нозі."
                }
            ]
        },
        block3: {
            title: "ЯК ПРИМІРЯТИ ФУТБОЛЬНІ БУТСИ",
            description: "Приміряй бутси разом з ігровим екіпіруванням, наприклад: гетрами з амортизуючими вставками або без них і щитками із захистом гомілкостопу.",
            tips: [
                {
                    title: 'Приміряй обидві бутси',
                    description: "Так як одна нога може бути більшою за іншу, обов'язково приміряй обидві бутси."
                },
                     {
                    title: 'Правильно зашнуруй бутси',
                    description: "Зашнуруй, рівномірно розподіливши тиск і ступінь фіксації, і слідкуй, щоб в області гомілкостопу не було збірок."
                },
                     {
                    title: 'Пройдися',
                    description: "Важливо перевірити, що бутси добре сидять під твоєю вагою. Також переконайся, що тебе повністю влаштовують верх та підошва."
                },
                     {
                    title: 'Перевір, щоб бутси ніде не тиснули і не сиділи надто вільно',
                    description: "Комфортна посадка, що облягає, допомагає утримувати стопу на місці під час різкої зміни рухів."
                }
            ]
        },
        block4: {
            title: "Не підійшов РОЗМІР АБО КОЛІР?",
            description: "Нічого страшного, ти можеш безкоштовно повернути товар."
        },
        goUp: "Угору"
    },
    sizeMTrack: {
        title: "СПОРТИВНІ КОСТЮМИ ДЛЯ ЧОЛОВІКІВ - РОЗМІРИ",
        anchors: [
            {
                title: 'ПІДІБРАТИ СВІЙ РОЗМІР',
                link: 'findSize'
            },
            {
                title: 'ЯК ВИМІРЯТИ?',
                link: 'howToKnowSize'
            },
        ],
        table: {
            headers: {
                centimeter: {
                    title: "Етикетка"
                }
            },
            body: {
                centimeter: {
                    subtitle1: "Грудна клітина",
                    subtitle2: "Талія",
                    subtitle3: "Стегна",
                    subtitle4: "Внутрішня довжина штанини",
                }
            },
            description: "Проведи в бік, щоб побачити більше."
        },

        block1: {
            title: "ЯКИЙ РОЗМІР ОБРАТИ?",
            description1: "Легше сказати, ніж зробити... Бо що робити, коли раптом опиняєшся \"між\" двома розмірами?",
            description2: "Вирішувати тільки тобі. Надаєш перевагу більш приталеному одягу? Обирай менший розмір. Надаєш перевагу більш вільному одягу? Обирай більший розмір.",
            description3: "Верхня частина",
            description4: "Якщо у тебе різні об'єми грудей і талії, обирай модель за об'ємом грудей.",
            description5: "Нижня частина",
            description6: "Якщо у тебе різні об'єми стегон і талії, обирай модель за  об'ємом стегон.",
        },
        block2: {
            title: "ПІДІБРАТИ СВІЙ РОЗМІР",
            description: "Проведи в бік, щоб побачити більше."
        },
        block3: {
            title: "ЯК ВИМІРЯТИ?",
            tips: [
                {
                    id: '1',
                    title: 'ВИМІРЮВАННЯ ОБХВАТУ ГРУДНОЇ КЛІТИНИ',
                    image: 'https://media.adidas.ua/e494e73dbcfe36349c510368d5df7639/4/d7a84628c025d30f7b2c52c958767e76/64a4119a02151/2154.png',
                    description: "Щоб визначити правильний розмір, обгорни вимірювальну стрічку навколо найширшої частини грудної клітини, тримаючи її весь час на одній висоті."
                },
                {
                    id: '2',
                    title: 'ВИМІРЮВАННЯ ОБ\'ЄМУ ТАЛІЇ',
                    image: 'https://media.adidas.ua/e494e73dbcfe36349c510368d5df7639/4/7e9e346dc5fd268b49bf418523af8679/64a41113bddc6/ddc9.png',
                    description: "Виміряй найширшу частину талії за допомогою вимірювальної стрічки, тримаючи її весь час на одній висоті."
                },
                {
                    id: '3',
                    title: 'ВИМІРЮВАННЯ ОБ\'ЄМУ СТЕГН',
                    image: 'https://media.adidas.ua/e494e73dbcfe36349c510368d5df7639/4/c5a4e7e6882845ea7bb4d9462868219b/64a411891afe3/afe5.png',
                    description: "Виміряй задню поверхню стегон у найширшій точці, стоячи зі ступнями разом; вимірювальну стрічку тримай паралельно підлозі."
                },
                {
                    id: '4',
                    title: 'ВИМІРЮВАННЯ ВНУТРІШНЬОЇ ДОВЖИНИ ШТАНИН',
                    image: 'https://media.adidas.ua/e494e73dbcfe36349c510368d5df7639/4/7af6266cc52234b5aa339b16695f7fc4/64a41155abf87/bf8a.png',
                    description: "Виміряй довжину ноги по внутрішній стороні стегна від пахової області до кінчика ноги. Зазначена внутрішня довжина ноги розраховується на основі стандартної довжини шва."
                }
            ]
        },
        block4: {
            title: "Неправильний розмір або колір?",
            description: "Не проблема! У тебе є 60 днів на обмін розміру товару або повернення."
        },
        goUp: "Вгору"
    },
    sizeBra: {
        title: 'СПОРТИВНІ БРА- ПОРАДИ ЩОДО ВИБОРУ РОЗМІРУ',
        anchors: [
            {
                title: 'РОЗМІР ЛІТЕРА + Чашка',
                link: 'letterPlus'
            },
            {
                title: 'РОЗМІРИ ЛІТЕРАМИ',
                link: 'letter'
            },
            {
                title: 'МІЖНАРОДНІ РОЗМІРИ',
                link: 'international'
            },
        ],
        tableDescription: "Проведіть в бік, щоб побачити більше.",
        columnLabel: "РОЗМІР СПОРТИВНОГО БРА",
        table1: {
            title: "РОЗМІР ЛІТЕРА + Чашка",
            description1: "При підборі розміру літера + чашка використовуйте стандартні розміри (S, M, L і т.д.) і розміри чашок у двох діапазонах (A-C або D-DD), щоб забезпечити найкращу посадку.",
            description2: "Порівняй свій розмір спортивного бра з розмірами, зазначеними в нашій таблиці розмірів, щоб підібрати найкращий для себе варіант.",
        },
        additional: {
            title: "ПОТРІБЕН БІЛЬШИЙ РОЗМІР?",
            description: "У тебе чашка E або більший розмір? Ми також пропонуємо традиційні числові розміри (якщо ти не впевнена у відповідності, скористайся нашим калькулятором розмірів) та розміри Plus Size, доступні від 1X до 4X.",
        },
        table2: {
            title: "РОЗМІРИ ЛІТЕРАМИ",
            description: "Порівняй свій розмір спортивного бра з розмірами, зазначеними в нашій таблиці розмірів, щоб підібрати найкращий для себе варіант.",
        },
        table3: {
            title: "МІЖНАРОДНІ РОЗМІРИ",
            description: "Не знаєш, який твій міжнародний розмір? Переглянь нашу таблицю з міжнародними розмірами спортивних бра.",
        },
        bottom: {
            title: "НЕ ТОЙ РОЗМІР АБО КОЛІР?",
            description: "Не проблема! У тебе є 60 днів, щоб обміняти свій розмір або повернути товар.",
        },
        goUp: "Вгору"
    },
    sizeWTrack: {
        title: "СПОРТИВНІ КОСТЮМИ ДЛЯ ЖІНОК - РОЗМІРИ",
        anchors: [
            {
                title: 'ПІДІБРАТИ СВІЙ РОЗМІР',
                link: 'findSize'
            },
            {
                title: 'ЯК ВИМІРЯТИ?',
                link: 'howToKnowSize'
            },
        ],
        table: {
            headers: {
                centimeter: {
                    title: "Етикетка"
                }
            },
            body: {
                centimeter: {
                    subtitle1: "Грудна клітина",
                    subtitle2: "Талія",
                    subtitle3: "Стегна",
                    subtitle4: "Внутрішня довжина штанини",
                }
            },
            description: "Проведи в бік, щоб побачити більше."
        },

        block1: {
            title: "ЯКИЙ РОЗМІР ОБРАТИ?",
            description1: "Легше сказати, ніж зробити... Бо що робити, коли раптом опиняєшся \"між\" двома розмірами?",
            description2: "Вирішувати тільки тобі. Надаєш перевагу більш приталеному одягу? Обирай менший розмір. Надаєш перевагу більш вільному одягу? Обирай більший розмір.",
            description3: "Верхня частина",
            description4: "Якщо у тебе різні об'єми грудей і талії, обирай модель за об'ємом грудей.",
            description5: "Нижня частина",
            description6: "Якщо у тебе різні об'єми стегон і талії, обирай модель за  об'ємом стегон.",
        },
        block2: {
            title: "ПІДІБРАТИ СВІЙ РОЗМІР",
            description: "Проведи в бік, щоб побачити більше."
        },
        block3: {
            title: "ЯК ВИМІРЯТИ?",
            tips: [
                {
                    id: '1',
                    title: 'ВИМІРЮВАННЯ ОБХВАТУ ГРУДНОЇ КЛІТИНИ',
                    image: 'https://media.adidas.ua/e494e73dbcfe36349c510368d5df7639/7/cc42acc8ce334185e0193753adb6cb77/64a7e8972ed70/ed73.png',
                    description: "Щоб визначити правильний розмір, обгорни вимірювальну стрічку навколо найширшої частини грудної клітини, тримаючи її весь час на одній висоті."
                },
                {
                    id: '2',
                    title: 'ВИМІРЮВАННЯ ОБ\'ЄМУ ТАЛІЇ',
                    image: 'https://media.adidas.ua/e494e73dbcfe36349c510368d5df7639/7/26751be1181460baf78db8d5eb7aad39/64a7e8bebc7c0/c7ca.png',
                    description: "Виміряй найширшу частину талії за допомогою вимірювальної стрічки, тримаючи її весь час на одній висоті."
                },
                {
                    id: '3',
                    title: 'ВИМІРЮВАННЯ ОБ\'ЄМУ СТЕГН',
                    image: 'https://media.adidas.ua/e494e73dbcfe36349c510368d5df7639/7/a51c896c9cb81ecb5a199d51ac9fc3c5/64a7e8d00f391/f394.png',
                    description: "Виміряй задню поверхню стегон у найширшій точці, стоячи зі ступнями разом; вимірювальну стрічку тримай паралельно підлозі."
                },
                {
                    id: '4',
                    title: 'ВИМІРЮВАННЯ ВНУТРІШНЬОЇ ДОВЖИНИ ШТАНИН',
                    image: 'https://media.adidas.ua/e494e73dbcfe36349c510368d5df7639/7/752d25a1f8dbfb2d656bac3094bfb81c/64a7e8ebd4adf/4ae2.png',
                    description: "Виміряй довжину ноги по внутрішній стороні стегна від пахової області до кінчика ноги. Зазначена внутрішня довжина ноги розраховується на основі стандартної довжини шва."
                }
            ]
        },
        block4: {
            title: "Неправильний розмір або колір?",
            description: "Не проблема! У тебе є 60 днів на обмін розміру товару або повернення."
        },
        goUp: "Вгору"
    }
}
