import https from 'https';

function getCookieParams() {
    const date = new Date();
    date.setTime(date.getTime() + (30 * 60 * 1000));
    return {
        path: '/',
        expires: date
    };
}

export default async function(ctx) {
    let cookieAlreadySet = false;
    let langIso = ctx.$cookies.get('currentLangIso');
    const preferenceLang = ctx.$cookies.get('preferenceLang');
    if (!!preferenceLang) {
        langIso = Helpers.getLocaleIso(ctx.app.i18n, preferenceLang);
    }
    ctx.$axios.defaults.httpsAgent = new https.Agent({
        rejectUnauthorized: process.browser
    });
    ctx.$axios.defaults.headers['Accept-Language'] = langIso || ' ';
    ctx.$axios.defaults.headers['X-Api-Key'] = ctx.$cookies.get('xApiKey') || '';
    ctx.$axios.defaults.auth = API.auth;

    ctx.$axios.onResponse(function(resp) {
        if (cookieAlreadySet && !process.browser) {
            return;
        }
        let timeNow = Date.now();
        ctx.$cookies.set('lastQueryTime', timeNow, getCookieParams());
        cookieAlreadySet = true;
        let responseXApiKey = (resp?.headers?.['x-api-key'] ?? '');
        if ((!ctx.$cookies.get('xApiKey') || ctx.$cookies.get('xApiKey') !== responseXApiKey) && responseXApiKey) {
            ctx.$cookies.set('xApiKey', responseXApiKey, getCookieParams());
            ctx.$axios.defaults.headers['X-Api-Key'] = responseXApiKey || '';
        }
    });
    ctx.$axios.onError(function(error) {
        if (+((error?.response?.status ?? 0)) === 419) {
            const xApiKey = (error?.response?.headers?.['x-api-key'] ?? '');
            ctx.$cookies.set('xApiKey', xApiKey, getCookieParams());
            ctx.$cookies.set('timeOut', 1);
            ctx.$axios.defaults.headers['X-Api-Key'] = xApiKey || '';
            if (process.browser) {
                window?.location?.reload();
            } else {
                ctx.redirect(error.response.data.code, '/time-out');
            }
        } else if (+(error?.response?.status ?? 0) === 401) {
            ctx.redirect(error.response.data.code, '/login');
        } else {
            console.error("$axios.onError:", error?.response);
        }
    });
}
