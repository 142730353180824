//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import itemMixin from "./itemMixin";

export default {
    name: "TextItem",
    mixins: [itemMixin],
    computed: {
        textColor: function () {
            return (this?.info?.color ?? '');
        },
        inlineStyles: function () {
            const styles = {};
            this.textColor && this.textColor.trim !== '' && (styles.color = this.textColor);

            return styles
        },
        addTextSlyle: function(){
            return this.textSlyle.trim() !== ''
        },
        textSlyle: function(){
            return (this?.info?.style ?? '');
        },
        linkText: function () {
            return (this?.info?.text ?? '');
        }
    }
}
