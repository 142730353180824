//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import icons from "../../../icons"
export default {
    name: "LoginAndCartMainItem",
    props: {
        text: {
            type: String,
            default: ''
        },
        icon: {
            type: String,
            default: ''
        },
        count: {
            type: [Number, String],
            default: null
        },
        to: {
            type: String,
            default: '/'
        }
    },
    data() {
        return {
            icons
        }
    },
    computed: {
        isIcon() {
            return this.icon || this.icons[this.icon]
        },
        isCounter() {
            return this.count || this.count === 0
        }
    }
}
