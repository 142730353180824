export default async function (ctx) {
    if(process.client) {
        if (ctx.route?.query?.utm_medium && ctx.route?.query?.['aff_sub'] && ctx.route?.query?.['utm_medium'] === 'salesdoubler_cpa' ) {
            setSalesDoubler(ctx.route.query);
        } else if (Object.values(ctx.route?.query).length) {
            if (
                (ctx.route?.query?.utm_source && ctx.route?.query?.utm_source !== 'Affiliates') ||
                !!ctx.route?.query?.gclid ||
                !!ctx.route?.query?.uid
            ) {
                clearAffiliate(ctx.route.query)
            }
        }

        function setSalesDoubler(query) {
            let expiresDate = new Date(( 30 * 60 * 60 * 24 * 1000) + + new Date)
            let options = {
                path: '/',
                expires: expiresDate
            }
            ctx.$cookies.set('sd_user', query.aff_sub, options);
            ctx.$cookies.set('affInfo', JSON.stringify(query), options);
        }
        function clearAffiliate(query) {
            ctx.$cookies.remove('sd_user');
            ctx.$cookies.remove('affInfo');
        }
    }
}
