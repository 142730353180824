import search from "./search.svg?raw";
import close from "./close.svg?raw";
import logo from "./logo.svg?raw";
import loader from "./loader.svg?raw";
import arrowBottomSmall from "./arrow-bottom-small.svg?raw";
import arrowLeft from "./arrow-left.svg?raw";
import checkFlat from "./check-flat.svg?raw";

export default {
    search,
    close,
    logo,
    loader,
    arrowBottomSmall,
    arrowLeft,
    checkFlat
}
