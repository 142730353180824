
import icons from "./components/icons";
import common from "./mixin/common";
import ButtonCounter from "./components/ButtonCounter.vue";

export default {
    name: "IconButton",
    mixins: [common],
    components: {
        ButtonCounter
    },
    props: {
        // Set icon name (one of /assets/img/icons/index)
        icon: {
            type: String,
            default: 'heart'
        },

        // Set icon border
        outline: {
            type: Boolean,
            default: false
        },

        // Set round border
        round: {
            type: Boolean,
            default: false
        },

        // Set counter badge
        count: {
            type: [String, Number],
            default: 0
        },

        // Set button size
        size: {
            type: [String, Number],
            default: 48
        },

        // Allow hover state
        allowHover: {
            type: Boolean,
            default: true
        }
    },
    render: (createElement, { data, props, children, listeners }) => {
        const isLink = props.to && props.to.trim();
        const wrapperTag = isLink ? 'nuxt-link' : 'button';

        const iconName = (children?.[0]?.text ?? '').replace(/[\s+\\n]/g, '') || props.icon;
        const iconElement = createElement('div', {
            domProps: { innerHTML: icons[iconName] },
            class: 'button-icon__icon'
        });
        const counterElement = ButtonCounter.render(createElement, {
            props: { value: props.count },
            data: { staticClass: 'button-icon__counter' }
        });
        const button = createElement(wrapperTag, {
            class: [
                'button-icon__btn',
                { 'button-icon__btn--outline': props.outline },
                { 'button-icon__btn--round': props.round },
                { 'button-icon__btn--disabled': props.disabled },
                { 'button-icon__btn--hover': props.allowHover }
            ],
            attrs: {
                to: isLink && props.to,
                disabled: !isLink && props.disabled,
                'aria-label': props.ariaLabel
            },
            on: {
                click: listeners.click || (() => {})
            },
        }, [iconElement, counterElement]);
        return createElement('div', {
            class: [
                'button-icon',
                data.staticClass
            ],
            style: {
                width: `${props.size}px`,
                height: `${props.size}px`
            }
        }, [button]);
    }
}
