//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import fieldMixin from "./fieldMixin";
import RadioItem from './components/RadioItem'

export default {
    name: "Radio",
    mixins: [fieldMixin],
    components: {RadioItem},
    computed: {
        inlineMode: function () {
            return this.field.view.inline
        }
    },
    methods: {
        itemIsChecked(item) {
            return item[this.field.valueProp] === this.val[this.field.valueProp];
        }
    }
}
