
export  default {
    props: {
        widgetInfo: {
            type: Object,
            required: true
        },
    },
    data: function () {
        return {
            innerData: JSON.parse(JSON.stringify((this?.widgetInfo?.data ?? {})))
        }
    },
}

