export default {
    endCountWord(count, t1, t2, t3) {
        count = count.toString();
        let i = 0,
            arrNumber = count.split(''),
            lastNumber = parseInt(arrNumber[arrNumber.length - 1]),
            previousNumber = arrNumber.length > 1 ? parseInt(arrNumber[arrNumber.length - 2]) : 0;
        if (lastNumber === 1 && previousNumber !== 1) {
            i = 1
        } else if (lastNumber > 1 && lastNumber < 5 && previousNumber !== 1) {
            i = 2
        } else {
            i = 3
        }
        switch (i) {
            case 1:
                return count+' '+t1;
            case 2:
                return count+' '+t2;
            default:
                return count+' '+t3;
        }
    },
    getLocaleIso(i18n, lang) {
        let langNew = i18n.locales.find(i => i.code === lang);
        return  langNew ? langNew.iso : i18n.localeProperties.iso;
    },
    getLocaleBeIso(i18n, iso) {
        let langNew = i18n.locales.find(i => i.iso === iso);
        return  langNew.code ? langNew.code : i18n.localeProperties.code;
    },
    serializeQuery(queryObject) {
        let result = "?";
        for (let param in queryObject) {
            if (queryObject.hasOwnProperty(param)) {
                let newData = queryObject[param];
                let keyValuePare = "";
                if (Array.isArray(newData)) {
                    let tempAllInArray = [];
                    param = param + "[]";
                    newData.map((element) => {
                        tempAllInArray.push(param + "=" + element);
                    });
                    keyValuePare = tempAllInArray.join(",");
                } else {
                    keyValuePare = param + "=" + newData;
                }
                result += ((result === "?") ? "" : "&") + keyValuePare;
            }
        }
        return result;
    }
}
