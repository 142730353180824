//
//
//
//
//
//
//
//

import VPopup from "@/plugins/VPopup/popup";
import SubscribeThanks from "@/components/popups/SubscribePopup/Components/SubscribeThanks";
export default {
    name: "SignUpPopup",
    components: {VPopup, SubscribeThanks}
}
