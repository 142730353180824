import fs from 'fs';
import path from 'path';
import { NodeApiCacheExpiring } from '~/utils/config';

const cacheRootPath = path.resolve('./utils/ServerApiCache/_cache');

export default async function getServerCachedApiData(
    apiRoute,
    ctx,
    options = {
        expiringCacheMs: Number(NodeApiCacheExpiring.general) || 120000,
    }
) {
    if (!apiRoute || !ctx) {
        return;
    }

    // check if cache should be dropped from url search params
    if (ctx?.req?._parsedUrl?.search) {
        const searchObj = new URLSearchParams(ctx?.req?._parsedUrl?.search);
        if (searchObj.get('reset') === 'cache') {
            await clearServerApiCache();
            console.log(`%c Cache has been dropped for:%c ${ apiRoute.filePath } %c ${ apiRoute.fileName }.json`,
                'background: #ffffff; color: black;',
                'background: green; color: yellow;',
                'background: #000; color: #c30677;');
        }
    }

    const localePath = path.resolve(cacheRootPath, ctx.i18n.locale);

    return await getData(apiRoute, ctx, options);

    async function getData(apiRoute, ctx, options) {
        const { fileName, filePath, requestConfig } = apiRoute;
        if (!ctx || !fileName || !requestConfig) {
            console.error('-> Not correct apiRoute data: ', apiRoute);
            return;
        }
        const computedFilePath = path.resolve(`${ filePath ? localePath + filePath : localePath }`, `${ fileName }.json`);
        const getApiData = async () => {
            let apiData = null;
            // TODO after update axios to version >v0.22.0 rewrite to signal
            const CancelToken = ctx.$axios.CancelToken;
            const source = CancelToken.source();
            ctx.$axios.onError((error) => {
                source.cancel(`getApiData Axios error cancel`);
            });
            try {
                apiData = await ctx.$axios(Object.assign(requestConfig, { cancelToken: source.token }))
                    .then(response => response.data)
                    .catch(error => null);
                if (apiData) {
                    writeApiToFile(computedFilePath, apiData);
                }

            } catch (error) {
                console.error("-> server getApiData error ", error);
            }
            return apiData;
        };

        if (await hasValidCacheData(computedFilePath, options)) {
            let data = await getCachedData(computedFilePath, ctx);
            try {
                const cachedData = JSON.parse(data);
                if (cachedData) {
                    return cachedData;
                }
            } catch (error) {
                console.info("Error to parse cached data from file.", error, 'In computedFilePath: ', computedFilePath);
            }
        }
        return await getApiData();
    }

    function writeApiToFile(filePath, apiData) {
        try {
            fs.promises.writeFile(filePath, JSON.stringify(apiData));
        } catch (err) {
            console.error(err);
        }
    }

    async function hasValidCacheData(filePath, options) {
        return await hasCachedData(filePath) && await isValidCache(filePath, options);
    }

    function getCachedData(filePath) {
        return fs.promises.readFile(filePath, 'utf-8');
    }

    async function hasCachedData(filePath) {
        const difName = path.dirname(filePath);
        try {
            await fs.promises.access(filePath);
            return true;
        } catch (err) {
            await fs.promises.mkdir(difName, { recursive: true });
        }
        return false;
    }

    function isValidCache(filePath, options) {
        const { expiringCacheMs } = options;
        return fs.promises.stat(filePath).then(stat => {
            const timeFromLastChanges = (Date.now() - Number(stat.ctime));
            return (timeFromLastChanges < expiringCacheMs);
        }).catch(error => {
            return false;
        });
    }

    async function clearServerApiCache() {
        return await fs.promises.rmdir(cacheRootPath, {
            recursive: true,
        });
    }
}


