
export default {
    name: "CommonSubtitle",
    functional: true,
    render: function (createElement, {data, children}) {
        return createElement('span', {
            class: ['common-subtitle', data.staticClass],
        }, children);
    }
}
