//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "CommonScroll",
    props: {
        wrapperClass: {
            type: String,
            default: ''
        },
        verticalScrolling: {
            type: Boolean,
            default: false
        },
        scrollableOnWidowScrollLock: {
            type: Boolean,
            default: false
        },
        minSize: {
            type: Number,
            default: null
        },
        scrollPosition: {
            type: Number,
            default:0
        }
    },
    data() {
        return {
            showScrollBar: true,
            thumbStyle: {},
            observer: null
        }
    },
    computed: {
        setMinSize(){
            return this.minSize !== null
        },
        clientSizeParam(){
            return this.verticalScrolling ? 'clientHeight' : 'clientWidth'
        },
        scrollSizeParam(){
            return this.verticalScrolling ? 'scrollHeight' : 'scrollWidth'
        },
        scrollParam(){
            return this.verticalScrolling ? 'scrollTop' : 'scrollLeft'
        },
        sizeParam(){
            return this.verticalScrolling ? 'height' : 'width'
        },
        positionParam(){
            return this.verticalScrolling ? 'top' : 'left'
        }
    },
    methods: {
        handleScroll: function (evt, el = this.$refs.container) {
            if(!this.$refs.container){
                return
            }
            const prevVal = this.showScrollBar;
            this.showScrollBar = el ? el[this.scrollSizeParam] > el[this.clientSizeParam] : false;
            if (this.showScrollBar) {

                const newSize = (Math.ceil(this[this.verticalScrolling ? 'calcThumbHeight' : 'calcThumbWidth']()));
                const sizeToSet = this.setMinSize ? (newSize > this.minSize ? newSize : this.minSize) : newSize;
                const percentage = this.alreadyScrolledDistanceInPercent();
                const newPosition = this.calcThumbPosition(sizeToSet, percentage);

                this.thumbStyle = {
                    [this.sizeParam]:sizeToSet + "px",
                    [this.positionParam]:newPosition < 0 ? 0 : newPosition + "px",
                }

            }
            if (!prevVal && this.showScrollBar) {
                this.$nextTick(() => {
                    this.handleScroll()
                })
            }

        },
        handleMouseWheel: function (evt, el) {
            this.showScrollBar = el ? el[this.scrollSizeParam] > el[this.clientSizeParam] : false;
            if (!this.verticalScrolling) {
                if (this.showScrollBar) {
                    evt.preventDefault();
                }
                if (el.scrollWidth > el.clientWidth) {

                    const delta = Math.max(-1, Math.min(1, (evt.wheelDelta || -evt.detail)));
                    const prevVal = el.scrollLeft || 0;
                    el.scrollLeft = prevVal - (delta * 150)
                }
            }
        },
        alreadyScrolledDistanceInPercent(){
            return this.$refs.container[this.scrollParam] /(this.$refs.container[this.scrollSizeParam]  - this.$refs.container[this.clientSizeParam])
        },
        calcThumbPosition(size, scrolledPercents){
            const containerSize = this.$refs.container[this.clientSizeParam];
            return (containerSize - Math.min(containerSize, size)) * scrolledPercents
        },
        calcThumbWidth() {
            if(!this.$refs['scrollbar']){
                return  0
            }
            const newWidth =this.$refs['scrollbar'].clientWidth * this.$refs.container.clientWidth / this.$refs.container.scrollWidth;
            return  (this.$refs['scrollbar'].clientWidth === newWidth ? 0 : newWidth);
        },

        calcThumbHeight() {
            if(!this.$refs['scrollbar']){
                return  0
            }
            const newHeight =  this.$refs['scrollbar'].clientHeight * this.$refs.container.clientHeight / this.$refs.container.scrollHeight;
            return (this.$refs['scrollbar'].clientHeight === newHeight ? 0 : newHeight);
        },
        setPosition(offset) {
            this.$refs.container[this.scrollParam] = offset;
        }
    },
    created() {
        this.$on('reCalc', this.handleScroll);
        this.$on('setPosition', this.setPosition);
        if(process.browser) {
            window && window.addEventListener('resize', this.handleScroll);
        }
    },
    beforeDestroy() {
        if(process.browser) {
            window && window.removeEventListener('resize', this.handleScroll);
        }
        this.observer.disconnect();

    },
    mounted() {
        this.showScrollBar = this.$refs.container ? this.$refs.container[this.verticalScrolling ? 'scrollHeight' : 'scrollWidth'] > this.$refs.container[this.verticalScrolling ? 'clientHeight' : 'clientWidth'] : false;
        this.observer = new MutationObserver(function(mutations) {
            this.handleScroll();
        }.bind(this));
        this.observer.observe(
            this.$refs.container,
            { attributes: false, childList: true, characterData: false, subtree: true }
        );
        this.$refs.container.scrollTop = this.scrollPosition
    }
}
