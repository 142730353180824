export const appConfig = {
    perPage: 48,
    inputMask: {
        ua: {
            enabled: "+38# ## ### ## ##",
            disabled: "+38# ## ### ## ##",
        },
        expireDate: "##/##",
    },
    formatPhoneRegexp: string => {
        if (typeof string !== "string") {
            return string;
        }
        let formatPhone = string
            .replace(/[^0-9]/g, "")
            .replace(/(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})/, "$1 $2 $3 $4 $5");

        return "+" + formatPhone;
    }, // +### ## ### ## ##
    uspTopHideRoutes: ["/", "/man", "/woman", "/child"],
    uspPages: [
        { name: "index", path: "main" },
        { name: "static-help", path: "help" }
    ],

    mainInfo: {
        supportTime: {
            from: "9:00",
            to: "21:00"
        },
        contacts: {
            phone: "+380931111111",
            mail: "support@adidas.com",
            globalPhone: "0 800 21 22 32",
        },
        openUTCTime: {
            from: "9:00:00+03:00",
            to: "21:00:00+03:00"
        },
        currency: "UAH",
        globalInfo: {
            url: "https://www.adidas.ua/",
            name: "adidas",
            addressLocality: "Ukraine, Kyiv",
        },
        socialBlock: {
            instagram: {
                link: "https://www.instagram.com/adidasukraine/",
                icon: "instagram"
            },
            facebook: {
                link: "https://www.facebook.com/adidas/",
                icon: "facebook",
            },
            youtube: {
                link: "https://www.youtube.com/c/adidas",
                icon: "youtube",
            }
        },
    },
    positions: {
        status: {
            notActive: ['cancelled','rejected','refunded'],
        }
    },
    faq: {
        popular: [
            { textField: "q_1", link: "/" },
            { textField: "q_2", link: "/" },
            { textField: "q_3", link: "/" },
            { textField: "q_4", link: "/" },
            { textField: "q_5", link: "/" },
            { textField: "q_6", link: "/" }
        ],
        myOrderButtons: [
            { textField: "buttonEnter", link: "/login" },
            { textField: "buttonTracking", link: "/account/history" }
        ]
    },
    image: {
        mediaUrl: "https://assetmanagerpim-res.cloudinary.com/images",
        fileExtensions: {
            catalog: process.browser && navigator.userAgent.match(/Version\/[\d\.]+.*Safari/) ? "jpg" : "WebP",
            productCard: process.browser && navigator.userAgent.match(/Version\/[\d\.]+.*Safari/) ? "jpg" : "WebP"
        }
    },
    store: {
        catalogExpand: [
            "links",
            "counts",
            "attributes",
            "products",
            "products.imagesMainList",
            "products.baseInfo",
            "products.colors.imagesMainList",
            "products.colors.baseInfo",
            "products.sizes",
        ],
        PDP_Expand: [
            "imagesFullList",
            "about",
            "sizes",
            "description",
            "careInstructions",
            "baseInfo",
            "colors.imagesMainList",
        ],
        addedToCartExpand: [
            "lastAddedPosition.product.imagesMainList",
            "lastAddedPosition.product.sizes",
            "totalPrices",
            "positions.product.imagesMainList",
        ],
        loadCartExpand: [
            "positions.product.imagesMainList",
            "positions.tradeOffer",
            "totalPrices",
            "promoCode",
            "tags"
        ],
        loadFavoriteExpand: [
            "products",
            "products.imagesMainList",
        ],
        loadOrderConfirmation: [
            "totalPrices",
            "deliveryAddress",
            "payment",
            "positions",
            "positions.product.imagesMainList",
            "positions.tradeOffer",
            "positions.promoCode",
            "positions.totalPrices",
            "deliveryReceiver",
            "promoCode",
            "tags"
        ],
        loadOrder: [
            "positions.product.imagesMainList",
            "totalPrices",
        ]
    }
};

export const links = {
    footer: {
        location: { name: "index" },
        legalInfo: { name: "index" },
        privacyData: { name: "index" },
        social: {
            instagram: "/",
            youtube: "/"
        }
    },
    loginAndCart: {
        login: "/login",
        account: "/account/details",
        cart: "/cart"
    },
    cart: {
        contactsPopupShopsAddr: "/static/contacts",
        returnPopupShops: "/static/contacts",
        timeoutButton: "/cart"
    },
    loyaltyUniversal: {
        uk_UA: "/polozhennya-pro-programu-loyalnosti-yunivers",
        ru_RU: "/polozhenie-o-programme-loyalnosti-yunivers",
    },
    privacyPolicy: {
        uk_UA: "/politika-konfidenczijnosti-programi-loyalnosti-adidas-universe",
        ru_RU: "/politika-konfidenczialnosti-programmy-loyalnosti-adidas-universe",
    },
};

export const staticImages = {
    sizeTableShoesBlock: "https://media.adidas.ua/efa46b748973d40580de382979ee84bc/10/912d2b1c7b2826caf99687388d2e8f7c/62a3352f18320/8323.png",
    sizeTableClothesBlock: "https://media.adidas.ua/efa46b748973d40580de382979ee84bc/10/4f16c818875d9fcb6867c7bdc89be7eb/62a3367dc2f6e/2f70.png",
    sizeTableSwimsuitsBlock: "https://media.adidas.ua/efa46b748973d40580de382979ee84bc/10/0eec27c419d0fe24e53c90338cdc8bc6/62a3369d1b771/b774.png",
    sizeTableBootsComfortableBlock_ru: "https://media.adidas.ua/efa46b748973d40580de382979ee84bc/10/f197002b9a0853eca5e046d9ca4663d5/62a336bf0200d/2010.png",
    sizeTableBootsComfortableBlock_ua: "https://media.adidas.ua/efa46b748973d40580de382979ee84bc/10/83f97f4825290be4cb794ec6a234595f/62a336d9b4a2e/4a30.png",
    sizeTableBootsMaterialBlock_ru: "https://media.adidas.ua/efa46b748973d40580de382979ee84bc/10/6a61d423d02a1c56250dc23ae7ff12f3/62a3370164956/4959.png",
    sizeTableBootsMaterialBlock_ua: "https://media.adidas.ua/efa46b748973d40580de382979ee84bc/10/2de5d16682c3c35007e4e92982f1a2ba/62a3371a24b2d/4b2f.png",
    childBanner1: "https://media.adidas.ua/efa46b748973d40580de382979ee84bc/10/b51a15f382ac914391a58850ab343b00/62a3373381429/142c.png",
    childBanner2: "https://media.adidas.ua/efa46b748973d40580de382979ee84bc/10/f29b38f160f87ae86df31cee1982066f/62a337605de98/de9a.png",
    childBanner3: "https://media.adidas.ua/efa46b748973d40580de382979ee84bc/10/e077e1a544eec4f0307cf5c3c721d944/62a3377354863/4866.png",
    childSlider1: "https://media.adidas.ua/efa46b748973d40580de382979ee84bc/10/83adc9225e4deb67d7ce42d58fe5157c/62a3378ebaf3f/af41.png",
    childSlider2: "https://media.adidas.ua/efa46b748973d40580de382979ee84bc/10/70feb62b69f16e0238f741fab228fec2/62a3379fbd19d/d1a1.png",
    childSlider3: "https://media.adidas.ua/efa46b748973d40580de382979ee84bc/10/602d1305678a8d5fdb372271e980da6a/62a337b71bff1/bff4.png",
    childrenClothesSize: "https://media.adidas.ua/efa46b748973d40580de382979ee84bc/10/9a1756fd0c741126d7bbd4b692ccbd91/62a337c999899/989c.png",
    manTop: "https://media.adidas.ua/efa46b748973d40580de382979ee84bc/10/a8f8f60264024dca151f164729b76c0b/62a337ddc916f/9172.png",
    manBottom: "https://media.adidas.ua/efa46b748973d40580de382979ee84bc/10/1abb1e1ea5f481b589da52303b091cbb/62a337f384101/4103.png",
    mTrack1: "https://media.adidas.ua/e494e73dbcfe36349c510368d5df7639/4/7e9e346dc5fd268b49bf418523af8679/64a41113bddc6/ddc9.png",
    mTrack2: "https://media.adidas.ua/e494e73dbcfe36349c510368d5df7639/4/7af6266cc52234b5aa339b16695f7fc4/64a41155abf87/bf8a.png",
    mTrack3: "https://media.adidas.ua/e494e73dbcfe36349c510368d5df7639/4/c5a4e7e6882845ea7bb4d9462868219b/64a411891afe3/afe5.png",
    mTrack4: "https://media.adidas.ua/e494e73dbcfe36349c510368d5df7639/4/d7a84628c025d30f7b2c52c958767e76/64a4119a02151/2154.png",
    summerShoes: "https://media.adidas.ua/efa46b748973d40580de382979ee84bc/10/1baff70e2669e8376347efd3a874a341/62a33811260df/60e3.png",
    subscribeBlockDesktop: "https://media.adidas.ua/efa46b748973d40580de382979ee84bc/10/83f2550373f2f19492aa30fbd5b57512/62a3382a46695/6698.png",
    subscribeBlockTablet: "https://media.adidas.ua/efa46b748973d40580de382979ee84bc/10/b5a1fc2085986034e448d2ccc5bb9703/62a3384030a49/0a4c.png",
    subscribeBlockMobile: "https://media.adidas.ua/efa46b748973d40580de382979ee84bc/10/98986c005e5def2da341b4e0627d4712/62a338525dbb3/dbb5.png",
};

export const ApiEndpointsList = {
    facebook: "/customer/signin/facebook",
    google: "/customer/signin/google"
};

export const NodeApiCacheExpiring = {
    general: 120000,
    subscriptionForm: 300000,
}
