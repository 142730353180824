//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
    name: "CommonBtnLink",
    computed: {
        isLink() {
            return this.$attrs.to
        },
        isOuterLink() {
            return this.$attrs.href
        },
        isInline() {
            return this.$attrs.inline
        },
        target() {
            return this.$attrs.target ? this.$attrs.target : '_parent';
        }
    }
}
