export default {
    search: 'Пошук',
    searchHistory: 'Історія пошуку',
    pageTitle: 'Ми не змогли знайти товари по запиту',
    pageSubtitle: 'Не здавайся! Перевір написання чи перефразуй пошуковий запит.',
    pageSomeElse: 'Тобі може сподобатися:',
    pageWeRecommended: 'Ми рекомендуємо',
    clear: 'очистити',
    showAll: 'Дивитися все',

    // Error page

    errorPageTitle: 'Сторінку не знайдено, але не варто здаватися',
    errorSubtitle: 'Введи свій запит тут',
    errorSomeElse: 'Перевір коректність написання адреси в рядку браузера. Або повернися на',
    errorSomeElse2: 'головну сторінку',
    errorSomeElse3: 'і спробуй знову.'
}
