//
//
//
//
//
//
//
//

import mainLayout from './layouts/Main'
import widgetMixin from "../../mixins/widgetMixin";
import {mapGetters} from "vuex";
export default {
    name: "Subscribe",
    components: {
        mainLayout
    },
    mixins: [widgetMixin],
    computed: {
        ...mapGetters({
            subscribeData: 'header/getSubscribeData',
        }),
        hasSubscribeData() {
            return !!this.subscribeData?.title || !!this.subscribeData?.description
        },
    }
}
