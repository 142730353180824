export default {
    fields: {
        phoneNumber: 'Номер телефона',
        fName: 'Имя',
        sName: 'Фамилия',
        mName: 'Отчество',
        date: 'Дата',
        birthDate: 'Дата рождения',
        month: 'Месяц',
        year: 'Год',
        sex: 'Пол',
        male: 'Мужской',
        female: 'Женский',
        notGender: 'Не хочу отвечать',
        email: 'Email',
        pass: 'Пароль',
        mobilePhone: 'Мобильный телефон',
        confirmCode: 'Код подтверждения',
        city: 'Город',
        street: 'Улица',
        house: 'Дом / Корпус',
        flat: 'Квартира',
    },
    errors: {
        enterBirthDate: "Введи дату рождения",
        incorrectDate: 'Некорректная дата',
        maxDate: 'Оформление заказа возможно только лицами старше 14 лет.',
        enterConfirmCode:'Введи код',
        incorrectConfirmCode:'Некорректний код',
        enterPhone:'Введи номер телефона',
        incorrectPhone:'Некорректный номер телефона',
        enterEmail:'Введи адрес электронной почты',
        incorrectEmail:'Проверь верный ли адрес электронной почты',
        required: 'Поле {field} должно быть заполнено',
        number: 'Поле {field} должно содержать только цифры',
        length: 'Длина поля {field} должна быть {length} знаков',
        maxLength: 'Длина поля {field} должна быть меньше {length} знаков',
        minLength: 'Длина поля {field} должна быть больше {length} знаков',
        maxValue: 'Значение поля {field} должно быть не больше {value}',
        minValue: 'Значение поля {field} должно быть не меньше {value}',
        wrongFormat: 'Поле {field} имеет неправильный формат',
        onlyCyrillic: 'Только кириллицей',
        requiredField: 'Обязательное поле',
        wrongDate: 'Некорректная дата',
        chooseDeliveryAddress: 'Выбери адрес доставки',
        recaptcha: 'Пожалуйста, подтвердите, что Вы не робот',
    }
}
