import _set from '~/utils/_set';

export default {
    computed: {
        isDepended: function () {
            return this.row.hasOwnProperty('depend') &&
                Array.isArray(this.row.depend)
                && this.row.depend.length > 0
                && this.row.depend.some(function (item) {
                return item.hasOwnProperty('to') && item.to && item.to.trim().length > 0
            })
        },
        dependedToValue: function () {
            return this.isDepended && this.row.depend.some(function (item) {
                return item.hasOwnProperty('value')
            });
        },
        hasDependentValueIsRule: function () {
            return this.dependedToValue && this.row.depend.some(function (item) {
                return Boolean(item?.value?.is?.length);
            });
        },
        hasDependentValueNotRule: function () {
            return this.dependedToValue && this.row.depend.some(function (item) {
                return Boolean(item?.value?.not?.length);
            })
        },
        getValueRules: function () {
            return (rule, condition) => {
                const rules = [];
                if (condition) {
                    this.row.depend.forEach(function (item) {
                        if (item.value.hasOwnProperty(rule)) {
                            rules.push({
                                name: item.to,
                                value: item.value[rule]
                            })
                        }
                    })
                }
                return rules;
            }
        },
        dependValueIsRules: function () {
            return this.getValueRules('is', this.hasDependentValueIsRule)
        },
        dependValueNotRules: function () {
            return this.getValueRules('not', this.hasDependentValueNotRule)
        },
        dependValueIsRulesFieldRulesNames: function () {
            return this.dependValueIsRules.reduce(function (acc, item) {
                acc.push(item.name)
                return acc;
            }, [])
        },
        dependValueNotRulesFieldRulesNames: function () {
            return this.dependValueNotRules.reduce(function (acc, item) {
                acc.push(item.name)
                return acc;
            }, [])
        },
        _depend_allParentFieldsNames: function () {
            const fieldNames = [];
            if (this.isDepended) {
                this.row.depend.forEach( (item) => {
                    item.to && fieldNames.push({
                        field: item.to,
                        formName: (item?.formName ?? this.formName)
                    })
                })
            }
            return fieldNames;
        },
        _depend_ParentFieldsNamesByForms: function () {
            return this._depend_allParentFieldsNames.reduce((acc, item) => {
                _set(acc, item.formName, [
                    ...(acc?.item?.formName ?? []),
                    item.field
                ])
                return acc;
            }, {})
        },

        parentFieldsValue: function () {
            let values = {};
            for (let formName in this._depend_ParentFieldsNamesByForms) {
                const keyValueObj = (this.$store.getters?.[`${formName}/getKeyValueDataForFieldsList`] ?? (() => ({})))(this._depend_ParentFieldsNamesByForms[formName]);
                values = Object.assign({}, keyValueObj)
            }
            return values;
        },
        checkDependRulesForOneField: function () {
            return (ruleArr, name, value, expectResult) => {
                return ruleArr.every((rule) => {
                    if (rule.name === name) {
                        return expectResult === rule.value.includes(value)
                    } else {
                        return true
                    }
                })
            }
        },

        parentFieldsFilledRight: function () {
            return this._depend_allParentFieldsNames.every(function (el) {
                return this.dependRuleIsValid(el.field);
            }.bind(this))
        },
        dependRulesValidated() {
            return this._depend_allParentFieldsNames.every(function (el) {
                return this.parentFieldFilled(el.field) ? this.dependRuleIsValid(el.field) : true
            }.bind(this))
        }
    },
    methods: {
        dependRuleIsValid(name) {
            const filled = this.parentFieldFilled(name);
            const valueIsRulesValid = this.dependValueIsRulesFieldRulesNames.includes(name) ? this.checkDependRulesForOneField(this.dependValueIsRules, name, this.parentFieldsValue[name], true) : true;
            const valueNotRulesValid = this.dependValueNotRulesFieldRulesNames.includes(name) ? this.checkDependRulesForOneField(this.dependValueNotRules, name, this.parentFieldsValue[name], false) : true;
            return filled && valueIsRulesValid && valueNotRulesValid;

        },
        parentFieldFilled(name) {
            return typeof this.parentFieldsValue[name] !== 'boolean' ? this.parentFieldsValue[name] && (this.parentFieldsValue[name].toString().trim() !== '') : true;
        }
    }
}
