//
//
//
//
//
//
//
//
//

import registerModule from "./LazyFormRow/store/registerModule";
import {mapGetters} from 'vuex'

export default {
    name: "CommonFormError",
    props: {
        formId: {
            type: String,
            required: true
        }
    },
    computed: {
        ...mapGetters({
            'getFormErrorById': 'forms/getFormErrorById'
        }),
        errorText () {
            return this.getFormErrorById(this.formId)
        },
        showError () {
            return this.errorText.trim() !== ''
        }
    },
    watch: {


    },
    methods: {

    },
    async mounted() {

    }
}
