export default function isStringInListRegex(listOfStrings = [], StringToCheck) {
    let isInList = false;
    for (let i = 0; i < listOfStrings.length; i++) {
        const regex = new RegExp(listOfStrings[i]);
        if (regex.test(StringToCheck)) {
            isInList = true;
            break;
        }
    }
    return isInList;
}
