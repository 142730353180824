export default async function ({ app, store }, inject) {
    let i18n = app.i18n;
    inject('liveChat', new Vue({
        computed: {
            body() {
                if (typeof document !== 'undefined') {
                    return document.querySelector('body');
                } else  {
                    return false;
                }
            }
        },
        methods: {
            liveChat() {
                return document.querySelector("#chat-widget-container") || false;
            },
            hide() {
                this.liveChat() && this.liveChat().classList.add("hidden");
            },
            show() {
                this.liveChat() && this.liveChat().classList.remove('hidden');
            },
        }
    }))
}
