//
//
//
//
//
//
//
//
//
//

import HeaderMenuItem from "./components/HeaderMenuItem/index";

export default {
    name: "HeaderMenu",
    components: {
        HeaderMenuItem
    },
    props: {
        items: {
            type: Array,
            default: () => ([])
        },
        disable: {
            type: Boolean,
            default: false
        }
    }
}
