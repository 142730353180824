import i18n from '@/plugins/i18n';
export const state = () => ({
    product: null,
    chosenSize: {},
    sizeGuideData: [],
});

export const getters = {
    getProduct: ({product}) => product,
    getChosenSize: ({chosenSize}) => chosenSize,
    getAlarm: ({chosenSize}) => chosenSize.alarm,
    getSizeGuideData: ({sizeGuideData}) => sizeGuideData,
    productHasDescription: ({product}, getters) => {
        return getters.productHasDescriptionHeadline || getters.productHasDescriptionText || getters.productHasDescriptionImage
    },
    productHasDescriptionHeadline: ({product}, getters) => {
        return (product?.description?.subHeadline ?? '') !== ''
    },
    productHasDescriptionText: ({product}, getters) => {
        return  (product?.description?.shortDescription ?? '')
    },
    productHasDescriptionImage: ({product}, getters) => {
        return (product?.description?.descriptionImage ?? null)
    },
    productHasCareBlock: ({product}, getters) => {
        return getters.productHasMainCareBlock || getters.productHasExtraCareBlock
    },
    productHasMainCareBlock: ({product}) => {
        return Boolean(product?.careInstructions?.careInstructionsCode?.length);
    },
    productHasExtraCareBlock: ({product}) => {
        return Boolean(product?.careInstructions?.extraCareInstructionsCode?.length);
    },
    productHasAboutBlock: ({product}) => {
        return Boolean(product?.about?.length);
    },
    getProductNavigationItems: ({product}, getters) => {
        const res = [
            {
                anchor: 'gallery',
                titleKey: 'productCard.gallery'
            },
        ];

        getters.productHasDescription && (res.push({
            anchor: 'description',
            titleKey: 'productCard.description'
        }));
        getters.productHasCareBlock && (res.push({
            anchor: 'care',
            titleKey: 'productCard.care'
        }));
        getters.productHasAboutBlock && (res.push({
            anchor: 'bullets',
            titleKey:'productCard.aboutModel'
        }));

        return res
    }
};

export const mutations = {
    [types.SET_PRODUCT](state, data) {
        state.product = data
    },
    [types.SET_CHOSEN_SIZE](state, data) {
        state.chosenSize = data
    },
    [types.SET_FAVORITE_FLAG](state, data) {
        state.product.isFavorite = data
    },
    [types.SET_SIZE_GUIDE_INFO](state, data) {
        state.sizeGuideData = data
    },
};

export const actions = {
    async getProduct({commit, state}, id) {
        let data = {
            images: [
                {
                    src: {
                        desktop: {
                            '1x': 'https://assets.adidas.com/images/h_2000,f_auto,q_auto:sensitive,fl_lossy/fd7bb0d72a384b5bb043ac0200925c7b_9366/Krossovki_ZX_1K_Boost_belyj_FX6516_01_standard.jpg',
                            '2x': 'https://assets.adidas.com/images/h_2000,f_auto,q_auto:sensitive,fl_lossy/fd7bb0d72a384b5bb043ac0200925c7b_9366/Krossovki_ZX_1K_Boost_belyj_FX6516_01_standard.jpg',
                        },
                        tablet: {
                            '1x': 'https://assets.adidas.com/images/h_2000,f_auto,q_auto:sensitive,fl_lossy/fd7bb0d72a384b5bb043ac0200925c7b_9366/Krossovki_ZX_1K_Boost_belyj_FX6516_01_standard.jpg',
                            '2x': 'https://assets.adidas.com/images/h_2000,f_auto,q_auto:sensitive,fl_lossy/fd7bb0d72a384b5bb043ac0200925c7b_9366/Krossovki_ZX_1K_Boost_belyj_FX6516_01_standard.jpg',
                        },
                        mobile: {
                            '1x': 'https://assets.adidas.com/images/h_2000,f_auto,q_auto:sensitive,fl_lossy/fd7bb0d72a384b5bb043ac0200925c7b_9366/Krossovki_ZX_1K_Boost_belyj_FX6516_01_standard.jpg',
                            '2x': 'https://assets.adidas.com/images/h_2000,f_auto,q_auto:sensitive,fl_lossy/fd7bb0d72a384b5bb043ac0200925c7b_9366/Krossovki_ZX_1K_Boost_belyj_FX6516_01_standard.jpg',
                        }
                    },
                    title: 'Image title',
                    alt: 'image alt',
                },
                {
                    src: {
                        desktop: {
                            '1x': 'https://assets.adidas.com/images/h_2000,f_auto,q_auto:sensitive,fl_lossy/fd7bb0d72a384b5bb043ac0200925c7b_9366/Krossovki_ZX_1K_Boost_belyj_FX6516_01_standard.jpg',
                            '2x': 'https://assets.adidas.com/images/h_2000,f_auto,q_auto:sensitive,fl_lossy/fd7bb0d72a384b5bb043ac0200925c7b_9366/Krossovki_ZX_1K_Boost_belyj_FX6516_01_standard.jpg',
                        },
                        tablet: {
                            '1x': 'https://assets.adidas.com/images/h_2000,f_auto,q_auto:sensitive,fl_lossy/fd7bb0d72a384b5bb043ac0200925c7b_9366/Krossovki_ZX_1K_Boost_belyj_FX6516_01_standard.jpg',
                            '2x': 'https://assets.adidas.com/images/h_2000,f_auto,q_auto:sensitive,fl_lossy/fd7bb0d72a384b5bb043ac0200925c7b_9366/Krossovki_ZX_1K_Boost_belyj_FX6516_01_standard.jpg',
                        },
                        mobile: {
                            '1x': 'https://assets.adidas.com/images/h_2000,f_auto,q_auto:sensitive,fl_lossy/fd7bb0d72a384b5bb043ac0200925c7b_9366/Krossovki_ZX_1K_Boost_belyj_FX6516_01_standard.jpg',
                            '2x': 'https://assets.adidas.com/images/h_2000,f_auto,q_auto:sensitive,fl_lossy/fd7bb0d72a384b5bb043ac0200925c7b_9366/Krossovki_ZX_1K_Boost_belyj_FX6516_01_standard.jpg',
                        }
                    },
                    title: 'Image title',
                    alt: 'image alt',
                },
                {
                    src: {
                        desktop: {
                            '1x': 'https://assets.adidas.com/images/h_2000,f_auto,q_auto:sensitive,fl_lossy/fd7bb0d72a384b5bb043ac0200925c7b_9366/Krossovki_ZX_1K_Boost_belyj_FX6516_01_standard.jpg',
                            '2x': 'https://assets.adidas.com/images/h_2000,f_auto,q_auto:sensitive,fl_lossy/fd7bb0d72a384b5bb043ac0200925c7b_9366/Krossovki_ZX_1K_Boost_belyj_FX6516_01_standard.jpg',
                        },
                        tablet: {
                            '1x': 'https://assets.adidas.com/images/h_2000,f_auto,q_auto:sensitive,fl_lossy/fd7bb0d72a384b5bb043ac0200925c7b_9366/Krossovki_ZX_1K_Boost_belyj_FX6516_01_standard.jpg',
                            '2x': 'https://assets.adidas.com/images/h_2000,f_auto,q_auto:sensitive,fl_lossy/fd7bb0d72a384b5bb043ac0200925c7b_9366/Krossovki_ZX_1K_Boost_belyj_FX6516_01_standard.jpg',
                        },
                        mobile: {
                            '1x': 'https://assets.adidas.com/images/h_2000,f_auto,q_auto:sensitive,fl_lossy/fd7bb0d72a384b5bb043ac0200925c7b_9366/Krossovki_ZX_1K_Boost_belyj_FX6516_01_standard.jpg',
                            '2x': 'https://assets.adidas.com/images/h_2000,f_auto,q_auto:sensitive,fl_lossy/fd7bb0d72a384b5bb043ac0200925c7b_9366/Krossovki_ZX_1K_Boost_belyj_FX6516_01_standard.jpg',
                        }
                    },
                    title: 'Image title',
                    alt: 'image alt',
                },
                {
                    src: {
                        desktop: {
                            '1x': 'https://assets.adidas.com/images/h_2000,f_auto,q_auto:sensitive,fl_lossy/fd7bb0d72a384b5bb043ac0200925c7b_9366/Krossovki_ZX_1K_Boost_belyj_FX6516_01_standard.jpg',
                            '2x': 'https://assets.adidas.com/images/h_2000,f_auto,q_auto:sensitive,fl_lossy/fd7bb0d72a384b5bb043ac0200925c7b_9366/Krossovki_ZX_1K_Boost_belyj_FX6516_01_standard.jpg',
                        },
                        tablet: {
                            '1x': 'https://assets.adidas.com/images/h_2000,f_auto,q_auto:sensitive,fl_lossy/fd7bb0d72a384b5bb043ac0200925c7b_9366/Krossovki_ZX_1K_Boost_belyj_FX6516_01_standard.jpg',
                            '2x': 'https://assets.adidas.com/images/h_2000,f_auto,q_auto:sensitive,fl_lossy/fd7bb0d72a384b5bb043ac0200925c7b_9366/Krossovki_ZX_1K_Boost_belyj_FX6516_01_standard.jpg',
                        },
                        mobile: {
                            '1x': 'https://assets.adidas.com/images/h_2000,f_auto,q_auto:sensitive,fl_lossy/fd7bb0d72a384b5bb043ac0200925c7b_9366/Krossovki_ZX_1K_Boost_belyj_FX6516_01_standard.jpg',
                            '2x': 'https://assets.adidas.com/images/h_2000,f_auto,q_auto:sensitive,fl_lossy/fd7bb0d72a384b5bb043ac0200925c7b_9366/Krossovki_ZX_1K_Boost_belyj_FX6516_01_standard.jpg',
                        }
                    },
                    title: 'Image title',
                    alt: 'image alt',
                },
                {
                    src: {
                        desktop: {
                            '1x': 'https://assets.adidas.com/images/h_2000,f_auto,q_auto:sensitive,fl_lossy/fd7bb0d72a384b5bb043ac0200925c7b_9366/Krossovki_ZX_1K_Boost_belyj_FX6516_01_standard.jpg',
                            '2x': 'https://assets.adidas.com/images/h_2000,f_auto,q_auto:sensitive,fl_lossy/fd7bb0d72a384b5bb043ac0200925c7b_9366/Krossovki_ZX_1K_Boost_belyj_FX6516_01_standard.jpg',
                        },
                        tablet: {
                            '1x': 'https://assets.adidas.com/images/h_2000,f_auto,q_auto:sensitive,fl_lossy/fd7bb0d72a384b5bb043ac0200925c7b_9366/Krossovki_ZX_1K_Boost_belyj_FX6516_01_standard.jpg',
                            '2x': 'https://assets.adidas.com/images/h_2000,f_auto,q_auto:sensitive,fl_lossy/fd7bb0d72a384b5bb043ac0200925c7b_9366/Krossovki_ZX_1K_Boost_belyj_FX6516_01_standard.jpg',
                        },
                        mobile: {
                            '1x': 'https://assets.adidas.com/images/h_2000,f_auto,q_auto:sensitive,fl_lossy/fd7bb0d72a384b5bb043ac0200925c7b_9366/Krossovki_ZX_1K_Boost_belyj_FX6516_01_standard.jpg',
                            '2x': 'https://assets.adidas.com/images/h_2000,f_auto,q_auto:sensitive,fl_lossy/fd7bb0d72a384b5bb043ac0200925c7b_9366/Krossovki_ZX_1K_Boost_belyj_FX6516_01_standard.jpg',
                        }
                    },
                    title: 'Image title',
                    alt: 'image alt',
                },
            ],
            wobblers: [
                {title: 'exclusive'},
            ],
            imageAssetIDs: 'Зріст моделі на фото 189 cm. Розмір одежі 48-50 RU. Об’єм грудей 98 cm. Об’єм талії 82 cm.',
            colors: {
                length: 3,
                items: [
                    {
                        id: 0,
                        image: {
                            src: {
                                desktop: {
                                    '1x': 'https://assets.adidas.com/images/h_2000,f_auto,q_auto:sensitive,fl_lossy/fd7bb0d72a384b5bb043ac0200925c7b_9366/Krossovki_ZX_1K_Boost_belyj_FX6516_01_standard.jpg',
                                    '2x': 'https://assets.adidas.com/images/h_2000,f_auto,q_auto:sensitive,fl_lossy/fd7bb0d72a384b5bb043ac0200925c7b_9366/Krossovki_ZX_1K_Boost_belyj_FX6516_01_standard.jpg',
                                },
                                tablet: {
                                    '1x': 'https://assets.adidas.com/images/h_2000,f_auto,q_auto:sensitive,fl_lossy/fd7bb0d72a384b5bb043ac0200925c7b_9366/Krossovki_ZX_1K_Boost_belyj_FX6516_01_standard.jpg',
                                    '2x': 'https://assets.adidas.com/images/h_2000,f_auto,q_auto:sensitive,fl_lossy/fd7bb0d72a384b5bb043ac0200925c7b_9366/Krossovki_ZX_1K_Boost_belyj_FX6516_01_standard.jpg',
                                },
                                mobile: {
                                    '1x': 'https://assets.adidas.com/images/h_2000,f_auto,q_auto:sensitive,fl_lossy/fd7bb0d72a384b5bb043ac0200925c7b_9366/Krossovki_ZX_1K_Boost_belyj_FX6516_01_standard.jpg',
                                    '2x': 'https://assets.adidas.com/images/h_2000,f_auto,q_auto:sensitive,fl_lossy/fd7bb0d72a384b5bb043ac0200925c7b_9366/Krossovki_ZX_1K_Boost_belyj_FX6516_01_standard.jpg',
                                },
                            },
                            title: 'title',
                            alt: 'alt',
                        },
                        url: '/product-card',
                        active: 0,
                    },
                    {
                        id: 1,
                        image: {
                            src: {
                                desktop: {
                                    '1x': 'https://assets.adidas.com/images/h_2000,f_auto,q_auto:sensitive,fl_lossy/fd7bb0d72a384b5bb043ac0200925c7b_9366/Krossovki_ZX_1K_Boost_belyj_FX6516_01_standard.jpg',
                                    '2x': 'https://assets.adidas.com/images/h_2000,f_auto,q_auto:sensitive,fl_lossy/fd7bb0d72a384b5bb043ac0200925c7b_9366/Krossovki_ZX_1K_Boost_belyj_FX6516_01_standard.jpg',
                                },
                                tablet: {
                                    '1x': 'https://assets.adidas.com/images/h_2000,f_auto,q_auto:sensitive,fl_lossy/fd7bb0d72a384b5bb043ac0200925c7b_9366/Krossovki_ZX_1K_Boost_belyj_FX6516_01_standard.jpg',
                                    '2x': 'https://assets.adidas.com/images/h_2000,f_auto,q_auto:sensitive,fl_lossy/fd7bb0d72a384b5bb043ac0200925c7b_9366/Krossovki_ZX_1K_Boost_belyj_FX6516_01_standard.jpg',
                                },
                                mobile: {
                                    '1x': 'https://assets.adidas.com/images/h_2000,f_auto,q_auto:sensitive,fl_lossy/fd7bb0d72a384b5bb043ac0200925c7b_9366/Krossovki_ZX_1K_Boost_belyj_FX6516_01_standard.jpg',
                                    '2x': 'https://assets.adidas.com/images/h_2000,f_auto,q_auto:sensitive,fl_lossy/fd7bb0d72a384b5bb043ac0200925c7b_9366/Krossovki_ZX_1K_Boost_belyj_FX6516_01_standard.jpg',
                                },
                            },
                            title: 'title',
                            alt: 'alt',
                        },
                        url: '/product-card',
                        active: 1,
                    },
                    {
                        id: 2,
                        image: {
                            src: {
                                desktop: {
                                    '1x': 'https://assets.adidas.com/images/h_2000,f_auto,q_auto:sensitive,fl_lossy/fd7bb0d72a384b5bb043ac0200925c7b_9366/Krossovki_ZX_1K_Boost_belyj_FX6516_01_standard.jpg',
                                    '2x': 'https://assets.adidas.com/images/h_2000,f_auto,q_auto:sensitive,fl_lossy/fd7bb0d72a384b5bb043ac0200925c7b_9366/Krossovki_ZX_1K_Boost_belyj_FX6516_01_standard.jpg',
                                },
                                tablet: {
                                    '1x': 'https://assets.adidas.com/images/h_2000,f_auto,q_auto:sensitive,fl_lossy/fd7bb0d72a384b5bb043ac0200925c7b_9366/Krossovki_ZX_1K_Boost_belyj_FX6516_01_standard.jpg',
                                    '2x': 'https://assets.adidas.com/images/h_2000,f_auto,q_auto:sensitive,fl_lossy/fd7bb0d72a384b5bb043ac0200925c7b_9366/Krossovki_ZX_1K_Boost_belyj_FX6516_01_standard.jpg',
                                },
                                mobile: {
                                    '1x': 'https://assets.adidas.com/images/h_2000,f_auto,q_auto:sensitive,fl_lossy/fd7bb0d72a384b5bb043ac0200925c7b_9366/Krossovki_ZX_1K_Boost_belyj_FX6516_01_standard.jpg',
                                    '2x': 'https://assets.adidas.com/images/h_2000,f_auto,q_auto:sensitive,fl_lossy/fd7bb0d72a384b5bb043ac0200925c7b_9366/Krossovki_ZX_1K_Boost_belyj_FX6516_01_standard.jpg',
                                },
                            },
                            title: 'title',
                            alt: 'alt',
                        },
                        url: '/product-card',
                        active: 0,
                    },
                ]
            },
            division: 'Outdoor',
            title: 'Парка MYSHELTER COLD.RDY',
            subTitle: 'ПАРКА-ПУХОВИК ДЛЯ МІСТА, натхненна холодными зимами НЬЮ-ЙОРКА',
            article: 'FT2431',
            defaultColor: {
                id: 0,
                title: 'Black / Orange',
            },
            salePrice: '24 999 грн.',
            firstPrice: '26 999 грн.',
            pdpCallout: {
                title: 'Знижка -23% у додатку',
                text: 'При отриманні замовлення звіряйтеся з таблицею розмірів за шкалами UK, US, FR.',
            },
            aboutModelOnPhoto: 'Зріст моделі на фото 189 cm. Розмір одежі 48-50 RU. Об’єм грудей 98 cm. Об’єм талії 82 cm.',
            sizes: [
                {
                    id: 6,
                    label: '40-42 UA',
                    available: true,
                    balance: 100,
                },
                {
                    id: 1,
                    label: '48-50 UA',
                    available: true,
                    balance: 4,
                },
                {
                    id: 2,
                    label: '52-54 UA',
                    available: true,
                    balance: 3,
                },
                {
                    id: 3,
                    label: '56-58 UA',
                    available: true,
                    balance: 1,
                },
                {
                    id: 4,
                    label: '999',
                    available: false,
                    balance: 0,
                },
            ],
            sizeChart: {},
            meta: {
                breadcrumb: [
                    {
                        url: '/',
                        title: 'Головна',
                    },
                    {
                        url: '/man',
                        title: 'Чоловікам',
                    },
                    {
                        url: '/man',
                        title: 'Одяг',
                    },
                ]
            },
            isFavorite: false,
            description: 'По дорозі на роботу або на зустріч з друзями останнє, про що хочеться думати, це холод. Тому ти носиш парку MYSHELTER COLD.RDY. Валики з гусячим пухом HyperDRY ™ частково перекривають один одного, завдяки чому модель добре зберігає тепло і не пропускає холодне повітря всередину. Внутрішні лямки дозволяють носити куртку на плечах як рюкзак, коли заходиш в приміщення або транспорт. Непромокальний жилет додатково захищає від опадів.',
            descriptionImage: {
                src: {
                    desktop: {
                        '1x': 'https://assets.adidas.com/images/w_600,f_auto,q_auto/d25f6df8ebe340898719a80f00d25b3a_9366/Parka_Stadium_18_chernyj_BQ6594.jpg',
                        '2x': 'https://assets.adidas.com/images/w_600,f_auto,q_auto/d25f6df8ebe340898719a80f00d25b3a_9366/Parka_Stadium_18_chernyj_BQ6594.jpg',
                    },
                    tablet: {
                        '1x': 'https://assets.adidas.com/images/w_600,f_auto,q_auto/d25f6df8ebe340898719a80f00d25b3a_9366/Parka_Stadium_18_chernyj_BQ6594.jpg',
                        '2x': 'https://assets.adidas.com/images/w_600,f_auto,q_auto/d25f6df8ebe340898719a80f00d25b3a_9366/Parka_Stadium_18_chernyj_BQ6594.jpg',
                    },
                    mobile: {
                        '1x': 'https://assets.adidas.com/images/w_600,f_auto,q_auto/d25f6df8ebe340898719a80f00d25b3a_9366/Parka_Stadium_18_chernyj_BQ6594.jpg',
                        '2x': 'https://assets.adidas.com/images/w_600,f_auto,q_auto/d25f6df8ebe340898719a80f00d25b3a_9366/Parka_Stadium_18_chernyj_BQ6594.jpg',
                    },
                },
                title: 'title',
                alt: 'alt',
            },
            careInstructions: [
                {icon: 'A2', title: 'Ручне прання в холодній воді'},
                {icon: 'B1', title: 'Не відбілювати'},
                {icon: 'C2', title: 'Машинна сушка при низькій температурі'},
                {icon: 'D1', title: 'Не прасувати'},
                {icon: 'E1', title: 'Хімічна чистка заборонена'},
            ],
            extraCareInstructions: [
                {title: 'Не використовувати кондиціонер для білизни'},
                {title: 'Прати тільки з м\'яким миючим засобом'},
                {title: 'Прати й прасувати з виворітного боку'},
            ],
            bullets: [
                {title: 'Парка: стандартна посадка Верхня частина: вільний крій'},
                {title: 'Застібка на блискавку; утеплений капюшон на зав\'язках-шнурках'},
                {title: 'Основний матеріал: 100% нейлон'},
                {title: 'Парка з пуховою утеплювачем і капюшоном'},
                {title: 'Непромокальний пух HyperDRY ™'},
                {title: 'Передні кишені на блискавці'},
                {title: 'Каптур двосторонній жилет з внутрішніми кишенями'},
                {title: 'Лямки для перенесення'},
            ],

        }

        let dataUpdate = {
            "display-name": "Парка MYSHELTER COLD.RDY",
            "division": "Outdoor",
            "price": {
                "first": 26999,
                "sale": 24699
            },
            "defaultColor": "Black / Orange",
            "vendorCode": "ED2345",
            "aboutModelOnPhoto": 'Зріст моделі на фото 189 cm. Розмір одежі 48-50 RU. Об’єм грудей 98 cm. Об’єм талії 82 cm.',
            "badges": [
                {
                    "title": "exclusive",
                    "type": "custom"
                }
            ],
            "imagesList": [
                {
                    "id": "fd7bb0d72a384b5bb043ac0200925c7b_9366/MYSHELTER",
                    "title": "title image",
                    "alt": "alt image"
                },
                {
                    "id": "fd7bb0d72a384b5bb043ac0200925c7b_9366/MYSHELTER",
                    "title": "title image",
                    "alt": "alt image",
                    "describe": true
                },
                {
                    "id": "fd7bb0d72a384b5bb043ac0200925c7b_9366/MYSHELTER",
                    "title": "title image",
                    "alt": "alt image"
                },
                {
                    "id": "fd7bb0d72a384b5bb043ac0200925c7b_9366/MYSHELTER",
                    "title": "title image",
                    "alt": "alt image"
                },
                {
                    "id": "fd7bb0d72a384b5bb043ac0200925c7b_9366/MYSHELTER",
                    "title": "title image",
                    "alt": "alt image"
                }
            ],
            "sizeChart": "size_tops_woman_dz",
            "pdpCallout": {
                "title": "ЗНИЖКА -23% У ДОДАТКУ",
                "subtitle": "При отриманні замовлення звіряйтеся з таблицею розмірів за шкалами UK, US, FR."
            },
            "sizes": [
                {
                    "title": "40-42 UA",
                    "id": 32423,
                    "balance": 100,
                },
                {
                    "title": "48-50 UA",
                    "id": 34556,
                    "balance": 4,
                },
                {
                    "title": "52-54 UA",
                    "id": 3334566,
                    "balance": 3,
                },
                {
                    "title": "56-58 UA",
                    "id": 3334,
                    "balance": 1
                }
            ],
            "colors": [
                {
                    "display-name": "Парка MYSHELTER COLD.RDY",
                    "division": "Outdoor",
                    "price": {
                        "first": 26999,
                        "sale": 24699
                    },
                    "badges": [
                        {
                            "title": "не скользит",
                            "type": "custom"
                        }
                    ],
                    "defaultColor": "Bright Blue / Cloud White / Cloud White",
                    "url": {
                        "absolute": "https://www.adidas.ua/krosovki-tensor-malchiki-beg-ED2345",
                        "parent": "/product-view"
                    },
                    "images": {
                        "main": {
                            "id": "fd7bb0d72a384b5bb043ac0200925c7b_9366/sdhbvsddffsdfssfsd",
                            "title": "title image",
                            "alt": "alt image"
                        },
                        "hover": {
                            "id": "bf960e33d8954a8bab4aaafd00ff7767_9366/sdhbvsddffsdfssfsd",
                            "title": "title image",
                            "alt": "alt image"
                        }
                    }
                },
                {
                    "display-name": "Парка MYSHELTER COLD.RDY",
                    "division": "Outdoor",
                    "price": {
                        "first": 26999,
                        "sale": 24699
                    },
                    "badges": [
                        {
                            "title": "не скользит",
                            "type": "custom"
                        }
                    ],
                    "defaultColor": "Bright Blue / Cloud White / Cloud White",
                    "url": {
                        "absolute": "https://www.adidas.ua/krosovki-tensor-malchiki-beg-ED2345",
                        "parent": "/product-view"
                    },
                    "images": {
                        "main": {
                            "id": "bf960e33d8954a8bab4aaafd00ff7767_9366/sdhbvsddffsdfssfsd",
                            "title": "title image",
                            "alt": "alt image"
                        },
                        "hover": {
                            "id": "bf960e33d8954a8bab4aaafd00ff7767_9366/sdhbvsddffsdfssfsd",
                            "title": "title image",
                            "alt": "alt image"
                        }
                    }
                },
                {
                    "display-name": "Парка MYSHELTER COLD.RDY",
                    "division": "Outdoor",
                    "price": {
                        "first": 26999,
                        "sale": 24699
                    },
                    "badges": [
                        {
                            "title": "не скользит",
                            "type": "custom"
                        }
                    ],
                    "defaultColor": "Bright Blue / Cloud White / Cloud White",
                    "url": {
                        "absolute": "https://www.adidas.ua/krosovki-tensor-malchiki-beg-ED2345",
                        "parent": "/product-view"
                    },
                    "images": {
                        "main": {
                            "id": "bf960e33d8954a8bab4aaafd00ff7767_9366/sdhbvsddffsdfssfsd",
                            "title": "title image",
                            "alt": "alt image"
                        },
                        "hover": {
                            "id": "bf960e33d8954a8bab4aaafd00ff7767_9366/sdhbvsddffsdfssfsd",
                            "title": "title image",
                            "alt": "alt image"
                        }
                    }
                },
            ],
            "subHeadline": "ПАРКА-ПУХОВИК ДЛЯ МІСТА, натхненна холодными зимами НЬЮ-ЙОРКА",
            "short-description": "По дорозі на роботу або на зустріч з друзями останнє, про що хочеться думати, це холод. Тому ти носиш парку MYSHELTER COLD.RDY. Валики з гусячим пухом HyperDRY ™ частково перекривають один одного, завдяки чому модель добре зберігає тепло і не пропускає холодне повітря всередину. Внутрішні лямки дозволяють носити куртку на плечах як рюкзак, коли заходиш в приміщення або транспорт. Непромокальний жилет додатково захищає від опадів.",
            "careInstructionsCode": [
                {
                    "description": "Ручне прання в холодній воді",
                    "code": "A2",
                    "image": {
                        "url": "наш медиа",
                        "alt": "",
                        "title": ""
                    },
                },
                {
                    "description": "Не відбілювати",
                    "code": "B1",
                    "image": {
                        "url": "наш медиа",
                        "alt": "",
                        "title": ""
                    },
                },
                {
                    "description": "Машинна сушка при низькій температурі",
                    "code": "C2",
                    "image": {
                        "url": "наш медиа",
                        "alt": "",
                        "title": ""
                    },
                },
                {
                    "description": "Не прасувати",
                    "code": "D1",
                    "image": {
                        "url": "наш медиа",
                        "alt": "",
                        "title": ""
                    },
                },
                {
                    "description": "Хімічна чистка заборонена",
                    "code": "E1",
                    "image": {
                        "url": "наш медиа",
                        "alt": "",
                        "title": ""
                    },
                },
            ],
            "extraCareInstructionsCode": [
                "Не використовувати кондиціонер для білизни",
                "Прати тільки з м'яким миючим засобом",
                "Прати й прасувати з виворітного боку"
            ],
            "about": [
                "Парка: стандартна посадка Верхня частина: вільний крій",
                "Застібка на блискавку; утеплений капюшон на зав'язках-шнурках",
                "Основний матеріал: 100% нейлон",
                "Парка з пуховою утеплювачем і капюшоном",
                "Непромокальний пух HyperDRY™",
                "Передні кишені на блискавці",
                "Каптур двосторонній жилет з внутрішніми кишенями",
                "Лямки для перенесення",
                "Колір моделі: Black / Orange",
                "Артикул: ED2345",
            ],
            "meta": {
                "breadcrumb": [
                    {
                        "url": '/',
                        "title": 'Головна',
                    },
                    {
                        "url": '/man',
                        "title": 'Чоловікам',
                    },
                    {
                        "url": '/man',
                        "title": 'Одяг',
                    },
                ]
            },
        }

        commit(types.SET_PRODUCT, dataUpdate);
    },

    async toCart({commit}, id) {
        commit(types.SET_CART_ADDED_FLAG, 1);
        await this.$axios.get(`${API.main}/order/positions/add`, {
            params: {
                expand: AppConfig.store.addedToCartExpand.join(','),
                tradeOfferId: id
            }
        }).then(function (response) {

            commit(types.SET_LAST_ADDED, response.data.lastAddedPosition[0]);
            commit(types.SET_TOTAL_PRICES, response.data.totalPrices);
            commit(types.SET_PREORDER_ID, response.data.id);
            commit(types.SET_POSITION_COUNT, response.data.positionsCount);

            commit('../cart/UPDATE_CART_TOTAL', response.data.positionsCount);


            commit(types.SET_CART_ADDED_FLAG, 2);
            setTimeout(function () {
                commit(types.SET_CART_ADDED_FLAG, 0);
            }, 5000);
        });
    },

    async getSizeGuideInfo({commit}, id) {
        await this.$axios.get(`${API.main}/size-guide/get`, {
            params: {
                sizeChartId: id
            }
        }).then(function (response) {
            commit(types.SET_SIZE_GUIDE_INFO, response.data);
        });
    },

    async toggleFavorite({commit}, flag) {
        commit(types.SET_FAVORITE_FLAG, flag);
    },


    clearProductInfo({commit}) {
        commit(types.SET_PRODUCT, {});
        commit(types.SET_CHOSEN_SIZE, {});
        commit(types.SET_SIZE_GUIDE_INFO, {});
    }
};
