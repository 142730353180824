//
//
//
//
//
//
//
//
//
//
//
//
//

import mediaItem from './components/mediaItem';
import textItem from './components/textItem';
import emptyItem from './components/emptyItem';
import emptyLineItem from './components/emptyLineItem';

export default {
    name: "MenuColumnItem",
    components: {
        mediaItem,
        textItem,
        emptyItem,
        emptyLineItem,
    },
    props: {
        itemsList: {
            required: true,
            type: Array
        }
    },
    computed: {
        hasItemsList: function () {
            return !!(this?.itemsList ?? false) && this.itemsList.length > 0
        },
    },
    methods: {
        itemComponentName(type) {
            const suffix = type.indexOf('item') !== -1? '' : "-item";
            return  `${type.trim()}${suffix}`
        },
    }

}
