import core from './modules/core';
import home from './modules/home';
import account from './modules/account';
import cart from './modules/cart';
import checkout from './modules/checkout';
import login from './modules/login';
import registration from './modules/registration';
import staticPages from './modules/static';
import store from './modules/store';
import thankYou from './modules/thankYouPage';
import common from './modules/common';
import langPopup from './modules/langPopup';
import widgets from './modules/widgets';
import search from './modules/search';
import productCard from './modules/productCard';
import form from './modules/form';
import sizeTable from './modules/sizeTable';
import yeezy from './modules/yeezy';
import breadcrumbs from "./modules/breadcrumbs";
import waitList from "./modules/waitList";

export default {
    langPopup,
    common,
    core,
    home,
    account,
    cart,
    checkout,
    login,
    registration,
    static: staticPages,
    store,
    thankYou,
    widgets,
    search,
    productCard,
    form,
    sizeTable,
    yeezy,
    breadcrumbs,
    waitList,
}
