//
//
//
//
//
//
//

import FooterBottomRowLocalLocation from "./FooterBottomRowLocalLocation";
import FooterBottomRowLocalLanguage from "./FooterBottomRowLocalLanguage";
export default {
    name: "FooterBottomRowLocal",
    components: {FooterBottomRowLocalLanguage, FooterBottomRowLocalLocation}
}
