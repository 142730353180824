//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import icons from '../icons'
import { links } from '~/utils/config';

export default {
    name: "FooterBottomRowLocalLocation",
    data() {
        return {
            icons,
            links
        }
    }
}
