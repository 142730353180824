//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "SearchRecommendationWords",
    props: {
        words: {
            type: Object,
            default: () => { return {} }
        },
        query: {
            type: String,
            default: ''
        }
    },
    methods: {
        boldSubstr(value) {
            return value.toLowerCase().replace(new RegExp(this.query.toLowerCase(), 'g'), `<b>${this.query}</b>`);
        },
        closePopup() {
            this.$emit('close')
        }
    },
    computed: {
        hasItems() {
            return Boolean(this?.words?.items?.length);
        }
    }
}
