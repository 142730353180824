import esEvent from '~/assets/js/eSputnik/esEvent';

export default function addToWishlist(data) {
    const eventName = 'AddToWishlist';

    if (typeof data !== 'object') {
        console.warn('Wrong data type', eventName, data);
    }

    const computedProductKey = data?.sizes?.[0]?.id ? data?.sizes?.[0]?.id : data?.id;
    sendEvent({
        [eventName]: {
            productKey: String(computedProductKey),
            price: String(data.price.sale),
            isInStock: 1
        }
    });

    function sendEvent(eventBody) {
        esEvent(eventName, eventBody);
    }
}
