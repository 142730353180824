//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import icons from "~/assets/img/icons";

export default {
    name: "HeaderStaticLanguage",
    data() {
        return {
            icons,
            showList: false,
            isMacOs: false,
            addRouterHook:true
        }
    },
    computed: {
        langName: function () {
            return this.$i18n.locales.find((lang) => {
                return lang.code === this.$i18n.locale
            }).label;
        },
        localesListWithOutActive: function () {
            return this.$i18n.locales.filter((lang) => {
                return lang.code !== this.$i18n.locale
            });
        },
    },
    methods: {
        toggleList(state) {
            state = typeof state == 'boolean' ? state : !this.showList;
            this.showList = state
        },
        checkOS: function () {
            if(window.navigator.userAgent.indexOf("Macintosh") !== -1) {
                this.isMacOs = true;
            }
        },
        setPreferenceLocaleCookie(lang){
            this.$store.dispatch('core/setLocaleCookie', lang);
        },
        async setNewLang(lang) {
            this.toggleList(false);
            this.setPreferenceLocaleCookie(lang);
            await this.$i18n.setLocale(lang);
        }
    },
    mounted(){
        this.addRouterHook && this.$router.beforeHooks.unshift((to, from, next)=>{
            this.toggleList(false);
            this.addRouterHook = false;
            next();
        });
        this.checkOS();
    }
}
