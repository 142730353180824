export default ({
                    name,
                    formName,
                    value = '',
                    selectedValue = value,
                    label = "",
                    fieldType = 'Text',
                    send = false,
                    view = {},
                    state = {},
                    validateSettings = []
                }) => {
    return {
        "id": name,
        "error": {
            show: false,
            text: '',
            permanentText: ''
        },
        name: name,
        formName: formName,
        value: value,
        selectedValue: selectedValue,
        valueProp: 'id',
        data: {
            loading: false,
            items: [],
            pagination: {
                page: 1,
                hasNext: false
            },
        },
        send: send,
        validateSettings: validateSettings,
        depend: [],
        dataLoad: {
            cancelPreviousRequest: false,
            sdk: {},
            byUrl: {}
        },
        texts: {
            label: label,
            placeholder: '',
            description: '',
        },
        view: Object.assign({
            halfRow: false,
            halfContainer: false,
            inline: true,
        }, view, {
            fieldType: fieldType,
        }),
        state: Object.assign({
            showedNow: false,
            manualChange: true,

            validated: false,
            validating: false,
            autoChange: true,
        }, state, {
            filled:( value !== null && value !== "" )|| ['boolean','number'].includes(typeof value)
        }),
        getProperty: {}
    }
}
