
import icons from "./components/icons";
import common from "./mixin/common";

export default {
    name: "FavoriteButton",
    mixins: [common],
    props: {
        // Set button to active mode
        active: {
            type: Boolean,
            default: false
        }
    },
    render: function (createElement, {data, props, listeners}) {
        const isLink = props.to && props.to.trim();
        const wrapperTag = isLink ? 'nuxt-link' : 'button';

        const iconElement = createElement('div', {
            domProps: {innerHTML: icons[props.active ? 'heartBlack' : 'heart']},
            class: 'button-favorite__icon'
        });
        const button = createElement(wrapperTag, {
            class: [
                'button-favorite__btn',
                {'button-favorite__btn--disabled': props.disabled}
            ],
            attrs: {
                to: isLink && props.to,
                disabled: !isLink && props.disabled,
                'aria-label': props.ariaLabel
            },
            on: {
                click: listeners.click || (() => {})
            },
        }, [ 'favorite', iconElement]);
        return createElement('div', {
            class: [
                'button-favorite',
                data.staticClass,
                {'button-favorite--active': props.active}
            ],
        }, [button]);
    }
}
