export default ({ app:{store, router, nuxt,i18n} }) => {
    router.beforeEach((to, from, next) => {
        store.dispatch('header/setActiveItem', '');
        next();
    });
    nuxt.defaultTransition.beforeEnter = (to, from) => {
        i18n.finalizePendingLocaleChange();
    }

    // Optional: wait for locale before scrolling for a smoother transition
    router.options.scrollBehavior = async (to, from, savedPosition) => {
        // Make sure the route has changed
        if (to.name !== from.name) {
            await i18n.waitForPendingLocaleChange()
        }

        if (to.name && from.name && (to.name === from.name && to.name !== 'dynamicPage')) return true;

        return savedPosition || { x: 0, y: 0 }
    }
}
