//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import fieldMixin from "./fieldMixin";
import CommonScroll from "../../CommonScroll";
import debounce from '~/utils/_debounce';

export default {
    name: "Select",
    components: {CommonScroll},
    mixins: [fieldMixin],
    data: function () {
        return {
            searchInput: '',
            open: false,
            prevSelectScrollPosition: 0
        }
    },
    computed: {
        mainSelectedLabel () {
            return (this.val?.selectedlabel ?? (this.val?.label ?? ''))
        },
        showAdditionalLabel () {
            return !(this.val?.additionalLabel ?? '') &&  this.showAddition && !(this.val?.selectedlabel ?? '')
        },
        search: function () {
            return debounce(this.searchFunc, 150)
        },
        hasMorePage: function () {
            return this.field.data.pagination.hasNext
        },
        curPage: function () {
            return this.field.data.pagination.page
        },
        showSelectedLabel: function () {
            return !this.open && this.filled
        },
        showLoader: function () {
            return this.dataLoading && this.open
        },
        showSearchIcon: function () {
            return !this.open && !this.showLoader;
        },
        showContent: function () {
            return this.open
        }
    },
    methods: {
        searchFunc() {
            this.setLoadingParams()
        },
        toggleContent(state = null) {
            state = this.fieldIsDisabled ? false : state;
            this.open = typeof state !== 'boolean' ? !this.open : state
        },
        close() {
            this.searchInput = '';
            this.toggleContent(false)
        },
        loadMoreData() {
            this.hasMorePage && !this.dataLoading && this.setLoadingParams(+this.curPage + 1)

        },
        setNewVal(item) {
            this.close(false);
            this.updateVal(item)
        },
        updateSearchInput(e) {
            this.searchInput = e.target.value
        },
        setLoadingParams(page = 1) {

            this.$emit('search', {
                'search': this.searchInput,
                'page': page
            })
        },
        saveScrollPosition() {
            if (this.$refs.scroll) {
                this.prevSelectScrollPosition = this.$refs.scroll.$refs.container ? this.$refs.scroll.$refs.container.scrollTop : 0
            }
        }
    },
    watch: {
        searchInput: {
            handler: function (newVal, oldVal) {
                if (newVal !== oldVal) {
                    this.search()
                }
            }
        },
        open: {
            handler: function (newVal) {
                this[newVal ? 'onFocus' : 'onBlur']()
            }
        },

    },
}
