//
//
//
//
//
//
//
//
//
//
//

import SubscribePopup from '@/components/popups/SubscribePopup/index'

export default {
    name: "Main",
    data() {
        return {
            formId: 'subscription_form'
        }
    },
    methods: {
        async openSubscribePopup() {
            this.$ga.sendSimpleEvent('begin-subscription')
            await this.$store.dispatch(`forms/loadFormByIdWithData`, {formId: this.formId, data: {source: 'footer'}});
            this.$popups.close();
            this.$popups.open({
                component: SubscribePopup,
                props: {
                    formId: this.formId,
                    source: 'footer'
                }
            })
        }
    }
}
