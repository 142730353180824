//
//
//
//
//
//
//
//
//

import registerModule from "./LazyFormRow/store/registerModule";
import {mapGetters} from 'vuex'

export default {
    name: "CommonForm",
    props: {
        autocomplete: {
            type: Boolean,
            default: false
        },
        statesList: {
            type: Array,
            default: function () {
                return [];
            }
        },
        formId: {
            type: String,
            default: ''
        },
        validateNow: {
            type: Boolean,
            default: false
        },
        sendRequestApiDuringSubmit: {
            type: Boolean,
            default: true
        },
        hasCaptcha: {
            type: Boolean,
            default: false
        }
    },
    data: function () {
        return {
            submitAfterValidationEnd: false,
            requestAlreadySent: false,
            captchaToken: null,
            captchaFieldName: 'token',
            captchaFieldTypeName: 'token_type'
        }
    },

    computed: {
        ...mapGetters({
            'getFormFieldsDataThatNeedToBeSend': 'forms/getFormFieldsDataThatNeedToBeSend',
            'getFormFieldData': 'forms/getFormFieldData',
        }),
        allValidated() {
            return this.statesList.every((name) => {
                return this.$store?.getters?.[`${name}/allRequiredAndNotEmptyValidated`] ?? false;
            })
        },
        formValidating() {
            return this.statesList.some((name) => {
                return this.$store?.getters?.[`${name}/formValidating`] ?? false;
            });
        },
        requiredFieldsNamesList() {
            return this.statesList.map((name) => {
                return (this.$store?.getters?.[`${name}/allRequiredFieldNames`] ?? []);
            });
        },
        captchaValue() {
            return this.getFormFieldData(this.formId, 'token')?.value;
        },
        captchaTypeValue() {
            return this.getFormFieldData(this.formId, 'token_type')?.value;
        },
        formUnderlineId() {
            return this.formId.replace(/-/g, '_')
        }
    },
    watch: {
        allValidated: {
            handler(newValue) {
                this.$emit('afterValidation', newValue)
            }
        },
        validateNow: {
            handler(newValue, oldValue) {
                this.$nextTick(() => {
                    newValue && !oldValue && this.validateForm()
                })
            },
        },
        formValidating: {
            handler(newValue, oldValue) {
                if (!newValue) {
                    this.validateForm(false);
                }
                if (!newValue && oldValue) {
                    setTimeout(() => {
                        this.submitAfterValidationEnd && (this.submitForm())
                        this.submitAfterValidationEnd = false
                    },0)
                }
            }
        },

    },
    methods: {
        getFormDataForSend() {
            return !!(this.formId) && this.formId !== '' ? this.getFormFieldsDataThatNeedToBeSend(this.formId) : {}
        },
        onSubmit() {
            if (this.requestAlreadySent || this.submitAfterValidationEnd) {
                return
            }
            this.submitAfterValidationEnd = true;
            this.validateForm();
        },
        async submitForm() {
            this.submitAfterValidationEnd = false;

            if (this.hasCaptcha){
                await this.getReCaptcha();
            }
            await this.validateForm(false);
            if (this.allValidated && (!this.hasCaptcha || this.captchaToken || this.captchaValue)) {
                const data = this.getFormDataForSend();
                this.$emit('submit', data);
                this.sendRequestApiDuringSubmit && await this.sendForm(data)
                if(this.hasCaptcha){
                    await this.$recaptcha.reset();
                }
            }
        },
        async validateForm(validate = true) {
            this.statesList.forEach((state) => {
                this.$store.dispatch(`${state}/setValidateFormState`, validate);
            })
        },
        setValidationErrors(errorsByFormNames) {
            this.$store.dispatch(`forms/setFormErrors`, {
                formId: this.formId,
                formErrors: errorsByFormNames
            })
        },
        async sendForm(formDataForSend) {
            if (!this.formId || this.formId == '') {
                return
            }
            this.requestAlreadySent = true;
            this.$store.dispatch(`forms/sendForm`, {
                formId: this.formId,
                data: formDataForSend
            }).then(responseList => {
                const allResponseSuccess = responseList.every(el=>el.status !== "rejected")
                if(allResponseSuccess){
                    const responseData = responseList.reduce(function (acc, item) {
                        acc[item.value.formName] = item.value.response.data
                        return acc
                    },{});
                    this.$emit('successSubmit',responseData);
                }else {
                    const errorData = responseList.reduce(function (acc, item) {
                        if(item.status !== "rejected"){
                            return acc
                        }
                        if(+(item?.reason?.value?.response?.status ?? 500) === 422 || (item?.reason?.value?.response?.data?.commonError ?? '') !== '' || (item?.reason?.value?.response?.data?.isAlreadyExist ?? false)) {
                            acc.data = Object.assign({}, acc.data, item.reason.value.response.data)
                        }
                        acc.statuses[item.reason.formName] = item.reason.value.response.status
                        return acc
                    },{
                        data: {},
                        statuses: {}
                    });
                    this.setValidationErrors(errorData.data)
                    this.$emit('errorSubmit', errorData.data, errorData.statuses);
                }
                this.requestAlreadySent = false;
            }).catch((e) => {
                this.$emit('errorSubmit', null);
                this.requestAlreadySent = false;
            })
        },
        async getReCaptcha(){
            const tokenV3 = await this.$recaptcha.execute(`submit_${this.formUnderlineId}`);
            if (tokenV3) {
                this.$store.commit(`forms/${types.UPDATE_FORM_VALUE_BY_ID}`, {
                    formId: this.formId,
                    fieldName: this.captchaFieldName,
                    value: tokenV3
                });
                this.$store.commit(`forms/${ types.UPDATE_FORM_VALUE_BY_ID }`, {
                    formId: this.formId,
                    fieldName: this.captchaFieldTypeName,
                    value: 'v3'
                });
            } else {
                console.error('Cannot load captcha')
            }
        }
    },
    beforeCreate() {
        this.$options.propsData.statesList = this.$options.propsData.statesList || [];
        this.$options.propsData.statesList.forEach((stateName) => {
            registerModule(this.$store, stateName)
        })
    },
    async mounted() {

    }
}
