export default {
    computed: {
        getDataFromOtherField: function () {
            return  !!(this.row?.getProperty?.from ?? false)
        },
        getSomePropFromParentField: function () {
            return this.getDataFromOtherField && (this?.row?.getProperty?.prop ?? false) && this.row.getProperty.prop.trim() !== '';
        },
        getPropertyParentFieldSelectedValue: function () {
            if (!this.getDataFromOtherField) {
                return '';
            }
            const parentField = this.rows.find(function (field) {
                return field.name === this.row.getProperty.from
            }.bind(this));
            return parentField && parentField.selectedValue || ''
        },
        valueFromOtherField: function () {
            if(this.getSomePropFromParentField){
                return  this.getPropertyParentFieldSelectedValue &&
                        typeof this.getPropertyParentFieldSelectedValue === 'object' &&
                        this.getPropertyParentFieldSelectedValue.hasOwnProperty(this.row.getProperty.prop) &&
                        this.getPropertyParentFieldSelectedValue[this.row.getProperty.prop]
                        || ''
            }
            return  this.getPropertyParentFieldSelectedValue;
        },
    },
    watch:{
        valueFromOtherField:{
            handler: function (newVal, oldVal) {
                if(this.getDataFromOtherField && JSON.stringify(newVal) !== JSON.stringify(oldVal)){
                    this.selectedValueUpdate(newVal);
                }
            },
            immediate: true
        }
    },
}
