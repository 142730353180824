export default {
    titleForm: 'Увійти',
    titleCreate: 'Створити аккаунт',
    forgotPass: 'Забув пароль?',
    formRowEmail: {
        label: 'Email',
        errorRequired: 'Поле Email має бути заповнене',
        errorEmail: 'Введений Email має неправильний формат'
    },
    formRowPass: {
        label: 'Пароль',
        errorRequired: 'Поле Пароль має бути заповнене',
        errorLength: 'Довжина поля Пароль має бути більше 7 символів',
        info: 'Пароль повинен містити щонайменше 8 знаків'
    },
    formText: 'Натискаючи кнопку «УВІЙТИ», я даю свою згоду на обробку моїх персональних даних відповідно до ',
    confidencePolitic: 'Політики конфіденційності adidas',
    or: 'або',
    createText: {
        top: 'Приєднуйся до програми лояльності adidas ADIDAS UNIVERSE:',
        b1: 'Накопичуй бали і отримуй знижку до 20%, якою ти можеш скористатись як на сайті, так і в роздрібних магазинах та дисконт-центрах',
        b2: 'Отримуй спеціальні умови та додаткові бонуси під час акцій',
        b3: 'Дізнавайся першим про спеціальні пропозиції та сезонні розпродажі',
        bottom1: 'Не гай часу - реєструйся в програмі лояльності ADIDAS UNIVERSE прямо зараз і починай накопичувати бали і збільшувати свою персональну знижку. Відкрий для себе краще від adidas.',
        bottom2: 'Хочеш дізнатися більше про ADIDAS UNIVERSE?'
    },
    buttonLogin: 'Увійти',
    buttonRegistry: 'Реєстрація',
    buttonPhone: 'Телефон',
    buttonFb: 'Facebook',
    buttonGoogle: 'Google',
    popupPhone: {
        title: 'Вхід по номеру телефону',
        inputPlaceholderPhone: 'Мобільний телефон',
        inputPlaceholderCode: 'Код підтвердження',
        codeSendToNumber: 'Код підтвердження відправлений на номер',
        buttonSend: 'Надіслати код',
        buttonConfirm: 'Підтвердити',
        buttonBreak: 'Скасувати',
        resend: 'Відправити ще раз',
        errorRequired: 'Поле має бути заповнене',
        errorPhone: 'Телефон введено неправильно',
        errorCode: 'Неправильний код підтвердження',
        errorCodeLength: 'Довжина коду підтвердження має складати {length} символи(ів)'
    },
    popupForgotPass: {
        title: 'Забув пароль',
        subtitle: 'Вкажи свій email, і ми відправимо на нього посилання для відновлення пароля.',
        textBold: 'Посилання для відновлення пароля відправлено',
        textEmail: 'Перевір E-mail',
        buttonClearPass: 'Скинути пароль',
        buttonClose: 'Закрити'
    },

    newPassword: {
        title: 'Створи новий пароль',
        subtitle: 'Введи новий пароль. Після зміни пароля ти залишишся в системі.'
    }
}
