
export default {
    name: "ButtonCounter",
    functional: true,
    props: {
        value: {
            type: [String, Number],
            default: 0
        }
    },
    render(createElement, {data, props}) {
        const count = +props.value;
        return createElement('div', {
            class: [
                'button-counter',
                data.staticClass,
                {'button-counter--empty': count === 0}
            ],
        }, [createElement('span', {}, [count])]);
    }
}
