//
//
//
//
//
//
//
//
//
//

import icons from "~/assets/img/icons";
import langSwitchPopup from '~/components/popups/LanguageSwitch';
export default {
    name: "FooterBottomRowLocalLanguage",
    data() {
        return {
            icons
        }
    },
    methods: {
        openLanguageSwitch() {
            this.$popups.open({
              component: langSwitchPopup
            })
        }
    }
}
