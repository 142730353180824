//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex";
import HeaderSearch from './HeaderSearch';
import HeaderIconBag from './HeaderIconBag';
import HeaderIconFavorite from './HeaderIconFavorite';

export default {
    name: "HeaderIconContainer",
    components: {
        HeaderSearch,
        HeaderIconBag,
        HeaderIconFavorite,
    },
    computed: {
        ...mapGetters({
            'isGuest': 'user/isGuest'
        }),
        accountLink() {
            return this.isGuest ? '/login' : '/account'
        },
    },
}
