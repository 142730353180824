export default {
    search: 'Пошук',
    language: 'українська',
    ukraine: 'Україна',
    subscribe: 'Підписатись',
    subscribeTo: 'Підписка на розсилку',
    pasteEmail: 'Введи e-mail',
    aboutUs: 'Про нас',
    deliveryAndPayment: 'Доставка і оплата',
    returns: 'Поверненння',
    contacts: 'Контакти',
    privacyPolicy: 'Політика конфіденційності'
}
