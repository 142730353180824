//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "CommonText",
    props: {
        italic: {
            type: Boolean,
            default: false
        },
        bold: {
            type: Boolean,
            default: false
        },
    },
}
