export default {
    title: 'Оформление заказа',

    head: {
        cart: 'Корзина',
        delivery: 'Доставка',
        payment: 'Оплата',
        thankYou: 'Заказ оформлен',
        questions: 'Вопросы',
        workHours: 'с 9:00 до 21:00',
        workDays: 'без выходных',
    },

    sidebar: {
        statusTitle: 'Статус заказа',
    },

    delivery: {
        title: 'Выбери адрес доставки',
        titleType: 'Выбери способ доставки',
        toPayment: 'Продолжить оформление',
        city: 'Населенный пункт',
        priceInfo: 'Цена и срок доставки зависят от способа доставки. На следующем шаге ты сможешь уточнить эту информацию.',
        addressTitle: 'Адрес доставки',
        addressInfo: 'Мы используем контакте данные только для того, чтобы информировать тебя о заказе.',
        warehouseTitle: 'Адрес отделения',
        warehouseInfo: 'Пожалуйста, уточни адрес, откуда тебе удобно забрать заказ, или выбери ближайшее отделение',
        onMap: 'на карте',
        wontSubscribe: 'Я хочу получать информацию о новинках, акциях и новостях adidas.',
        confidentiality: 'Я принимаю <a href="/gdpr" target="_blank">Политику конфиденциальности</a> и <a href="/rules" target="_blank">условия продажи</a>',
        loginNotification: 'Войди в аккаунт для быстрого оформления заказа',
        rulesTitle: 'Условия доставки',
        typeFreeDelivery1: 'Бесплатная доставка при заказе от 2500 грн.',
        typeFreeDelivery1Temp: 'Бесплатная доставка для заказов сделанных с 05.12 до 06.12.',
        typeFreeDelivery2: 'Бесплатная доставка без ограничений по сумме заказа',
        turnThis: 'Забрать здесь',
        readMore1: 'Подробнее',
        readMore2: 'Свернуть',
    },

    payment: {
        title: 'Оплата и подтверждение заказа',
        choosePaymentType: 'Выбери удобный способ оплаты',
        approveOrder: 'Подтвердить заказ',

        or: 'или',
        buy: 'Оплатить',
        cartNumber: 'Номер карточки',
        expireLabel: 'ММ/ГГ',
        expireInfo: 'Срок действия',
        cvvInfo: 'Код CVV2/CVC2',
        cvvPopover: '3-значный код на обратной стороне',

        sale: {
            paymentByCard: '(дополнительная скидка 5%)'
        },

        monobank: {
            title: 'monobank. Покупка частями',
            monthCount: 'Количество платежей: ',
            monthPay: 'Ежемесячный платеж:',
            month: 'месяц',
            btn: 'Оформить',
            infoText: '<b>Обрати внимание:</b> для покупки тебе должен быть доступен лимит на "Покупку частями" в приложении monobank. В случае возникновения вопросов о работе приложения обращайся на горячую линию monobank (ПАО «Универсалбанк») 0800205205',
            popover: 'Введи свой номер телефона, к которому привязан аккаунт monobank',
        },
    },

    confirmation: {
        title: 'Твой заказ успешно оформлен',
        orderNumber: 'Номер заказа',
        welcomeText: 'Привет, {name}, спасибо, что покупаешь в adidas! Мы отправим подтверждение на email <b>{email}</b> в ближайшее время.',
        deliveryDateTitle: 'Твой заказ будет доставлен:',
        infoOrder: 'Ты получишь письмо на email и СМС, когда твой заказ будет отправлен со склада.',
        askTitle: 'Что-то не так?',
        askText: 'Остались вопросы? Ищи ответы <a href="/help">в разделе Помощь</a>',
        deliveryDate: 'Доставка в период:',
    }
}
