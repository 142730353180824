//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import mainLayout from './layouts/Main';
import icons from "./icons";

export default {
    name: "LoginAndCart",
    components: {
        mainLayout
    },
    props: {
        layout: {
            type: String,
            default: 'main'
        },
        logged: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            icons
        }
    },
    computed: {
        componentIs() {
            return `${this.layout}Layout`
        }
    },
    methods: {
        toTop() {
            if (window) {
                window.scroll({ top: 0, behavior: 'smooth' })
            }
        }
    }
}
