
export default {
    name: "CommonHeadline",
    functional: true,
    props: {
        // Set number of "h" tag
        type: {
            type: [Number, String],
            default: 1
        },
        style: {
            type: Object,
            default: () => { return {} }
        }
    },
    render: function (createElement, {data, props, children}) {
        const elementData = {
            class: [
                'common-headline',
                `common-headline__h${props.type}`,
                data.staticClass
            ],
            style: data.style
        };
        return createElement('h' + props.type, elementData, children);
    }
}
