export default {
    Home: "Главная",
    home: "Главная",
    storefinder: "Поиск магазина",
    cart: "Корзина",
    "order-tracker": "Отследить заказ",
    favorites: "Избранные модели",
    registration: "Регистрация",
    login: "Вход в профиль",
}
