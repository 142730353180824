
import icons from "./components/icons";
import common from "./mixin/common";

export default {
    name: "SizeButton",
    mixins: [common],
    render: function (createElement, {data, props, children, listeners}) {
        const isLink = props.to && props.to.trim();
        const wrapperTag = isLink ? 'nuxt-link' : 'button';

        const iconElement = createElement('div', {
            domProps: {innerHTML: icons['ruler']},
            class: 'button-size__icon'
        });
        const textElement = createElement('div', {class: 'button-size__text'}, children);
        const button = createElement(wrapperTag, {
            class: [
                'button-size__btn',
                {'button-size__btn--disabled': props.disabled}
            ],
            attrs: {
                to: isLink && props.to,
                disabled: !isLink && props.disabled,
                'aria-label': props.ariaLabel
            },
            on: {
                click: listeners.click || (() => {})
            }
        }, [
            iconElement,
            textElement
        ]);
        return createElement('div', {
            class: [
                'button-size',
                data.staticClass
            ],
        }, [button]);
    }
}
