//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import fieldMixin from "./fieldMixin";
import RadioDeliveryItem from "@/components/common/LazyFormRow/components/components/RadioDeliveryItem";

export default {
    name: "RadioDelivery",
    mixins: [fieldMixin],
    components: {RadioDeliveryItem},
}
