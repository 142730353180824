//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex";
import backIcon from '~/assets/img/icons/arrow-left.svg?raw';
import MobileMenuListItem from '@/widgets/header/HeaderMain/components/HeaderMobileMenu/components/MobileMenuListItem/index';
import MobileMenuPage from '@/widgets/header/HeaderMain/components/HeaderMobileMenu/components/MobileMenuPage/index';

export default {
    name: "MobileMenuItems",
    components: {
        MobileMenuListItem,
        MobileMenuPage,
    },
    data() {
        return {
            backIcon: backIcon,
        }
    },
    computed: {
        ...mapGetters({
            activeColumn: 'header/getMobileMenuActiveColumn'
        }),
    },
    methods: {
        clearColumn() {
            this.$store.dispatch('header/setActiveMobileColumn', null);
        },
        closePopup(data) {
            if (data && data.href) {
                this.$emit('closeMenu')
                this.$store.dispatch('header/setMobileStatus', false);
            }
        },
        isEmpty(item) {
            return item.type === 'empty'
        },
        getColor(item) {
            return (item?.data?.color ?? '#000000');
        }
    }
}
