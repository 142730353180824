export default {
    'choseLang': {
        ua:'Обрати мову',
        ru:'Изменить язык'
    }, // for runtime text change in popup without all texts loading
    save:{
        ua:'Зберегти',
        ru:'Сохранить',
    }

}
