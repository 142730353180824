//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from "vuex"
import breakpointsListMixin from '~/mixins/breakpointsList.mixin';
export default {
    name: "ResponsiveImage",
    props: {
        classList: {
            type: String,
            default: 'image'
        },
        media: {
            type: Object,
            required: true
        },
        noLazy: {
            type: Boolean,
            default: false
        }
    },
    mixins: [
        breakpointsListMixin,
    ],
    data: function() {
        return {
            imageIndex: 1
        }
    },
    computed: {
        ...mapGetters({
            getCurrentImageCount: 'getCurrentImageCount',
            pageH1: 'dynamicPage/pageH1',
        }),
        desktopImg() {
            return this.media.src.desktop['1x'] !== this.media.src.desktop['2x'] && this.media.src.desktop['2x'].trim() !== '' ? (`${this.media.src.desktop['1x']} 1x, ${this.media.src.desktop['2x']} 2x`) : this.media.src.desktop['1x']
        },
        tabletImg() {
            return this.media.src.tablet['1x'] !== this.media.src.tablet['2x'] && this.media.src.tablet['2x'].trim() !== '' ? (`${this.media.src.tablet['1x']} 1x, ${this.media.src.tablet['2x']} 2x`) : this.media.src.tablet['1x']
        },
        mobileImg() {
            return this.media.src.mobile['1x'] !== this.media.src.mobile['2x'] && this.media.src.mobile['2x'].trim() !== '' ? (`${this.media.src.mobile['1x']} 1x, ${this.media.src.mobile['2x']} 2x`) : this.media.src.mobile['1x']
        },
        title (){
            const title = (this.media?.title ?? '');
            return title !== ''? title : `${this.pageH1} ${this.imageIndex}`
        },
        alt (){
            const alt = (this.media?.alt ?? '');
            return alt !== ''? alt : `${this.pageH1} ${this.imageIndex}`
        },
        isTabletUp() {
            return this.tabletSmallUp;
        },
        imgTypeLink() {
            if  (this.mobileDown) {
                return {
                    srcset: this.mobileImg,
                    src: (this.media?.src?.mobile?.['1x'] ?? '')
                }
            } else if (this.tabletSmallUp && this.tabletDown) {
                return {
                    srcset: this.tabletImg,
                    src: (this.media?.src?.tablet?.['1x'] ?? '')
                }
            } else {
                return {
                    srcset: this.desktopImg,
                    src: (this.media?.src?.desktop?.['1x'] ?? '')
                }
            }
        }
    },
    mounted() {
        this.imageIndex = this.getCurrentImageCount
        this.$store.commit('INCREASE_IMAGE_COUNT')
    }
}
