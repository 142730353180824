export default {
    Checkbox: 'RowCheckbox',
    checkbox: 'RowCheckbox',
    Radio: 'RowRadio',
    radio: 'RowRadio',
    RadioDelivery: 'RowRadioDelivery',
    radioDelivery: 'RowRadioDelivery',
    DateExchange: 'RowDateExchange',
    dateExchange: 'RowDateExchange',
    SecretCode: 'RowSecretCode',
    secretCode: 'RowSecretCode',
    Phone: 'RowPhone',
    phone: 'RowPhone',
    Hidden: 'RowHidden',
    hidden: 'RowHidden',
    Email: 'RowEmail',
    email: 'RowEmail',
    Password: 'RowPassword',
    password: 'RowPassword',
    Input: 'RowInput',
    input: 'RowInput',
    Text: 'RowInput',
    text: 'RowInput',
    "text-row": 'RowInput',
    Select: 'RowSelect',
    AutoSelect: 'RowAutoSelect',
    select: 'RowSelect',
    Number: 'RowNumber',
    number: 'RowNumber',
    date: 'RowHidden',// not a mistake, "фича"
    Date: 'RowHidden',// not a mistake, "фича"
}
