//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import formTypeNameToComponentMap from './formTypeNameToComponentMap'

import dependMixin from "./_rowMixins/dependMixin";
import dataLoadMixin from "./_rowMixins/dataLoadMixin";
import textsMixin from "./_rowMixins/textsMixin";
import validateMixin from "./_rowMixins/validateMixin";
import storeMixin from "./_rowMixins/storeMixin";
import viewMixin from "./_rowMixins/viewMixin";
import getPropertyMixin from "./_rowMixins/getPropertyMixin";
import icons from './icons';
import Input from "./components/Input";
import Select from "./components/Select";
import AutoSelect from "./components/AutoSelect";
import Radio from "./components/Radio";
import RadioDelivery from "./components/RadioDelivery";
import Phone from "./components/Phone";
import Number from "./components/Number";
import Email from "./components/Email";
import Hidden from "./components/Hidden";
import Password from "./components/Password";
import Checkbox from "./components/Checkbox";
import DateExchange from "./components/DateExchange";
import SecretCode from "./components/SecretCode";


export default {
    name: "CommonRowFormForm",
    mixins: [storeMixin, dependMixin, viewMixin, dataLoadMixin, getPropertyMixin, textsMixin, validateMixin],
    components: {
        RowInput: Input,
        RowSelect: Select,
        RowAutoSelect: AutoSelect,
        RowRadio: Radio,
        RowRadioDelivery: RadioDelivery,
        RowPhone: Phone,
        RowNumber: Number,
        RowEmail: Email,
        RowHidden: Hidden,
        RowPassword: Password,
        RowCheckbox: Checkbox,
        RowDateExchange: DateExchange,
        RowSecretCode: SecretCode,
    },
    model: {
        prop: 'rowData',
        event: 'input'
    },
    props: {
        rowData: {
            type: Object,
            default: function () {
                return {};
            }
        },
        stateName: {
            type: String,
            default: 'Forms'
        }
    },
    data() {
        return {
            id: null,

            formTypeNameToComponentMap: formTypeNameToComponentMap,
            defaultFieldName: 'RowInput',
            hiddenType: 'RowHidden',

            newRowData: null,
            blurCount: 0,
            focused: false,

            icons
        }
    },
    computed: {
        _value: function () {
            return this.value
        },
        _selectedValue: function () {
            return this.row.selectedValue
        },
        value: {
            get: function () {
                return (this.row?.value ?? null)
            },
            set: function (value) {
                this.setNewRowData(Object.assign({}, this.newRowData || this.row, {value: value}));
            }
        },
        filled: function () {
            return (this.row?.state?.filled ?? false)
        },
        dontShiftContent() {
            return !((this.row?.view?.shiftContentOnError ?? true))
        },
        isHalfRow() {
            return ((this.row?.view?.halfRow ?? false))
        },
        isHalfContainer() {
            return ((this.row?.view?.halfContainer ?? false))
        },
        showDescription() {
            return this.showInfoBlock && !this.canErrorBeShowed;
        },
        canErrorBeShowed() {
            return (this.showInfoBlock && this.showErrorText) || this.hasPermanentErrorMessage;
        },
        showTemperaryError () {
            return this.canErrorBeShowed && (!this.focused || this.fieldType.toLocaleLowerCase() === 'select' || this.fieldType.toLocaleLowerCase() === 'checkbox') && this.errorText && this.errorText !== ''
        },

        showErrorBlock() {
            return this.hasPermanentErrorMessage || this.showTemperaryError
        },
        lowercaseFieldType () {
            return this.fieldType.toLocaleLowerCase()
        },
        addFocusedClass (){
            return ['select'].includes(this.lowercaseFieldType) ? this.focused : false
        }
    },
    methods: {
        onFocus() {
            this.focused = true;
            this.$emit('focus');
        },
        onBlur() {
            this.blurCount++;
            this.focused = false;
            this.validate(true);
            this.$emit('blur');
        }
    },
    created() {
        // this.focused = this.filled
    }
}
