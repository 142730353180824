//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SectionTitle from "~/components/common/section/SectionTitle";
import SectionSubtitle from "~/components/common/section/SectionSubtitle";
import CommonBtnText from "~/components/common/buttons/TextBtn";

export default {
    name: "SectionHeader",

    components: {
        CommonBtnText,
        SectionSubtitle,
        SectionTitle
    },

    props: {
        title: {
            type: Object,
            default() {
                return {
                    text: "",
                    color: "",
                };
            }
        },
        description: {
            type: Object,
            default() {
                return {
                    text: "",
                    color: "",
                };
            }
        },
        link: {
            type: Object,
            default() {
                return {
                    text: "",
                    color: "",
                    href: ""
                };
            }
        }
    },

    computed: {
        showLinkBlock() {
            return (this.link.href &&
                this.link.text &&
                this.link.href !== "" &&
                this.link.text !== "");
        },
    },

};
