//
//
//
//
//
//
//
//
//

import SearchRecommendationProductCard
    from "@/components/pages/search/SearchRecommendation/SearchRecommendationProductCard";
export default {
name: "SearchRecommendationProducts",
    components: {SearchRecommendationProductCard},
    props: {
        products: {
            type: Array,
            default: () => { return [] }
        }
    },
    methods: {
        closePopup() {
            this.$emit('close')
        }
    }
}
