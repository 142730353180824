import heartBlack from "./heart-black.svg?raw"
import heart from "./heart.svg?raw"
import arrowRight from "./arrow-right.svg?raw"
import arrowLeft from "./arrow-left.svg?raw"
import longArrowRight from "./long-arrow-right.svg?raw"
import longArrowLeft from "./long-arrow-left.svg?raw"
import eye from "./eye.svg?raw"
import eyeClose from "./eye-close.svg?raw"
import ruler from "./ruler.svg?raw"
import profile from "./profile.svg?raw"
import bag from "./bag.svg?raw"
import search from "./search.svg?raw"
import instagram from "./instagram.svg?raw"
import youtube from "./youtube.svg?raw"
import close from "./close.svg?raw"
import closeBig from "./close-big.svg?raw"
import mobileMenu from "./mobile-menu.svg?raw"
import loader from "./loader.svg?raw"
import back from "./back.svg?raw"
import check from "./check.svg?raw"
import fullScreen from "./fullScreen.svg?raw"

export default {
    heartBlack,
    heart,
    arrowRight,
    arrowLeft,
    longArrowRight,
    longArrowLeft,
    eye,
    eyeClose,
    ruler,
    profile,
    bag,
    search,
    instagram,
    youtube,
    close,
    closeBig,
    mobileMenu,
    loader,
    back,
    check,
    fullScreen
}
