export default {
    // SUBSCRIBE
    subscribe: {
        buttonText: 'Підпишись на розсилку',
        mainText: 'Підпишись на новини та отримай знижку -10%!'
    },

    // LOGIN AND CART
    loginAndCart: {
        myAccount: 'Мій аккаунт',
        login: 'Увійти',
        cart: 'Кошик',
        toTop: 'Вгору'
    },

    // FOOTER
    footer: {
        followUs: 'Слідкуй за нами',
        ukraine: 'Україна',
        legalInfo: 'Юридична інформація',
        privacyData: 'Обробка персональних даних',
        rules: 'Умови',
        rights: 'ДП "Адідас-Україна". Всі права захищені'
    }
}
