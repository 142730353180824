export default {
    cart: 'Кошик',
    addToCart: 'Додати в кошик',
    addedToCart: 'Додано у кошик',
    added: 'Додано',
    addLoading: 'Додається...',
    freeDelivery: 'Безкоштовна доставка замовлень на суму від 2500 грн',
    payOnline: 'ЗНИЖКА 5% при оплаті картою на сайті',
    freeDeliveryTemp: 'Безкоштовна доставка на всі замовлення в період з 05.12 до 06.12',
    monobank: 'покупка частинами разом із',
    chooseSize: 'Обрати розмір',
    sizeChart: 'Таблиця розмірів',
    availableColors: 'Доступні кольори',
    chooseYourSize: 'Обери свій розмір',
    onWarehouse: 'Залишилось на складі',
    gallery: 'Галерея',
    description: 'Опис',
    care: 'Догляд',
    aboutModel: 'Про модель',
    modelColor: 'Колір моделі',
    modelArticle: 'Номер моделі',
    careRecommendation: 'Рекомендації з прання',
    extraCareRecommendation: 'Додаткова інформація по догляду',
    color: 'Колір',
    size: 'Розмір',
    count: 'Кількість',
    countShort: 'К-сть',
    withPDV: '(включно з ПДВ)',
    totalSum: 'Загальна сума',
    totalProduct: 'Усього товарів',
    costProduct: 'Вартість товарів',
    infoRow: {
        row1: 'вказано британський розмір, перевір в таблиці розмірів',
        row2: 'безкоштовна доставка замовлень від 2500 грн',
        row3: 'доступні способи доставки: відділення або поштомат "нова пошта", кур\'єрська доставка',
        row4: '60 днів на повернення товару',
    },
    sizeEmpty: {
        title: 'Товар повністю розпроданий',
        text: 'На жаль, на даний момент цього товару немає в наявності.',
    }
}
