
import icons from "./components/icons"
import common from "./mixin/common";

export default {
    name: "SecondaryButton",
    mixins: [common],
    render: function (createElement, {data, props, children, listeners}) {
        const isLink = props.to && props.to.trim();
        const wrapperTag = isLink ? 'nuxt-link' : 'button';
        const iconElement = createElement('div', {
            domProps: {innerHTML: icons['longArrowRight']},
            class: 'button-secondary__icon'
        });
        const textElement = createElement('div', {class: 'button-secondary__text'}, children);
        const button = createElement(wrapperTag, {
            class: [
                'button-secondary__btn',
                {'button-secondary__btn--disabled': props.disabled}
            ],
            attrs: {
                to: isLink && props.to,
                disabled: !isLink && props.disabled,
                'aria-label': props.ariaLabel
            },
            on: {
                click: listeners.click || (() => {})
            }
        }, [
            textElement,
            iconElement
        ]);
        return createElement('div', {
            class: [
                'button-secondary',
                data.staticClass
            ],
        }, [button]);
    }
}
