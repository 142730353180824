export default {
    search: 'Поиск',
    searchHistory: 'История поиска',
    pageTitle: 'Мы не смогли ничего найти по запросу',
    pageSubtitle: 'Не сдавайся! Проверь написание или перефразируй поисковый запрос.',
    pageSomeElse: 'Тебе может понравится:',
    pageWeRecommended: 'Мы рекомендуем',
    clear: 'очистить',
    showAll: 'Смотреть все',

    // Error page

    errorPageTitle: 'Страница не найдена, но не стоит сдаваться',
    errorSubtitle: 'Введи свой запрос тут',
    errorSomeElse: 'Проверь корректность написания адреса в строке браузера. Или вернись на',
    errorSomeElse2: 'главную страницу',
    errorSomeElse3: 'и попробуй еще раз.'
}
