//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VPopup from "~/plugins/VPopup/popup";

export default {
    name: "InfoPopup",

    components: {
        VPopup,
    },

    props: {
        title: {
            type: Object,
            default() {
                return {
                    text: "",
                };
            }
        },
        description: {
            type: Object,
            default() {
                return {
                    text: "",
                };
            }
        },
        submitButton: {
            type: Object,
            default() {
                return {
                    text: "",
                    clickCallback: () => {
                    }
                };
            }
        },

    }
};
