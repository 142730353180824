//

export default {
    name: "FooterColumnLink",
    functional: true,
    render: function (createElement, {props,children, data}) {
        const isLink = props.to && props.to.trim();
        const wrapperTag = isLink ? 'nuxt-link' : 'p';
        return createElement(wrapperTag,{
            class: {
                ...(data.staticClass && {
                    [data.staticClass]: true,
                })
            },
            attrs: {
                ...data.attrs,
            }

        }, [children])
    }
}
