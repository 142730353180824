
import icons from "./components/icons";
import common from "./mixin/common";

export default {
    name: "ShortArrowButton",
    mixins: [common],
    props: {
        // Set arrow button direction: right | left
        direction: {
            type: String,
            default: 'right'
        },

        // Set small button size
        small: {
            type: Boolean,
            default: false
        },
        // Set middle button size
        middle: {
            type: Boolean,
            default: false
        }
    },
    render: function (createElement, {data, props, listeners}) {
        const isLink = props.to && props.to.trim();
        const wrapperTag = isLink ? 'nuxt-link' : 'button';

        const middleArrow = createElement('div', {
            domProps: {innerHTML: icons[props.direction === 'left' ? 'longArrowLeft' : 'longArrowRight']},
            class: 'button-short__icon'
        });
        const normalArrow = createElement('div', {
            domProps: {innerHTML: icons[props.direction === 'left' ? 'arrowLeft' : 'arrowRight']},
            class: 'button-short__icon'
        });
        const iconElement = props.middle ? middleArrow : normalArrow;

        const button = createElement(wrapperTag, {
            class: [
                'button-short__btn',
                {'button-short__btn--disabled': props.disabled}
            ],
            attrs: {
                to: isLink && props.to,
                disabled: !isLink && props.disabled,
                'aria-label': props.ariaLabel
            },
            on: {
                click: listeners.click || (() => {})
            }
        }, [iconElement]);
        return createElement('div', {
            class: [
                'button-short',
                data.staticClass,
                {'button-short--small': props.small},
                {'button-short--middle': props.middle}
            ],
        }, [button]);
    }
}
