export default {
    props: {
        styleType: {
            required: false,
            type: String,
            default: ''
        },
        info: {
            required: true,
            type: Object
        }
    },
    computed: {
        url: function (){
            return (this?.info?.href ?? '/');
        },
        dividerBefore: function () {
            return (this?.info?.dividerBefore ?? false);
        }
    }
}
