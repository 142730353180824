export default function throttle(callback, delay = 0) {
  let timeoutId;
  let lastArgs;
  let lastThis;

  return function(...args) {
    const context = this;

    lastArgs = args;
    lastThis = context;

    if (!timeoutId) {
      timeoutId = setTimeout(() => {
        timeoutId = null;
        callback.apply(lastThis, lastArgs);
      }, delay);
    }
  };
}
