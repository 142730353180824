//
//
//
//
//
//
//
//
//

import FooterBottomRowLocal from "./FooterBottomRowLocal";
import { links } from '~/utils/config';

export default {
    name: "FooterBottomRow",
    components: {FooterBottomRowLocal},
    data() {
        return {
            links
        }
    },
    computed: {
        lang() {
            return this.$i18n.locale
        },
        privacyLink() {
            return this.lang === 'ru' ? '/politika-konfidenczialnosti-programmy-loyalnosti-adidas-universe' : '/politika-konfidenczijnosti-programi-loyalnosti-adidas-universe'
        },
        rightsLink() {
            return this.lang === 'ru' ? '/publichnyj-dogovor-kupli-prodazhi' : '/publichnij-dogovir-kupivli-prodazhu'
        }
    }
}
