export default {

    computed: {
        hasTextsInfo: function () {
            return this.row.hasOwnProperty('texts')
        },
        hasLabel: function () {
            return this.hasTextsInfo && this.row.texts.hasOwnProperty('label') && this.row.texts.label.trim().length > 0
        },
        label: function () {
            return this.hasLabel ? this.row.texts.label : ''
        },
        hasDescription: function () {
            return this.hasTextsInfo && this.row.texts.hasOwnProperty('description') && this.row.texts.description.trim().length > 0
        },
        description: function () {
            return this.hasDescription ? this.row.texts.description : ''
        },

    }
}
