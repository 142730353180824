//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import fieldMixin from "./fieldMixin";
import MaskInput from "./components/MaskInput";

export default {
    name: "DateExchange",
    mixins: [fieldMixin],
    data: function () {
        return {
            // mask:
        }
    },
    computed:{
        mask(){
            return AppConfig.inputMask.expireDate
        }
    },
    components: {MaskInput},
    created() {
    }
}
