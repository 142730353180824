import instagram from "./instagram.svg?raw";
import youtube from "./youtube.svg?raw";
import facebook from "./facebook.svg?raw";
import flag from "./flag.svg?raw";

export default {
    instagram,
    youtube,
    facebook,
    flag
}
