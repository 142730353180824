//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LoginAndCartMainItem from "./components/Item";
import {mapGetters} from "vuex";
import { links } from '~/utils/config';

export default {
    name: "Main",
    components: {LoginAndCartMainItem},
    props: {
        logged: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapGetters({
            inCartCount: 'cart/getCartLength',
            isGuest: 'user/isGuest',
        }),
        items() {
            return [
                {
                    title: !this.isGuest ? this.$t('widgets.loginAndCart.myAccount') : this.$t('widgets.loginAndCart.login'),
                    icon: !this.isGuest ? '' : 'profile',
                    to: !this.isGuest ? '/account' : '/login'
                },
                {
                    title: this.$t('widgets.loginAndCart.cart'),
                    icon: 'bag',
                    to: (links?.loginAndCart?.cart ?? '/'),
                    count: this.inCartCount
                }
            ]
        }
    }
}
