//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import iconCheck from '~/assets/img/icons/check-flat.svg?raw'
import iconPlace from '~/assets/img/icons/place.svg?raw'
import iconDelivery from '~/assets/img/icons/delivery-ico.svg?raw'

    export default {
        name: "RadioDeliveryItem",
        props: {
            disabled: {
                type: Boolean,
                default: false
            },
            name: {
                type: String,
                required: true
            },
            value: {
                type: [Object, String, Number],
                default: null,
                validator: (p) => {
                    return ['string', 'object'].indexOf(typeof p) !== -1 || p === null;
                },
            },
            item: {
                type: Object,
                required: true
            },
            text: {
                type: String,
                default: ''
            }
        },
        computed: {
            checked() {
                return this.value && this.item && this.item.id === this.value.id;
            },
            iconDelivery() {
                return this.item.type === 'address' ? this.iconDeliveryBus : this.iconPlace
            }
        },
        data() {
            return {
                iconCheck: iconCheck,
                iconPlace: iconPlace,
                iconDeliveryBus: iconDelivery,
                ukPostId: '7192d8eb-d869-4c22-a958-8d87ddfe6290',
            }
        }
    }
