export default function esEvent(eventName, eventBody) {
    console.log(`%c eSputnik eventName:%c ${ eventName } %c eventBody: `,
        'background: #ffffff; color: black;',
        'background: green; color: yellow;',
        'background: #000; color: #c30677;',
        eventBody);

    if (!eventName) {
        console.error('No esEvent name');
        return;
    }

    if (!window.eS) {
        console.error('No window.eS fuc', window.eS);
        return;
    }

    if (eventBody) {
        window.eS('sendEvent', eventName, eventBody);
    } else {
        window.eS('sendEvent', eventName);
    }
}
