//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from 'vuex';
import FooterFollowUs from "./FooterFollowUs";
import FooterColumnLink from "./FooterClolumnLink";
import breakpointsListMixin from '~/mixins/breakpointsList.mixin';
import FooterAppsLink from "~/widgets/footer/components/FooterAppsLink.vue";

export default {
    name: "FooterTopRow",
    components: {FooterAppsLink, FooterColumnLink, FooterFollowUs},
    mixins: [
        breakpointsListMixin,
    ],
    computed: {
        ...mapGetters({
            columns: 'core/getFooterColumns',
            columnsMobile: 'core/getFooterMobileColumns'
        }),
        columnsList() {
            return this.tabletLandscapeUp ? this.columns : this.columnsMobile
        },
        isLandscapeDown() {
            return !this.tabletLandscapeUp;
        },
        isMobile() {
            return this.tabletSmallDown;
        }
    },
    methods: {
        hasNoTitle(title) {
            return !title || title.trim() === ''
        },
        isLast (item) {
            return item.id === this.columnsList[this.columnsList.length - 1].id
        },
    },
}
