export default {
    mobileSmall: 375,
    mobile: 640,
    tabletSmall: 768,
    tablet: 1024,
    tabletLandscape: 1120,
    laptopSmall: 1280,
    laptop: 1440,
    desktop: Infinity
};
