//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import icons from "../icons";
import { appConfig, links } from '~/utils/config';

export default {
    name: "FooterFollowUs",
    data() {
        return {
            icons,
            links
        };
    },
    computed: {
        iconsData() {
            return [
                appConfig?.mainInfo?.socialBlock?.instagram,
                appConfig?.mainInfo?.socialBlock?.youtube,
                appConfig?.mainInfo?.socialBlock?.facebook,
            ];
        }
    }
};
