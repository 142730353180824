export default {
    "delivery": {
        "city": {
            'default': {
                name: 'cities',
            },
            'withSelected': {
                name: 'cityPage',
            }
        },
        "type": {
            'default': {
                name: 'types',
            },
            'withSelected': {
                name: 'types',
            }
        },
        "warehouse": {
            'default': {
                name: 'warehouses',
            },
            'withSelected': {
                name: 'warehouses',
                additionParams: {
                    'showAll': 1
                }
            }
        },
        "street": {
            'default': {
                name: 'streets',
            },
            'withSelected': {
                name: 'streets',
                additionParams: {
                    'showAll': 1
                }
            }
        },
        "postCode": {
            'default': {
                name: 'postCode',
            },
            'withSelected': {
                name: 'postCode',
            }
        },
    }
}
