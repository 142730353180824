//
//
//
//
//
//
//

import {TheMask} from 'vue-the-mask'

export default {
    extends: TheMask,
    name: "PhoneMask"
}
