import {
    maxLength,
    minLength,
    minValue,
    length,
    maxValue,
    value,
    required,
    email,
    phone,
    date,
    minDate,
    maxDate,
    expireDate,
    cyrillic,
    orderNumber
} from "../../validators/validators";
import {set} from "vue-gtag";

export default {
    data: function () {
        return {
            validatorsMap: {
                minLength: minLength,
                maxLength: maxLength,
                minValue: minValue,
                maxValue: maxValue,
                date: date,
                value: value,
                length: length,
                required: required,
                email: email,
                phone: phone,
                minDate: minDate,
                maxDate: maxDate,
                expireDate: expireDate,
                cyrillic: cyrillic,
                orderNumber: orderNumber
            },
            validateTimeout: null,
            validateAfterDataLoad: false
        }
    },
    computed: {
        hasValidateSettings: function () {
            return (this?.row?.validateSettings ?? false) && typeof this.row.validateSettings === 'object';
        },
        hasRequiredValidation: function () {
            return this.hasValidateSettings && this.row.validateSettings.some(function (item) {
                return item.name === 'required' && item.value
            })
        },
        required: function () {
            return this.hasRequiredValidation && this.dependRulesValidated;
        },
        errorText: function () {
            let errText = ''
            if (this.hasValidateSettings)  {
                const errorsList = {};
                this.row.validateSettings.forEach(function (item) {
                    !this.$v.value[item.name] && (errorsList[item.name] = (item.text))
                }.bind(this));

                const keys = Object.keys(errorsList);
                errText = errorsList[keys[keys.length > 1 && keys[0] === 'required' && this.filled ? 1 : 0]] || errText;
            }

            return errText.trim() !== this.permanentErrorMessage.trim() ? errText : ''
        },
        showErrorText: function () {
            return this.showError && this.errorText.trim() !== ''
        },
        errorState: function () {
            return (this.row?.error?.show ?? false);
        },
        showError: function () {
            return this.errorState && this.blurCount
        },
        showSuccess() {
            return !this.showError && this.row.state.validated && this.blurCount
        },
        rowValidators: function () {
            const validationRules = {};
            this.hasValidateSettings && this.row.validateSettings.forEach(function (item) {
                const validator = this.getValidator(item.name);
                if (validator) {
                    validationRules[item.name] = validator((item?.value ?? '')) || true;
                }
            }.bind(this));
            return validationRules
        },
        validateRowNow() {
            return (this?.row?.state?.validateNow ?? false);
        },
        isValidatingNow() {
            return (this.row?.state?.validating ?? false)
        },
    },
    watch: {
        validateRowNow: {
            handler: async function (newVal, oldVal) {
                !oldVal && newVal && await this.validate(true);
            },
        },
        validateFormNow: {
            handler: async function (newVal, oldVal) {
                this.validateTimeout && clearTimeout(this.validateTimeout)
                this.validateTimeout = setTimeout(()=>{
                    newVal && this.validate(true);
                })
            },
        },
        '$v.value.$error': {
            handler: function (newVal) {
                newVal !== this.errorState && this.setNewErrorState(newVal);
            }
        },
    },
    methods: {
        getValidator(name) {
            return this.validatorsMap.hasOwnProperty(name) ? this.validatorsMap[name] : null;
        },
        setNewErrorState(show = false, force = false) {
            const showError = (this.blurCount > 0 && show) || (show && force);
            const rowInfo = (this.newRowData || this.row);
            this.newRowData = Object.assign({}, rowInfo, {
                error: Object.assign(
                    {},
                    rowInfo.error,
                    {
                        show: showError,
                        text: this.errorText
                    }
                ),
                state: Object.assign({}, rowInfo.state, {
                    validated: !show,
                    validating: false
                })
            });
            this.setNewRowData(this.newRowData);
        },
        setRowValidatedState(validatedState) {
            validatedState = typeof validatedState === 'boolean' ? validatedState : this.row.state.validating;
            const rowInfo = (this.newRowData || this.row);
            this.newRowData = Object.assign({}, rowInfo, {
                state: Object.assign({}, (rowInfo?.state ?? {}), {
                    validating: validatedState,
                    validateNow: !validatedState ? false : (rowInfo?.state?.validateNow ?? false),
                })
            });
            this.setNewRowData(this.newRowData);
        },
        async validate(force = false) {
            if(this.isValidatingNow){
                return
            }
            force && !this.blurCount && this.showRow && this.blurCount++;
            this.setRowValidatedState(true);
            if (process.browser) {
                this.$v.$touch();
            }
            setTimeout(async () => {
                this.$v && this.setNewErrorState(this.$v.value.$error, force);
            }, 10)
        }
    },
    validations: function () {
        return {
            value: this.rowValidators
        }
    },
}
