
import icon from '~/assets/img/icons/mobileMenuArrow.svg?raw'

export default {
    functional: true,
    name: "MobileMenuListItem",
    props: {
        to: {
            type: String,
            default: '',
        },
        btn: {
            type: Object,
            default: () => { return {} },
        }
    },
    render: function (createElement, {data, props, children, listeners}) {
        if (props.btn.data) {
            const isLink = props.to && props.to.trim();
            const wrapperTag = isLink ? { name: 'nuxt-link' } : 'div';

            let style = {
                fontWeight: 400,
                color: '#000000',
            };

            style.color = props.btn.data.color ? props.btn.data.color : '#000000';
            style.fontWeight = props.btn.data.style === 'headline' ? 700 : props.btn.data.style || 400;

            const iconElement = createElement('div', {
                domProps: {innerHTML: icon},
                class: 'list-item__icon',
                style: {fill: style.color}
            });
            const textElement = createElement('div', {class: 'list-item__title'}, props.btn.data.text);
            return createElement(wrapperTag, {
                class: [
                    'list-item',
                    data.staticClass,
                ],
                style: style,
                attrs: {
                    to: isLink && props.to,
                    disabled: !isLink && props.disabled,
                },
                on: {
                    click: listeners.click || ((e) => e)
                },
            }, [
                textElement,
                isLink ? '' : iconElement
            ]);
        } else {
            return false;
        }
    }
}
