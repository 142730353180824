//
//
//
//
//
//
//

export default {
name: "SubscribeThanks"
}
