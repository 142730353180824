//
//
//
//
//
//

export default {
    name: "CommonContainer",
    // functional: true,
    props: {
        fillHeight: {type: Boolean, default: false}
    }
}
