//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ResponsiveImage from "@/components/common/ResponsiveImage";
import CommonCountdown from "~/components/common/CommonCountdown";
export default {
    name: "HeaderUspBar",
    components: {CommonCountdown, ResponsiveImage},
    props: {
        data: {
            type: Array,
            default: () => ([])
        }
    },
    data() {
        return {
            showBody: false,
            activeLink: {},
            activeLinkIndex: 0,
        }
    },
    computed: {
        body() {
            if (typeof document !== 'undefined') {
                return document.querySelector('body');
            } else  {
                return false;
            }
        },
        items() {
            return this.data.slice(0, 3)
        },
    },
    methods: {
        animateBar() {
            this.activeLink = this.items[this.activeLinkIndex++];
            setInterval(()=>{
                this.$refs.animateText.classList.add('fade-out');
                this.activeLinkIndex = this.activeLinkIndex < this.items.length ? this.activeLinkIndex : 0;
                setTimeout(()=>{
                    this.activeLink = this.items[this.activeLinkIndex++];
                    this.$refs.animateText.classList.remove('fade-out');
                }, 50)
            }, 5000)
        },
        openBody() {
            this.showBody = true;
            this.body && this.body.classList.add("v-popup-open")
        },
        closeBody() {
            this.showBody = false;
            this.body && this.body.classList.remove("v-popup-open")
        },
    },
    mounted() {
        this.animateBar();
    },
    beforeDestroy() {
        this.$emit('height', 0)
    }
}
