//
//
//
//
//
//
//
//

import {mapGetters} from 'vuex';
import SearchInput from '~/components/pages/search/SearchInput';

export default {
    name: "HeaderSearch",
    components: {
        SearchInput,
    },
    data() {
        return {
            searchInput: ''
        }
    },
    computed: {
        ...mapGetters({
            getGlobalSearch: 'search/getGlobalSearch'
        }),
    },
    watch: {
        getGlobalSearch(val) {
            this.searchInput = val;
        }
    },
}
