//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex";

export default {
    name: "FormRecaptca",
    props: {
        hasError: {
            type: Boolean,
            default: false
        },
        formId: {
            type: String,
            default: ''
        },
        id: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
            widgetId: 0,
            checkCaptchaV2: false,
            captchaFieldName: 'token',
            captchaFieldTypeName: 'token_type',
        }
    },
    methods: {
        setTokenType(ver = 'v2') {
            this.$store.commit(`forms/${ types.UPDATE_FORM_VALUE_BY_ID }`, {
                formId: this.formId,
                fieldName: this.captchaFieldTypeName,
                value: ver
            });
        },
        onSuccess(token) {
            this.checkCaptchaV2 = true;
            if (token) {
                this.$store.commit(`forms/${ types.UPDATE_FORM_VALUE_BY_ID }`, {
                    formId: this.formId,
                    fieldName: this.captchaFieldName,
                    value: token
                });
                this.setTokenType('v2');
            }
        },
        onExpired() {
            this.$store.commit(`forms/${ types.UPDATE_FORM_VALUE_BY_ID }`, {
                formId: this.formId,
                fieldName: this.captchaFieldName,
                value: ''
            });
        }
    },
    computed: {
        ...mapGetters({
            'getFormFieldData': 'forms/getFormFieldData',
        }),
        reCaptchaV2Key() {
            return PARAMS.reCaptchaV2
        },
        captchaTypeValue() {
            return this.getFormFieldData(this.formId, 'token_type')?.value;
        },
        showReCaptcha() {
            return this.captchaTypeValue === 'v2'
        },
        showError() {
            return this.showReCaptcha && !this.checkCaptchaV2 && this.hasError;
        },
        computedId(){
            return this.id + '___' + this.formId;
        },
    },
    async mounted() {
        try {
            await this.$recaptcha.init();
        } catch (error) {
            console.error('Captcha error:', error)
        }
    }
}
