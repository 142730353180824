export default {
    title: 'Особистий кабінет',
    readMore: 'Дізнатися більше',
    welcomeText: 'З поверненням',
    welcomeDelivery: 'Раді знову тебе бачити - давай уточнимо усі деталі доставки...',

    // DETAILS
    titleDetails: 'Мої дані',
    accountDetails: 'Особисті дані',
    profile: {
        title: 'Мої дані',
        checkDetails: 'Будь ласка, перевір вказані дані',
        data: 'Дані',
        infoTitle: 'Дані для входу в акаунт',
        changeProfile: 'Змінити профіль',
        removeAccount: 'Видалити акаунт',
        removeInfo: 'Після видалення акаунту ти більше не зможеш бути учасником adidas Universe.'
    },

    // HISTORY
    titleHistory: 'Історія замовлень',
    history: {
        title: 'Історія замовлень',
        findAnother: 'Шукаєш інше замовлення?',
        findOrder: 'Відстежити замовлення',
        yourOrder: 'Твоє замовлення',
        showDetail: 'Дивитися деталі замовлення',
        loadMore: 'Завантажити ще',

        expectedDateLabel: 'Очікувана дата доставки',
        details: 'Деталі',
        order: 'Замовлення',
        payment: 'Оплата',
        deliveryType: 'Метод доставки',
        address: 'Адреса',
        delivery: 'Доставка',
        discount: 'Знижка',
        summaryLabel: 'Загальна сума',
    },

    // ADDRESSES
    titleAddresses: 'Адреси доставки',
    savedAddresses: 'Збережені адреси',

    addresses: {
        title: 'Адреси',
        newBtn: 'Нова адреса',
        emptyList: 'У тебе поки немає збережених адрес',
        changeBtn: 'Змінити адресу',
        removeBtn: 'Видалити',
        byDefault: 'Адреса за замовчуванням',
        titleAdd: 'Додати адресу',
        courierDelivery: 'Кур\'єрська доставка',
        Kyiv: 'Київ',
        Khreshchatyk: 'вул. Хрещатик',
        enterPhoneInfo: 'Введи актуальний номер мобільного телефону. На цей номер ти отримаєш інформацію щодо статусу свого замовлення',
        saveBtn: 'Зберегти',
        cancelBtn: 'Відмінити',
    },

    // LOYALTY
    titleLoyalty: 'Клубна програма',

    // preferences page
    preferences: {
        title: 'Вподобання',
        subscribe: 'Підписатися',
        subscribeTitle: 'Розсилка adidas',
        subscribeText: 'Підпишись на розсилку adidas, щоб першим (ою) дізнаватися про акції, лімітовані колекції, спеціальні пропозиції, заходи і конкурси. Нижче ти можеш вибрати, про що саме тобі було б цікаво дізнаватися.',
        subscribeRadio: 'Я згоден на обробку моїх персональних даних, включаючи транскордонну передачу та передачу третім особам, уповноваженим ДП «Адідас-Україна» для здійснення цілей маркетингу, реклами і вивчення думок групою компаній adidas. Я прочитав Політику Конфіденційності і згоден з її положеннями. Я розумію, що можу відкликати свою згоду, слідуючи по спеціальному посиланню в повідомленнях від adidas. Я погоджується отримувати персоналізовані маркетингові повідомлення електронною поштою від ТОВ «АДІДАС» і runtastic GmbH ( "runtastic"), а також для adidas і runtastic для обробки моїх Персональних Даних з метою отримання і перегляду таких повідомлень.',
        youSubscribed: 'Ти підписаний на нашу розсилку',
        unsubscribe: '* Щоб відписатися, прибери галочку у відповідному полі',
        interestedTitle: 'Обери те, що тобі цікаво',
        interestedText: 'Щоб отримувати тільки потрібну інформацію, вибери, які напрямки тобі подобаються.',
        updateInterested: 'Онови список вподобань',
    },

    // FAVORITES
    addToFavorite: 'Додати в обране',
    favorite: 'Вподобання',
    favoriteList: 'Список бажань',
    titleFavorites: 'Обране',
    favoriteModel: 'Обрані моделі',
    favoriteTotal: 'вподобані моделі',
    favoriteEmpty: 'Список обраних моделей порожній. Почни робити покупки та додай вподобані моделі до обраного.',

    //RegisterBanner
    registerTitle: 'Слідкуй за списком вподобаних моделей',
    registerText: 'Вступай в adidas universe і отримай знижку 10% на перше замовлення. Або увійди в свій акаунт та збережи вподобану(ні) модель(лі).',
    registerLoginQuestion: 'Вже є участником adidas universe?',

    welcomeHead: {
        hi: 'Привіт',
        not: 'Не',
        logout: 'Вийти',
    },
    cartWidget: {
        inCart: 'У кошику',
        totalProducts: 'Усього товарів',
        goToCart: 'Перейти в кошик',
    },
    order: {
        dateOrder: 'Дата замовлення',
        showDetail: 'Дивитися деталі замовлення',
    },
    coupons: {
        title: 'Твої купони',
        infoExpired: 'Промокод можна активувати тільки один раз. Бали дійсні протягом 1 року.',
        isCopy: 'Промокод скопійований',
        showPromoCode: 'Показати промокод',
        hidePromoCode: 'Сховати промокод',
        emptyList: 'Немає додаткових промокодів.',
        onlyForWeb: 'Купон діє тільки на сайті adidas.ua',
        onlyForMobile: 'Купон діє тільки в додатку',
    },
    universe: {
        title: 'adidas universe бали та знижки',
        description: 'ADIDAS UNIVERSE — це програма лояльності, що надає прогресивну знижку до 20% на продукцію adidas в фірмових магазинах та дискотн-центрах adidas, на сайті adidas.ua, а також у мобільному додатку adidas.',
        expired: 'Твоя знижка діє ще',
        days: 'дні (днів)',
        points: 'балів',
        currentLevel: 'Поточний рівень',
        accumulate1: 'Накопич ще',
        accumulate2: 'балів',
        accumulate2_1: 'до',
        accumulate3: 'та досягни наступного рівня знижки!',
        accumulate3_1: '1 бал - 1 гривня. Дізнайся більше про',
    },

    removePopup: {
        titleEnd: 'ми будемо сумувати',
        info1: 'Ти впевнений, що хочеш видалити обліковий запис adidas? У тебе більше не буде доступу до особистої інформації в акаунті: історії замовлень, художнього альбому і статистики прогресу.',
        info2: 'Якщо ти вирішиш видалити свій аккаунт, лист з підтвердженням видалення облікового запису буде відправлено на',
        removeBtn: 'Видалити акаунт',
        cancel: 'Скасувати'
    },

    changeData: {
        title: 'Редагувати дані',
        saveBtn: 'Оновити дані',
        cancelBtn: 'Відмінити',

    },

    changeEmail: {
        title: 'Редагувати Email',
        saveBtn: 'Зберегти зміни',
        cancelBtn: 'Скасувати',
    },

    changePassword: {
        title: 'Змінити пароль',
        saveBtn: 'Зберегти зміни',
        cancelBtn: 'Скасувати',
        oldLabel: 'Старий пароль',
        newLabel: 'Новий пароль',
        errorLength: 'Пароль повинен містити щонайменше 8 символів'
    },

    successRegister: {
        title: 'Залишився один клік',
        text: 'Лист із посиланням підтвердження підписки надіслано на пошту',
        btn: 'ОК',
    },
}
