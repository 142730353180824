import Vue from 'vue';

import scrollOnElement from "~/directives/scroll-on-element";
import mousewheel from "~/directives/mousewheel";
import outerClick from '~/directives/outer-click';
import scrollToBottomDirective from "~/directives/scroll-to-bottom-directive";
import scrollOnWindowDirective from "~/directives/scroll-on-window-directive";
import resizer from "~/plugins/resizer";

Vue.directive('scroll-on-element', scrollOnElement);
Vue.directive('scroll-on-window', scrollOnWindowDirective);
Vue.directive('mousewheel', mousewheel);
Vue.directive('resizer', resizer);
Vue.directive('outer-click', outerClick);
Vue.directive('scroll-to-bottom', scrollToBottomDirective);
Vue.directive('focus', {
    inserted: function (el, binding) {
        const focusNow = (typeof binding.arg === 'boolean') ? binding.arg : true;
        if(process.browser && focusNow) {
            setTimeout(()=>{
                el && el.focus();
                el && el.dispatchEvent(new Event('focus'));
            },150)
        }
    }
})
