//
//
//
//
//
//
//
//
//
//

import CommonRowForForm from "./commonFormRow";
import dateRow from "./dateRow";

export default {
    name: "LazyFormRow",
    model: {
        prop: 'rowData',
        event: 'input'
    },
    props: {
        rowData: {
            type: Object,
            default: function () {
                return {};
            }
        },
        stateName: {
            type: String,
            default: 'Forms'
        }
    },
    components: {
        CommonRowForForm,
        dateRow
    },
    computed: {
        fieldType() {
            return (this.rowData?.view?.fieldType ?? 'Input')
        },
        componentToUse() {
            const showDateRow = this.fieldType.toLocaleLowerCase() == 'date';
            return showDateRow ? 'dateRow' : 'CommonRowForForm';
        }
    },
}
