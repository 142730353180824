export default {
    title: 'Каталог',
    sorting: 'Сортувати',

    filter: {
        title: 'Фільтр',
        moreFilters: 'Більше фільтрів',
        fewerFilters: 'Менше фільтрів',
        appliedTitle: 'Застосовані фільтра',
    },

    forYouQuery: 'За твоїм запитом:',

    // SEARCH
    titleSearch: 'Результат пошуку',

    // PRODUCT CARD
    titleProduct: 'Товар'
}
