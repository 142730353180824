export  default {
    functional: true,
    props: {
        // url for nuxt-link
        to: {
            type: String,
            required: false,
        },
        // Set button to disable mode
        disabled: {
            type: Boolean,
            default: false
        },
        // Set button type (relevant for native form used button): button | submit | reset
        buttonType: {
            type: String,
            default: 'button'
        },
        // Set button aria-label attribute
        ariaLabel: {
            type: String,
            default: ''
        }
    }
}
