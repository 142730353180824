//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "CheckboxItem",
    props: {
        disabled: {
            default: false,
            required: false,
            type: Boolean
        },
        name: {
            required: true,
            type: String
        },
        value: {
            required: true,
            type: [Boolean, Number]
        },

        text: {
            type: String,
            default: ''
        },
        required: {
            type: Boolean,
            default: false
        }
    },
    methods:{}
}
