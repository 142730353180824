export default {
    adidasFrontend: 'Адидас Фронтенд',
    registerSign: 'Зарегистрированый товарный знак',
    text: 'Текст',
    compilationTitle: 'КИТАЙСКИЙ НОВЫЙ ГОД',
    compilationSubtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ',

    forWhom: 'ДЛЯ КОГО ВЫБИРАЕШЬ?',

    // MAN
    titleMan: 'Мужчинам',

    // WOMAN
    titleWoman: 'Женщинам',

    // CHILD
    titleChild: 'Детям',

    // TeaserCardSmall
    TeaserCardLabel1: 'Кроссовки',
    TeaserCardLabel2: 'Брюки',
    TeaserCardLabel3: 'Ветровки и куртки',
    TeaserCardLabel4: 'Толстовки и худи',

    // MainBannerText
    mainBannerTitle: 'HI ENERGY - бегом заряжаться',
    mainBannerSubTitle: 'Ultraboost 21 это новой уровень возврата энергии',

    // SEO TEXT

    seoTextTitle1: 'ADIDAS - ИСТОРИИ, СТИЛЬ, СПОРТИВНАЯ ОДЕЖДА И ОБУВЬ С 1949 ГОДА',
    seoTextTitle2: 'ТВОЙ СПОРТИВНЫЙ МАГАЗИН. ДЛЯ ЛЮБОГО ВИДА СПОРТА',
    seoText1: 'Спорт держит нас в форме. Учит дисциплины. Объединяет нас. Через спорт мы можем менять жизнь. В том числе с помощью вдохновляющих историй спортсменов. Чтобы помочь тебе подняться и двигаться вперед. В спортивных целях используются новейшие технологии, которые помогут тебе достичь лучших результатов. adidas становится домом для бегуна, баскетболиста, юного футболиста и поклонника фитнеса. Того, кто любит покорять горные тропы или начинать свой день с медитации. Музыканты тоже носят три полоски. На сцене, на фестивалях и просто на улицах. Наша спортивная одежда помогает сосредоточиться, когда звучит стартовый свисток. Во время гонки. На финишной черте. Мы здесь, чтобы поддерживать всех, кому важен спорт. Улучшать их игру. Их жизни. И менять мир к лучшему.',
    seoText2: 'adidas создает экипировку для атлетов всех направлений. С теми, кто любит менять ход игры. Бросать вызов условностям. Нарушать правила и устанавливать новые. Затем нарушать их снова. Спортсмены надевают нашу спортивную одежду перед матчем. Чтобы сосредоточиться. И во время соревнований. Чтобы победить. Мы - магазин спорттоваров для женщин, где бра и леггинсы имеют свое предназначение. От низкого до высокого уровня компрессии и поддержки. Мы создаем, обновляем и повторяем. Тестируем новые технологии в действии. На поле, на дорожке, корте и в бассейне. Мы с воодушевлением спортивными силуэтами прошлого, создавая новые модели для городских улиц. Как NMD или спортивные костюмы Firebird. Классические спортивные силуэты возвращаются. Как Stan Smith. И Superstar. Сегодня их можно увидеть на улицах и в медиа.',

    // Subcategories
    subcat1: 'ОБУВЬ',
    subcat2: 'ОДЕЖДА',
    subcat3: 'АКСЕССУАРЫ',
    subcat4: 'НОВЫЕ ПОСТУПЛЕНИЯ',
    subcat5: 'ВСЯ ЖЕНСКАЯ КОЛЛЕКЦИЯ',


    buy: 'КУПИТь',
    readyToWork: 'Готовы к зиме',

}
