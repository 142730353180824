//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex";
import logo from '~/assets/img/adidas-logo.svg?raw';
import MobileMenuListItem from '@/widgets/header/HeaderMain/components/HeaderMobileMenu/components/MobileMenuListItem/index';
import MobileMenuPage from '@/widgets/header/HeaderMain/components/HeaderMobileMenu/components/MobileMenuPage/index';

export default {
    name: "MobileMenuCategories",
    components: {
        MobileMenuListItem,
        MobileMenuPage,
    },
    data() {
        return {
            logo: logo,
        }
    },
    computed: {
        ...mapGetters({
            categories: 'header/getMenuMobile',
            activeCategory: 'header/getMobileMenuActiveCategory',
            links: 'header/getTopLinks',
            isGuest: 'user/isGuest',
            userInfo: 'user/getUserInfo'
        }),
        hasActiveCategory() {
            return !!this.activeCategory;
        },
        loginLink() {
            return this.isGuest ? { url: '/login', title: this.$t('common.login') } :{ url: '/account', title: `${this.$t('account.welcomeHead.hi')}, ${(this?.userInfo?.firstName ?? '')}` };
        }
    },
    methods: {
        setActiveCategory(item) {
            this.$store.dispatch('header/setActiveMobileCategory', item);
        }
    }
}
