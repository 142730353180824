
import common from "./mixin/common";
export default {
    name: "CommonBtnText",
    mixins: [common],
    render: function (createElement, ctx) {
        const isLink = ctx.props.to && ctx.props.to.trim();
        const wrapperTag = isLink ? 'nuxt-link' : 'button';

        const textElement = createElement('div', {class: 'button-text__text'}, ctx.children);
        const button = createElement(wrapperTag, {
            class: [
                'button-text__btn',
                {'button-text__btn--disabled': ctx.props.disabled}
            ],
            style: ctx.data.style,
            attrs: {
                to: isLink && ctx.props.to,
                disabled: !isLink && ctx.props.disabled,
                'aria-label': ctx.props.ariaLabel
            },
            on: {
                click: ctx.listeners.click || (() => {})
            }
        }, [textElement]);
        return createElement('div', {
            class: [
                'button-text',
                ctx.data.staticClass
            ],
            style: ctx.data.style,
        }, [button]);
    }
}
