//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import fieldMixin from "./fieldMixin";

export default {
    name: "SecretCode",
    mixins: [fieldMixin],
    computed: {
        onlyCodeVal() {
            return this.val ? this.val.replace(/[A-Za-zА-Яа-яЁё]/, '') : ''
        }
    },
    methods: {
        updateCode(val) {
            let newVal = val.replace(/[A-Za-zА-Яа-яЁё]/, '');
            this.updateVal(newVal)
        }
    }
}
