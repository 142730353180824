//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "CommonCountdown",
    props: {
        endTime: {
            type: Number,
            default: new Date().getTime(),
        }
    }
}
