//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import fieldMixin from "./fieldMixin";

export default {
    name: "Password",
    mixins: [fieldMixin],
    data: function () {
        return {
            show: false,
        }
    },
    computed: {
        btnText: function () {
            return this.$t(`common.${this.show ? 'hide' : 'show'}`).toLocaleLowerCase()
        },
        fieldType: function () {
            return this.show ? 'text' : 'password'
        }
    },
    methods: {
        changeShowState() {
            this.show = !this.show
        }
    }
}
