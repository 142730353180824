//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import pageMixin from './mixins/page';
import ProductCarousel from "@/widgets/productCarousel/Index";

export default {
    name: "PageNoResults",
    components: {ProductCarousel},
    mixins: [pageMixin],
    data() {
        return {
            widgetData: null
        }
    },
    computed: {
        searchQuery() {
            return (this?.$route?.query?.s ?? null)
        }
    },
    async mounted() {
        let widgetId = 'widget_we_recommend__automatic';
        await this.$axios.post(`${API.main}/widgets`,[
            widgetId
        ]).then(function (response) {
            this.widgetData = { data: response.data[widgetId] }
        }.bind(this))
    }
}
