//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "FooterSmall",
    computed: {
        lang() {
            return this.$i18n.locale
        },
        privacyLink() {
            return this.lang === 'ru' ? '/politika-konfidenczialnosti-programmy-loyalnosti-adidas-universe' : '/politika-konfidenczijnosti-programi-loyalnosti-adidas-universe'
        },
        rightsLink() {
            return this.lang === 'ru' ? '/polozhenie-o-programme-loyalnosti-yunivers' : '/polozhennya-pro-programu-loyalnosti-yunivers'
        }
    }
}
