//
//
//
//
//
//
//
//
//
//
//
//
//

import mediaItem from './components/mediaItem';
import textItem from './components/textItem';

export default {
    name: "MenuColumnFooter",
    components: {
        mediaItem,
        textItem,
    },
    props: {
        footerItems: {
            required: true,
            type: Array
        }
    },
    computed: {
        hasItemsList: function () {
            return this.footerItems.length > 0
        },
        itemsList: function () {
            return this.footerItems.filter((item)=>(!!item.data))
        }
    },
    methods: {
        itemComponentName(type) {
            return `${type.trim()}Item`
        },
    }

}
