//
//
//
//
//
//
//
//

import AppStoreUA from '~/assets/img/apps/AppStore-ua.svg?raw'
import AppStoreRU from '~/assets/img/apps/AppStore-ru.svg?raw'
import GooglePlayUA from '~/assets/img/apps/GooglePlay-ua.svg?raw'
import GooglePlayRU from '~/assets/img/apps/GooglePlay-ru.svg?raw'

export default {
    name: "FooterAppsLink",
    data() {
        return {
            apps: [
                {
                    id: 'AppStore',
                    icon: {
                        ua: AppStoreUA,
                        ru: AppStoreRU
                    },
                    link: 'https://apps.apple.com/ua/app/adidas-ua/id6476918828?l=uk',
                },
                {
                    id: 'GooglePlay',
                    icon: {
                        ua: GooglePlayUA,
                        ru: GooglePlayRU
                    },
                    link: 'https://play.google.com/store/apps/details?id=com.omnicore.adidas.ua',
                },
            ]
        };
    },
    computed: {
        currentLang() {
            return this.$i18n.locale;
        }
    }
};
