var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showRow)?_c('div',{staticClass:"form-row",class:{
        'form-row--focused': _vm.addFocusedClass,
        'form-row--without-shifting': _vm.dontShiftContent,
        'form-row--half-row': _vm.isHalfRow,
        'form-row--half-container': _vm.isHalfContainer,
    },attrs:{"data-row-name":_vm.rowName,"data-row-type":_vm.lowercaseFieldType}},[_c('div',{staticClass:"form-row__container"},[_c('div',{staticClass:"form-row__container__row",class:{
                'form-row__container__row--active': _vm.activeState,
                'form-row__container__row--error': _vm.showErrorUi,
                'form-row__container__row--success': _vm.showSuccessUi
            }},[_c(_vm.formFieldType,{tag:"component",attrs:{"field":_vm.row,"required":_vm.required},on:{"valueChange":_vm.manualSelectedValueUpdate,"focus":_vm.onFocus,"blur":_vm.onBlur,"search":_vm.search}}),_vm._v(" "),(_vm.showLabel)?_c('p',{staticClass:"form-row__container__row__label"},[_vm._v("\n                "+_vm._s(_vm.label)+"\n                "),(_vm.required)?_c('span',{staticClass:"form-row__container__row__label__required-mark"},[_vm._v("*")]):_vm._e()]):_vm._e(),_vm._v(" "),(_vm.validationStateIconCanBeShowed)?[(_vm.showErrorUi)?_c('div',{staticClass:"form-row__container__row-icon-cross",domProps:{"innerHTML":_vm._s(_vm.icons.cross)}}):(_vm.showSuccessUi)?_c('div',{staticClass:"form-row__container__row-icon-check",domProps:{"innerHTML":_vm._s(_vm.icons.check)}}):_vm._e()]:_vm._e()],2),_vm._v(" "),(_vm.showInfoBlock)?_c('div',{staticClass:"form-row__container__info-block"},[(_vm.showDescription)?_c('p',{staticClass:"form-row__container__info-block__description"},[_vm._v("\n                "+_vm._s(_vm.description)+"\n            ")]):_vm._e(),_vm._v(" "),(_vm.showErrorBlock)?_c('div',{staticClass:"form-row__container__info-block__error-wrapper"},[_c('div',{staticClass:"form-row__container__info-block__error-wrapper__inner"},[(_vm.showTemperaryError)?_c('p',{staticClass:"form-row__container__info-block__error-wrapper__text",class:_vm.fieldType},[_vm._v("\n                        "+_vm._s(_vm.errorText)+"\n                    ")]):_vm._e(),_vm._v(" "),(!_vm.dontShiftContent ? _vm.hasPermanentErrorMessage : _vm.hasPermanentErrorMessage && !_vm.showTemperaryError )?_c('p',{staticClass:"form-row__container__info-block__error-wrapper__text error__backend",class:_vm.fieldType},[_vm._v("\n                        "+_vm._s(_vm.permanentErrorMessage)+"\n                    ")]):_vm._e()])]):_vm._e()]):_vm._e()]),_vm._v(" "),_vm._t("default")],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }