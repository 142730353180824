
export  default {
    props: {
        widgetInfo: {
            type: Object,
        },
        layout: {
            type: String,
            default: 'main'
        }
    },
    computed: {
        layoutName() {
            return this.layout+'Layout'
        },
    }
}

