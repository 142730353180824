//
//
//
//
//
//
//
//
//
//
//

import logoUrl from '-!file-loader?esModule=false!~/assets/img/adidas-logo.svg?raw'
import logoOriginalsUrl from '-!file-loader?esModule=false!~/assets/img/icon-adidas-logo-originals.svg?raw'

export default {
    name: "HeaderLogo",
    data() {
        return {
            logoUrl,
            logoOriginalsUrl
        }
    },
    computed:{
        styles(){
            return{
                backgroundImage: `url(${this.logoUrl})`
            }
        },
        mainPageUrl () {
            return this.$i18n.locale === 'ua'? '/' : `/${this.$i18n.locale}`
        }
    }
}
