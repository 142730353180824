//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import pageMixin from './mixins/page';

export default {
    name: "PageError",
    mixins: [pageMixin]
}
