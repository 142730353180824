export default {
    title: 'Регистрация',
    inputPhone: 'Подтверди свой номер телефона, чтобы зарегистрироваться на сайте',
    codeToPhone: 'Код подтверждения отправлен на номер',
    confirmedPhone: {
        number: 'Номер',
        success: 'успешно',
        confirmed: 'подтвержден'
    },
    inputAnotherNumber: 'Ввести другой номер телефона',
    fieldsTitle: {
        personalData: 'Персональные данные',
        birthDate: 'Дата рождения',
        gender: 'Пол',
        loginData: 'Данные для входа в аккаунт'
    },
    more14Years: 'Да, мне больше 14 лет',
    goCaption: {
        text01: 'нажимая кнопку «ПРОДОЛЖИТЬ», я соглашаюсь с',
        text02: 'Правилами програмы лояльности Creators Club',
        text03: 'и даю свое согласие на обработку моих персональных данных согласно',
        text04: 'Политики конфеденциальности adidas.'
    },
    buttons: {
        sendCode: 'Отправить код',
        confirm: 'Подтвердить',
        go: 'Продолжить'
    },
    resend:'Отправить еще раз',
    enterNewPhone: 'Ввести другой номер телефона',
    moreAdidas: {
        text01: 'Хочешь узнать больше про ADIDAS UNIVERSE?',
        text02: 'Читай дальше про программу лояльности'
    },
    passDescription: 'Пароль должен состоять минимум из {length} знаков'
}
