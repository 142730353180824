//
//
//
//
//
//
//
//
//
//
//
//
//

import CommonMedia from "~/components/common/CommonMedia";

export default {
    name: "CommonMediaBackground",

    components: {
        CommonMedia
    },

    props: {
        blockInfo: {
            type: Object,
            require: true,
            default() {
                return {};
            }
        },
        height: {
            type: String,
            default: "100%"
        }
    },

    computed: {},

};
