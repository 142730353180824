//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CommonMedia from "../../../../../../../../../../../components/common/CommonMedia";
import itemMixin from "./itemMixin";

export default {
    name: "MediaItem",
    mixins: [itemMixin],
    components: {CommonMedia},
    computed: {
        fullW: function () {
            return (this?.info?.full ?? false)
        }
    }
}
