import locale6c337502 from '../../locales/ua'
import locale6c3374b9 from '../../locales/ru'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {},
  vueI18nLoader: false,
  locales: [{"name":"Ukrainian","label":"Українська","code":"ua","iso":"uk_UA","iso-639-1":"uk","file":"ua"},{"name":"Russian","label":"Русский","code":"ru","iso":"ru_RU","iso-639-1":"ru","file":"ru"}],
  defaultLocale: "ua",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  strategy: "no_prefix",
  lazy: false,
  langDir: "/opt/atlassian/pipelines/agent/build/locales",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":true,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected_locale","cookieSecure":false,"fallbackLocale":"ua","onlyOnNoPrefix":false,"onlyOnRoot":false,"useCookie":true},
  differentDomains: false,
  seo: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  beforeLanguageSwitch: () => null,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  loadLanguagesAsync: false,
  fallbackLocale: "ua",
  normalizedLocales: [{"name":"Ukrainian","label":"Українська","code":"ua","iso":"uk_UA","iso-639-1":"uk","file":"ua"},{"name":"Russian","label":"Русский","code":"ru","iso":"ru_RU","iso-639-1":"ru","file":"ru"}],
  localeCodes: ["ua","ru"],
}

export const localeMessages = {
  'ua': () => Promise.resolve(locale6c337502),
  'ru': () => Promise.resolve(locale6c3374b9),
}
