import Vue from 'vue';
import VueLazyload from 'vue-lazyload';
import loader from '../assets/img/icons/loader2.svg'

Vue.use(VueLazyload, {
    lazyComponent: true,
    preLoad: 1.6,
    dispatchEvent: true,
    throttleWait: 150,
    observer: true,
    loading: loader,
    observerOptions: {
        rootMargin: '0px',
        threshold: 0.1
    }
});
