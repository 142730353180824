export default async function ({ store, app, $cookies}) {
    const preferenceLang = $cookies.get('preferenceLang');
    const isPreferenceLangSet = !!preferenceLang;
    if(isPreferenceLangSet && preferenceLang !== app.i18n.locale){
        await app.i18n.setLocale(preferenceLang);
    }else if (!isPreferenceLangSet) {
        store.commit(`core/${types.SET_LANG_POPUP_SHOWING_STATE}`, !isPreferenceLangSet)
    }
    Promise.resolve()
}
