//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
export default {
    name: "HeaderIconFavorite",
    computed: {
        ...mapGetters({
            favoriteCount: 'favorite/getFavoriteCount',
            isGuest: 'user/isGuest'
        }),
        favoriteUrl() {
            return this.isGuest ? '/favorites' : '/account/favorites'
        },
        isFavorite() {
            return +this.favoriteCount > 0;
        },
        icon() {
            return this.isFavorite ? 'heartBlack' : 'heart'
        }
    }
}
