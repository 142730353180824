export  default {
    inserted: function (el, binding,vnode) {
        let f = function (evt) {
            if (binding.value(evt, el)) {
                window && window.removeEventListener('scroll', f);
            }
        }
        window && window.addEventListener('scroll', f);
        vnode.context.$emit('reCalc');

    }
}
