//
//
//
//
//
//
//
//
//
//
//
//
//

import FooterTopRow from "./components/FooterTopRow";
import FooterBottomRow from "./components/FooterBottomRow";

export default {
    name: "MainFooter",
    components: {FooterBottomRow, FooterTopRow},
}
