export default {
    cart: 'Корзина',
    addToCart: 'Добавить в корзину',
    addedToCart: 'Добавлено в корзину',
    added: 'Добавлено',
    addLoading: 'Добавляется...',
    freeDelivery: 'Бесплатная доставка заказов на сумму от 2500 грн',
    payOnline: 'СКИДКА 5% при оплате картой на сайте',
    freeDeliveryTemp: 'Бесплатная доставка на все заказы в период с 05.12 до 06.12',
    monobank: 'покупка частями вместе с',
    chooseSize: 'Выбрать размер',
    sizeChart: 'Таблица размеров',
    availableColors: 'Доступные цвета',
    chooseYourSize: 'Выбери свой размер',
    onWarehouse: 'Осталось на складе',
    gallery: 'Галерея',
    description: 'Описание',
    care: 'Уход',
    aboutModel: 'О модели',
    modelColor: 'Цвет модели',
    modelArticle: 'Номер модели',
    careRecommendation: 'Рекомендации по стирке',
    extraCareRecommendation: 'Дополнительная информация по уходу',
    color: 'Цвет',
    size: 'Размер',
    count: 'Количество',
    countShort: 'Кол-во',
    withPDV: '(включая НДС)',
    totalSum: 'Общая сумма',
    totalProduct: 'Всего товаров',
    costProduct: 'Стоимость товаров',
    infoRow: {
        row1: 'указан британский размер, сверься с таблицей размеров',
        row2: 'бесплатная доставка заказов от 2500 грн',
        row3: 'доступные способы доставки: отделение или почтомат “нова пошта”, курьерская доставка',
        row4: '60 дней на возврат товара',
    },
    sizeEmpty: {
        title: 'Товар полностью распродан',
        text: 'К сожалению, на данный момент данного товара нет в наличии.',
    }
}
