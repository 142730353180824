//
//
//
//
//
//
//
//

export default {
    name: "SubscribeAgree",
    props: {
        message: {
            type: String,
            default: ''
        }
    },
    methods: {
        closePopup() {
            this.$popups.close();
        }
    }
}
