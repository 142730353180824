export default {
    fields: {
        phoneNumber: 'Номер телефону',
        fName: 'Ім\'я',
        sName: 'Прізвище',
        mName: 'По-батькові',
        date: 'Дата',
        birthDate: 'Дата народження',
        month: 'Місяць',
        year: 'Рік',
        sex: 'Стать',
        male: 'Чоловіча',
        female: 'Жіноча',
        notGender: 'Не хочу відповідати',
        email: 'Email',
        pass: 'Пароль',
        mobilePhone: 'Мобільний телефон',
        confirmCode: 'Код підтвердження',
        city: 'Місто',
        street: 'Вулиця',
        house: 'Будинок/Корпус',
        flat: 'Квартира',
        warehouse: 'Номер чи адреса відділення'
    },
    errors: {
        enterBirthDate: "Введи дату народження",
        incorrectDate: 'Некоректна дата',
        maxDate: 'Оформлення замовлення можливе тільки особами старше 14 років.',
        enterConfirmCode:'Введи код',
        incorrectConfirmCode:'Некоректний код',
        enterPhone:'Введи номер телефону',
        incorrectPhone:'Некоректний номер телефону',
        enterEmail:'Введи адресу електронної пошти',
        incorrectEmail:'Перевір чи правильна адреса електронної пошти',
        required: 'Поле {field} має бути заповнене',
        requiredConfidentiality: 'Будь-ласка, погодься з нашою Політикою конфіденційності та Умовами продажу',
        number: 'Поле {field} має складатися лише з цифр',
        length: 'Довжина поля {field} має бути {length} знаків',
        maxLength: 'Довжина поля {field} має бути менше {length} знаків',
        minLength: 'Довжина поля {field} має бути більше {length} знаків',
        maxValue: 'Значення поля {field} має бути не більше {value}',
        minValue: 'Значення поля {field} має бути не менше {value}',
        wrongFormat: 'Поле {field} має неправильний формат',
        onlyCyrillic: 'Тільки кирилицею',
        requiredField: 'Обов\'язкове поле',
        wrongDate: 'Некоректна дата',
        chooseDeliveryAddress: 'Оберіть адресу доставки',
        recaptcha: 'Будь ласка, підтвердіть, що Ви не робот',
    }
}
