//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MenuColumnFooter from './components/MenuColumnFooter';
import TextItem from './components/components/textItem';
import MenuColumnItem from './components/MenuColumnItem';

export default {
    name: "MenuColumn",
    components: {
        MenuColumnFooter,
        TextItem,
        MenuColumnItem,
    },
    props: {
        columnKey: {
            required: true,
            type: Number
        },
        columnData: {
            required: true,
            type: Object
        },
        showOnlyFooter: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        showAsSidebar: function () {
            return this.columnData.sidebarView
        },
        footerInfo: function () {
            return this.columnData.footer
        },
        columnItemsList: function () {
            return this.columnData.items
        }
    }
}
