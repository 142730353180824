//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VPopup from "~/plugins/VPopup/popup";

export default {
    name: "LanguageSwitch",
    components: {VPopup},
    data() {
        return {
            curLocale: this.$i18n.locale
        }
    },
    methods: {
        onClose(){
            this.setPreferenceLocaleCookie()
        },
        isLangSelected(localeCode) {
            return localeCode === this.$i18n.locale
        },
        setPreferenceLocaleCookie(){
            this.$store.dispatch('core/setLocaleCookie', this.curLocale);
        },
        async setNewLocale() {
            this.setPreferenceLocaleCookie()
            if (!this.isLangSelected(this.curLocale)) {
                await this.$i18n.setLocale(this.curLocale);
            }
            this.$popups.close();
        }
    }
}
