//
//
//
//
//
//
//
//
//

import pageNotFound from "@/widgets/common/NotFound/NotFound"
import PageNoResults from "@/components/pages/search/PageNoResults";

export default {
    name: 'ErrorPage',
    middleware: ['fetch-dynamic-page-data'],
    head() {
        return {
            htmlAttrs: {
                lang: this?.$i18n?.localeProperties?.["iso-639-1"] ? this.$i18n?.localeProperties?.["iso-639-1"] : "en"
            },
        };
    },

    props: ['error'],
    components: {
        "pageNotFound": pageNotFound,
        "PageNoResults": PageNoResults,
    },

    computed: {
        emptySearchPage () {
            return (this?.error?.emptySearch ?? false)
        }
    }
}
