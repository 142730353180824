export default {
    inserted: function (el, binding) {
        let f = function (evt) {
            if (evt.target.scrollTop >= (evt.target.scrollHeight - evt.target.clientHeight - (parseInt(binding.arg) || 100))) {
                if (binding.value(evt, el)) {
                    el.removeEventListener('scroll', f, true)

                }
            }
        }
        el.addEventListener('scroll', f, true)
    },
}
