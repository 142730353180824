export default {
    save:'Зберегти',
    edit:'Редагувати',
    noResults: 'Немає результатів',
    region: 'обл.',
    district: 'р-н',
    seeAll: 'Дивитися все',
    clear: 'Очистити',
    clearAll: 'Очистити все',
    apply: 'Застосувати',
    show: 'Показати',
    hide: 'Сховати',
    back: 'Назад',
    toCart: 'Дивитись кошик',
    toCheckout: 'Оформити',
    continueShopping: 'Продовжити покупки',
    needHelp: 'Потрібна допомога?',
    contacts: 'Контакти',
    delivery: 'Доставка',
    return: 'Повернення',
    loyalty: 'Програма лояльності',
    continue: 'Продовжити',
    login: 'Увійти',
    colorsCount: 'Кольорів',
    nextPage: 'Наступна',
    prevPage: 'Попередня',
    page: 'Сторінка',
    from: 'з',
    toTop: 'Вгору',
    productCount: {
        1: 'товар',
        2: 'товари',
        3: 'товарів',
    },
    subscribeBtn: 'підписка на новини',
    product: 'Продукти',
    openFullScreen: 'Відкрити на всю сторінку',
    close: 'Закрити',

    sap: {
        title: 'adidas app',
        description: 'спробуй додаток',
        openApp: 'відкрити',
    },

    signUp: {
        blockAgree: {
          title: 'ГОТОВО',
          agreeDesc: 'Підписка успішно оформлена. {0} був доданий в список розсилки.',
        },
        form: {
            title: 'Підпишись та отримай знижку -10% на першу покупку!',
            subtitle: 'Підпишись та дізнавайся першим про нові надходження колекцій',
            sex: 'Стать',
            male: 'Жіноча',
            female: 'Чоловіча',
            noAnswer: 'Не хочу відповідати',
            btn: 'Підписатись',
            info: 'Натискаючи кнопку «Підписатись», ви даєте згоду на обробку персональних даних згідно',
            confidential: 'Політики конфіденційності',
        },
        agree: {
            title: 'Залишився один клік',
            text: 'Лист з посиланням для підтвердження підписки відправлено на пошту',
            btn: 'Ок',
        },
        thanks: {
            title: 'Дякуємо за підписку',
            text: 'Тепер ти будеш отримувати новини та розсилки від adidas. Перевіряй свою поштову скриньку, щоб дізнатися про майбутні релізи.'
        },
    },

    days: {
        Monday: 'ПН',
        Tuesday: 'ВТ',
        Wednesday: 'СР',
        Thursday: 'ЧТ',
        Friday: 'ПТ',
        Saturday: 'СБ',
        Sunday: 'НД',
    },
    weekend: 'Вихідний',
    askQuestion: 'Поставити запитання',
    soldLabelText: 'Розпродано',
}
