import './assets/style/popup.scss';
import scroll from '~/components/common/CommonScroll'

export default {
    name: 'v-popup',
    componentName: 'v-popup',
    components: {scroll},
    props: {
        title: {
            type: String,
            default: ''
        },
        className: {
            type: String,
            default: ''
        },
        isScroll: {
            type: Boolean,
            default: false
        },
        verticalScrolling: {
            type: Boolean,
            default: true
        },
        escapable: {
            type: Boolean,
            default: false
        },
        dismissable: {
            type: Boolean,
            default: true
        },
        isTop: {
            type: Boolean,
            default: false
        },
        isBottom: {
            type: Boolean,
            default: false
        },
        isLeft: {
            type: Boolean,
            default: false
        },
        isRight: {
            type: Boolean,
            default: false
        },
        isBottomTablet: {
            type: Boolean,
            default: false
        },
        isTopTablet: {
            type: Boolean,
            default: false
        },
        customStyle: {
            type: Object,
            default: function () {
                return {}
            }
        }
    },
    mounted() {
        this.$liveChat && this.$liveChat.hide();
        if (this.$el.focus) {
            this.$el.focus()
        }
    },
    destroyed() {
        if (this.isScroll) {
        }
    },
    computed: {
        isVPopup() {
            return (this.$parent.$vnode.data.props && this.$parent.$vnode.data.props.vPopup)
        },
        propsData() {
            return this.$props
        }
    },
    methods: {
        closeEvent() {
            this.$emit('close');
            this.isVPopup && this.$parent.$emit('close');
            this.$liveChat && this.$liveChat.show();
        },
    },
    render(h) {
        const {dismissable, title, isScroll, isTop, isBottom, isLeft, isRight, center, className, isBottomTablet, isTopTablet} = this.propsData;

        const closeBtn = dismissable
            ? h('div', {
                class: 'v-popup__container__close-btn',
                on: {
                    click: () => {
                        this.closeEvent();
                    }
                }
            })
            : null;

        const headerContent = this.$slots.header
            ? this.$slots.header
            : title
                ? h('span', {class: ['v-popup__container__header__title']}, title)
                : null;

        const header = headerContent
            ? h('div', {
                class: ['v-popup__container__header']
            }, [headerContent])
            : null;

        const body = h('div', {
            class: ['v-popup__container__body']
        }, [
            h('div', {class: 'v-popup__container__scroll', props: {verticalScrolling: this.verticalScrolling}}, [this.$slots.default])
        ]);

        const footer = this.$slots.footer
            ? h('div', {
                class: ['v-popup__container__footer']
            }, [this.$slots.footer])
            : null;


        return h('div', {
                class: [
                    'v-popup__outer-container',
                    {
                        'v-popup__outer-container--is-top': isTop,
                        'v-popup__outer-container--is-bottom': isBottom,
                        'v-popup__outer-container--is-left': isLeft,
                        'v-popup__outer-container--is-right': isRight,
                        'v-popup__outer-container--is-top-right': isRight && isTop,
                        'v-popup__outer-container--is-top-left': isLeft && isTop,
                        'v-popup__outer-container--is-bottom-left': isLeft && isBottom,
                        'v-popup__outer-container--is-bottom-right': isRight && isBottom,
                        'v-popup__outer-container--is-bottom-tablet': isBottomTablet,
                        'v-popup__outer-container--is-top-tablet': isTopTablet
                    }
                ],
                on: {
                    click: () => {
                        this.closeEvent();
                    }
                }
            },
            [
                h('div', {
                    attrs: {tabindex: -1},
                    class: ['v-popup__container',
                        className
                    ],
                    style: this.customStyle,
                    on: {
                        click: (event) => {
                            event.stopPropagation()
                        }
                    }
                }, [
                    closeBtn,
                    header,
                    body,
                    footer
                ])
            ]);
    }
};
