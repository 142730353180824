export default {
    subscribePopup: {
        title: "Підтверди електронну адресу, щоб отримати повідомлення про наявність розміру",
        description: "Підтвердьте свою електронну адресу, щоб отримувати повідомлення про розмір. Якщо він вже підтверджений, то залишай email, а ми відправимо тобі листа, як тільки твій розмір з'явиться у наявності.",
        sizeTitle: "Розмір",
        agreementText: "Я підтверджую, що мені вже виповнилося 16 років",
        submitBtnText: "Підписатися",
    },
    successEmailSubscribe: {
        title: "Готово! Ти отримаєш email, коли твій розмір з'явиться на сайті",
        description: "",
        btnText: "Продовжити покупки",
    },
    needConfirmEmailSubscribe: {
        title: "Чекаємо на підтвердження електронної адреси",
        description: "Перевір свою пошту: ми відправили лист з підтвердженням підписки.",
        btnText: "Продовжити покупки",
    },
    errorEmailSubscribe: {
        title: "Виникла помилка",
        description: "Спробуйте скористатися функціоналом пізніше",
        btnText: "Продовжити покупки",
    },
};
