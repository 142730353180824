//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import flagUA from '~/assets/img/icons/flag-ua.svg?raw';
import CommonScroll from '@/components/common/CommonScroll';

export default {
    name: "MobileMenuPage",
    components: {
        CommonScroll,
    },
    data() {
        return {
            flagUA: flagUA
        }
    }
}
