//
//
//
//
//
//
//
//
//
//
//

import SubscribeForm from "@/components/popups/SubscribePopup/Components/SubscribeForm";
import SubscribeAgree from "@/components/popups/SubscribePopup/Components/SubscribeAgree";
import SubscribeThanks from "@/components/popups/SubscribePopup/Components/SubscribeThanks";
import VPopup from "@/plugins/VPopup/popup";
import {mapGetters} from "vuex";
export default {
    name: "SubscribePopup",
    components: {VPopup, SubscribeForm, SubscribeAgree, SubscribeThanks},
    props: {
        formId: {
            type: String,
            default: ''
        },
        source: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
            step: 1,
            message: ''
        }
    },
    computed: {
        ...mapGetters({
            isSubscribed: 'user/isSubscribed',
            subscribeData: 'header/getSubscribeData',
        }),
        SubscribeComponent() {
            switch (this.step) {
                case 3:
                    return 'SubscribeThanks';
                case 2:
                    return 'SubscribeAgree';
                default:
                    return 'SubscribeForm'
            }
        },
    },
    methods: {
        changeStep(data) {
            this.step = data.step;
            this.message = data.message
        }
    },
    mounted() {
        if(!process.browser) {
            return
        }
        if (this.isSubscribed) {
            this.changeStep({step: 3})
        }
    },
    beforeDestroy() {
        if (this.source === 'pop_up') {
            sessionStorage.setItem('canShowSubscribe', 2);
        }
    }
}
