
export const state = () => ({
    globalSearch: '',
    searchResults: [],
    searchHints: []
});

export const getters = {
    getGlobalSearch: ({ globalSearch }) => globalSearch,
    getHints: ({ searchHints }) => searchHints,
};

export const actions = {
    async fetchSearchResults ({commit}, request) {
        //TODO change after create API
        await new Promise((resolve) => {
            setTimeout(() => { resolve() }, 500)
        });
        commit(types.SET_SEARCH_GLOBAL_REQUEST, request);
        //TODO change after create API
    },
    async fetchHints ({commit}, input) {
        try {
            const { data } = await this.$axios.$get(`${api.testApi}search/hints.json`);
            commit(types.SET_SEARCH_HINTS, data.items);
        } catch (err) {
            throw err
        }
    }
};

export const mutations = {
    [types.SET_SEARCH_GLOBAL_REQUEST] (state, data) {
        state.globalSearch = data
    },
    [types.SET_SEARCH_HINTS] (state, data) {
        state.searchHints = data
    }
};
