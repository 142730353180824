//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ResponsiveImage from "@/components/common/ResponsiveImage";
export default {
    name: "SearchRecommendationProductCard",
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    components: {ResponsiveImage},
    methods: {
        imageUrl(image) {
            return ImageHelper.getMediaStructure(image.id, AppConfig.image.fileExtensions.productCard, this.item.displayName, this.item.displayName, ImageSizes.productCarousel, 100)
        },
    }
}
