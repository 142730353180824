//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import mainLayout from "./layouts/Main";
import widgetMixin from "@/mixins/widgetMixin";
import breakpoints from "~/utils/breakpoints";
import SectionBackgroundContainer from "~/components/common/section/SectionBackgroundContainer";

export default {
    name: "ProductCarousel",

    components: {
        mainLayout,
        SectionBackgroundContainer,
    },

    mixins: [widgetMixin],

    props: {
        slideWidth: {
            type: Array,
            default: () => {
                return [254, 226, 131];
            }
        },
    },

    data() {
        return {
            bgHeight: "305px",
        };
    },

    methods: {
        resizeCallback() {
            this.bgHeight = (window.innerWidth < breakpoints.tabletSmall)
                ? "242px"
                : "305px";
        }
    },

    mounted() {
        this.resizeCallback();
    }
};
