//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';

export default {
    name: "HeaderIconBag",

    computed: {
        ...mapGetters({
            inCartCount: 'cart/getCartLength',
        }),
        size() {
            return 48;
        }
    },

    watch:{
        inCartCount(newVal, oldVal){
            this.$store.dispatch('cart/getCart');
        },
    },
}
