export default {
    title: 'Каталог',
    sorting: 'Сортировать',

    filter: {
        title: 'Фильтр',
        moreFilters: 'Больше фильтров',
        fewerFilters: 'Меньше фильтров',
        appliedTitle: 'Примененные фильтра',
    },

    forYouQuery: 'По твоему запросу:',

    // SEARCH
    titleSearch: 'Результат поиска',

    // PRODUCT CARD
    titleProduct: 'Товар'
}
