//
//
//
//
//
//
//
//
//
//
//
//

import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
name: "Carousel",
    components: {VueSlickCarousel},
    props: {
        settings: {
            type: Object,
            required: true
        },
        length: {
            type: Number,
            required: true
        },
        windowWidth: {
            type: Number,
            required: true
        },
        topArrow: {
            type: String,
            default: 'calc(50% + 13px)'
        }
    },
    data() {
        return {
            activeSlide: 0,
        }
    },
    computed: {
        hasArrows() {
            return this.windowWidth > 1025;
        },
        showPrevBtn() {
            return this.hasArrows && this.activeSlide > 0
        },
        showNextBtn() {
            return this.hasArrows && this.activeSlide + 4 < this.length
        }
    },
    methods: {
        showNext() {
            this.$refs.carousel.next()
        },
        showPrev() {
            this.$refs.carousel.prev()
        },
        beforeChange(oldIndex, newIndex) {
            this.activeSlide = newIndex
        },
    },
}
