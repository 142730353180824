export default {
    orderThank: 'Дякуємо, ваша заявка відправлена',
    confirmSize:{
        title: 'Перевірте обраний розмір',
        subtitle: 'Обраний розмір не можна буде змінити чи обміняти в магазині після підтверждення',
        changeSize: 'Змінити розмір',
        confirmSize: 'Підтвердити',
    },
    sizePlaceholder: 'Обери розмір',
    apply: 'Відправити заявку',
    returnConditionsTitle: 'Умови повернення',
    popupPhone: {
        title: 'Підтвердити номер телефону',
        inputPlaceholderPhone: 'Мобільний телефон',
        inputPlaceholderCode: 'Код підтвердження',
        codeSendToNumber: 'Код підтвердження відправлений на номер',
        buttonSend: 'Надіслати код',
        buttonConfirm: 'Підтвердити',
        buttonBreak: 'Скасувати',
        resend: 'Відправити ще раз',
        errorRequired: 'Поле має бути заповнене',
        errorPhone: 'Телефон введено неправильно',
        errorCode: 'Неправильний код підтвердження',
        errorCodeLength: 'Довжина коду підтвердження має складати {length} символи(ів)'
    },
    fieldsTitle: {
        personalData: 'Персональні дані',
        birthDate: 'Дата народження',
        gender: 'Стать',
        loginData: 'Дані для входу в акаунт'
    },
    chooseYourSize: 'Обери свій розмір',
    confirmPhone: 'Підтвердіть номер телефону',
    hasRequest: 'Ви вже відправляли заявку на участь',
}
