import SearchInput from "../SearchInput";
import { mapActions, mapGetters } from 'vuex';

export default {
    components: {SearchInput},
    data() {
        return {
            input: '',
            loading: false
        }
    },
    computed: {
        ...mapGetters({
            getGlobalSearch: 'search/getGlobalSearch'
        })
    },
    methods: {
        ...mapActions({
            fetchSearchResults: 'search/fetchSearchResults'
        }),
        async onSearch({resolve, value}) {
            try {
                this.loading = true;
                await this.fetchSearchResults(value);
                resolve();
            } catch(err) {
                console.error(err)
            } finally {
                this.loading = false;
            }
        }
    }
}
