import esEvent from '~/assets/js/eSputnik/esEvent';

export default function searchRequestEvent(data) {
    const eventName = 'SearchRequest';

    if (typeof data !== 'string') {
        console.warn('Wrong data type', eventName, data);
    }

    sendEvent({
        [eventName]: {
            search: data
        }
    });

    function sendEvent(eventBody) {
        esEvent(eventName, eventBody);
    }
}
