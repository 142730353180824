import Bus from './utils/bus';
import ModalWrapper from './wrapper';
import Modal from './popup'

const VPopup = {}
VPopup.install = (Vue) => {
  Vue.prototype.$popups = new Vue({
    name: '$popups',

    created() {
      Bus.$on('opened', data => {
        this.$emit('popups:opened', data);
      });

      Bus.$on('closed', data => {
        this.$emit('popups:closed', data);
      });

      Bus.$on('destroyed', data => {
        this.$emit('popups:destroyed', data);
      });

      this.$on('new', options => {
        this.open(options);
      });

      this.$on('close', data => {
        this.close(data);
      });

      this.$on('dismiss', index => {
        this.dismiss(index || null);
      });
    },

    methods: {
      open(options = null) {
        Bus.$emit('new', options);
      },

      close(data = null, saveState = false) {
        Bus.$emit('close',{
            data,
            saveState
        });
      },

      dismiss(index = null) {
        Bus.$emit('dismiss', index);
      }
    }
  });

  Vue.mixin({
    created() {
      this.$on('popups:new', options => {
        Bus.$emit('new', options);
      });

      this.$on('popups:close', data => {
        Bus.$emit('close', data);
      });

      this.$on('popups:dismiss', index => {
        Bus.$emit('dismiss', index);
      });
    }
  });
}

export default VPopup;

export {
  ModalWrapper,
  Modal,
  Bus
}
