//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex";
import backIcon from '~/assets/img/icons/arrow-left.svg?raw'
import MobileMenuListItem from '@/widgets/header/HeaderMain/components/HeaderMobileMenu/components/MobileMenuListItem/index';
import MobileMenuPage from '@/widgets/header/HeaderMain/components/HeaderMobileMenu/components/MobileMenuPage/index';

export default {
    name: "MobileMenuColumns",
    components: {
        MobileMenuListItem,
        MobileMenuPage,
    },
    data() {
        return {
            backIcon: backIcon,
        }
    },
    computed: {
        ...mapGetters({
            activeCategory: 'header/getMobileMenuActiveCategory',
            activeColumn: 'header/getMobileMenuActiveColumn'
        }),
        hasActiveCategory() {
            return !!this.activeCategory;
        },
        hasActiveColumn() {
            return !!this.activeColumn;
        }
    },
    methods: {
        clearCategory() {
            setTimeout(function () {
                this.$store.dispatch('header/setActiveMobileCategory', null);
            }.bind(this), 500)
        },
        setActiveColumn(item) {
            this.$store.dispatch('header/setActiveMobileColumn', item);
        }
    }
}
