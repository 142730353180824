import Vue from 'vue';

import CommonContainer from '~/components/common/CommonContainer';
import CommonRow from '~/components/common/CommonRow';
import CommonTitle from '~/components/common/CommonTitle';
import CommonHeadline from '~/components/common/CommonHeadline';
import CommonText from '~/components/common/CommonText';
import MainBtn from '~/components/common/buttons/MainBtn';
import FullArrowBtn from '~/components/common/buttons/FullArrowBtn';
import ShortArrowBtn from '~/components/common/buttons/ShortArrowBtn';
import SimpleBtn from '~/components/common/buttons/SimpleBtn';
import SecondaryBtn from '~/components/common/buttons/SecondaryBtn';
import LinkBtn from '~/components/common/buttons/LinkBtn';
import EyeBtn from '~/components/common/buttons/EyeBtn';
import SizeBtn from '~/components/common/buttons/SizeBtn';
import FavoriteBtn from '~/components/common/buttons/FavoriteBtn';
import IconBtn from '~/components/common/buttons/IconBtn';
import vueAwesomeCountdown from 'vue-awesome-countdown';
import VueMarqueeSlider from "vue-marquee-slider";

Vue.component('CommonContainer', CommonContainer);
Vue.component('CommonRow', CommonRow);
Vue.component('CommonTitle', CommonTitle);
Vue.component('CommonHeadline', CommonHeadline);
Vue.component('CommonText', CommonText);
Vue.component('MainBtn', MainBtn);
Vue.component('FullArrowBtn', FullArrowBtn);
Vue.component('ShortArrowBtn', ShortArrowBtn);
Vue.component('SimpleBtn', SimpleBtn);
Vue.component('SecondaryBtn', SecondaryBtn);
Vue.component('LinkBtn', LinkBtn);
Vue.component('EyeBtn', EyeBtn);
Vue.component('SizeBtn', SizeBtn);
Vue.component('FavoriteBtn', FavoriteBtn);
Vue.component('IconBtn', IconBtn);
Vue.use(vueAwesomeCountdown, 'vac');
Vue.use(VueMarqueeSlider)
