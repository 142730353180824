export default function (state) {
    return function (value) {
        if (state && (typeof value === 'string' ? value.trim() !== '' : !!value)) {
            let date = value.toString().match(/.{1,3}/g),
                today = new Date(),
                nowYear = today.getFullYear().toString().match(/.{1,2}/g);
            return state &&
                (
                    parseInt(date[0]) <= 12 &&
                    (
                        parseInt(date[1]) > parseInt(nowYear[1]) ||
                        ( parseInt(date[0]) >= today.getMonth()+1 && parseInt(date[1]) === parseInt(nowYear[1]) )
                    )
                )
        } else {
            return false
        }
    }
}
