//
//
//
//
//
//
//
//
//
//
//
//
//

import fieldMixin from "./fieldMixin";

import CheckboxItem from './components/CheckboxItem'

export default {
    name: "Input",
    mixins: [fieldMixin],
    components: {CheckboxItem},
    computed: {
        label: function () {
            return this.field && this.field.texts && this.field.texts.label || ''
        }
    }
}
