export default {
    adidasFrontend: 'Адідас Фронтенд',
    registerSign: 'Зареєстрований товарний знак',
    text: 'Текст',
    compilationTitle: 'КИТАЙСЬКИЙ НОВИЙ РІК',
    compilationSubtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ',

    forWhom: 'ДЛЯ КОГО  ОБИРАЄШ?',

    // MAN
    titleMan: 'Чоловікам',

    // WOMAN
    titleWoman: 'Жінкам',

    // CHILD
    titleChild: 'Дітям',

    // TeaserCardSmall
    TeaserCardLabel1: 'Кросівки',
    TeaserCardLabel2: 'Штани',
    TeaserCardLabel3: 'Куртки',
    TeaserCardLabel4: 'Толстовки і худі',

    // MainBannerText
    mainBannerTitle: 'HI ENERGY - бігом заряджатися',
    mainBannerSubTitle: 'Ultraboost 21 це новій рівень повернення енергії',

    // SEO TEXT

    seoTextTitle1: 'ADIDAS - ІСТОРІЇ, СТИЛЬ, СПОРТИВНИЙ ОДЯГ І ВЗУТТЯ З 1949 РОКУ',
    seoTextTitle2: 'ТВІЙ СПОРТИВНИЙ МАГАЗИН. ДЛЯ БУДЬ-ЯКОГО ВИДУ СПОРТУ',
    seoText1: 'Спорт тримає нас у формі. Вчить дисципліни. Об\'єднує нас. Через спорт ми можемо змінювати життя. В тому числі за допомогою надихаючих історій спортсменів. Щоб допомогти тобі піднятися і рухатися вперед. У спортивних товарах використовуються новітні технології, які допоможуть тобі досягти кращих результатів. adidas стає домом для бігуна, баскетболіста, юного футболіста і шанувальника фітнесу. Того, хто любить підкоряти гірські стежки або починати свій день з медитації. Музиканти теж носять три смужки. На сцені, на фестивалях і просто на вулицях. Наша спортивний одяг допомагає зосередитися, коли звучить стартовий свисток. Під час гонки. На фінішній межі. Ми тут, щоб підтримувати всіх, кому важливий спорт. Покращувати їх гру. Їх життя. І змінювати світ на краще.',
    seoText2: 'adidas створює екіпірування для атлетів всіх напрямків. З тими, хто любить змінювати хід гри. Кидати виклик умовностям. Порушувати правила і встановлювати нові. Потім порушувати їх знову. Спортсмени надягають нашу спортивний одяг перед матчем. Щоб зосередитися. І під час змагань. Щоб перемогти. Ми - магазин спорттоварів для жінок, де бра і легінси мають своє призначення. Від низького до високого рівня компресії і підтримки. Ми створюємо, оновлюємо і повторюємо. Тестуємо нові технології в дії. На поле, на доріжці, корті і в басейні. Ми з натхненням спортивними силуетами минулого, створюючи нові моделі для міських вулиць. Як NMD або спортивні костюми Firebird. Класичні спортивні силуети повертаються. Як Stan Smith. І Superstar. Сьогодні їх можна побачити на вулицях і в медіа.',

    // Subcategories
    subcat1: 'ВЗУТТЯ',
    subcat2: 'ОДЯГ',
    subcat3: 'АКСЕСУАРИ',
    subcat4: 'НОВІ НАДХОДЖЕННЯ',
    subcat5: 'ВСЯ ЖІНОЧА КОЛЕКЦІЯ',


    buy: 'КУПИТи',
    readyToWork: 'Готові до зими',

}
