//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import icons from './icons';
import { mapGetters } from 'vuex';

export default {
    name: 'HeaderUspBottom',
    props: {
        isOpen: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            icons
        };
    },
    computed: {
        UspDataWidgets() {
            return (this?.macroPageStructure?.widget ?? []);
        },
        UspDataWidgetMacro() {
            return this.UspDataWidgets.find(i => i.name === 'widget_usp_header_data') || false;
        },
        UspDataWidgetMicro() {
            return this.UspDataWidgetMacro ? this.microPageStructure.find(i => i.id === this.UspDataWidgetMacro.id) : null;
        },
        widgetData() {
            return (this?.UspDataWidgetMicro?.data ?? null);
        },
        hasData() {
            return !!this.widgetData?.title || !!this.widgetData?.description;
        },
        backgroundColor() {
            return (this.widgetData?.background || '#EBEDEE');
        },
        textColor() {
            return (this.widgetData?.textColor || '#000000');
        },

        ...mapGetters({
            macroPageStructure: 'dynamicPage/getMacroPageStructure',
            microPageStructure: 'dynamicPage/getMicroPageStructure',
        }),
    },
    methods: {
        onClose() {
            this.$emit('close');
        }
    }
};
