//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import createFieldConfig from "./components/createFieldConfig";
import commonFormRow from "./commonFormRow";

export default {
    name: "DateRow",
    props: {
        rowData: {
            type: Object,
            default: function () {
                return {};
            }
        },
        stateName: {
            type: String,
            default: 'Forms'
        }
    },
    data: function () {
        return {
            rowDataCopy: null,
            changeWasEmitted : false,
            dayField: null,
            monthField: null,
            yearField: null,
        }
    },
    computed: {
        rowName: function () {
            return (this.rowData?.name ?? '')
        },
        finalDate() {
            return `${(this.dayField?.value ?? '')}-${(this.monthField?.value ?? '')}-${(this.dayField?.value ?? '')}`
        },
        allDateFilled() {
            return this.dayField.state.filled && this.monthField.state.filled && this.yearField.filled
        },

        showInfoBlock: function () {
            return this.hasDescription || this.showError
        },
        hasDescription: function () {
            return this.rowDataCopy.texts.hasOwnProperty('description') && this.descriptionMessage.trim().length > 0
        },
        descriptionMessage: function () {
            return (this.rowDataCopy?.texts?.description ?? '')
        },

        showError() {
            return this.hasError || this.hasPermanentError;
        },
        hasError() {
            return (this.rowDataCopy?.error?.show ?? false) && this.errorMessage !== '';
        },
        errorMessage(){
            return (this.rowDataCopy?.error?.text ?? '')
        },
        hasPermanentError (){
            return this.permanentError !== ''
        },
        permanentError(){
            return (this.rowDataCopy?.error?.permanentText ?? '')
        },
    },
    methods: {
        updateDateVal(){
            const year = (this.yearField?.value ?? '');
            const month = (this.monthField?.value ?? '');
            const day = (this.dayField?.value ?? '');
            const yearVal = year;
            const monthVal = (+month > 9 || isNaN(+month) || +month === 0)? month : `0${+month}`;
            const dayVal = (+day > 9 || isNaN(+day) || +day === 0)? day : `0${+day}`;
            const valToSet = `${yearVal}-${monthVal}-${dayVal}`
            this.rowDataCopy = {
                ...this.rowDataCopy,
                value: valToSet,
                selectedValue: valToSet,
            }
            this.$emit('input', JSON.parse(JSON.stringify(this.rowDataCopy)))
        },
        updateDay() {
            this.updateMonth()
        },
        updateMonth() {
            if (this.monthField.state.validated) {
                const year = this.yearField.state.filled ? this.yearField.value : (new Date()).getFullYear();
                const maxCountInSelectedYear = (new Date(year, this.monthField.value , 0, 0, 0, 0, 0)).getDate();
                const maxDayValidation = this.dayField.validateSettings.find((el) => el.name === 'maxValue');
                maxDayValidation && (maxDayValidation.value = maxCountInSelectedYear);
            }
        },
        updateYear() {
            if (this.yearField.state.validated) {
                this.updateMonth()
            }
        },
        onInput(e) {
            this.changeWasEmitted = true;
            this.$emit('input', e)
        },
        onValueChange(e) {
            this.$emit('valueChange', e)
        },
        updateDateInput(val) {

            const splitDate = (val || '').split(/[-.]/);
            const year = (splitDate?.[0] ?? '');
            const month = (splitDate?.[1] ?? '');
            const day = (splitDate?.[2] ?? '');

            this.yearField.value = year;
            this.yearField.selectedValue = year;
            this.monthField.value = month;
            this.monthField.selectedValue = month;
            this.dayField.value = day;
            this.dayField.selectedValue = day;
        }
    },
    watch: {
        'rowData': {
            handler(val, oldVal) {
                const changeWasEmitted = (this.changeWasEmitted ?? false);
                !changeWasEmitted && (this.rowDataCopy = JSON.parse(JSON.stringify(val)));
                this.updateDateInput((val?.value ?? ''))
                this.changeWasEmitted = false;

            },
            deep: true
        }

    },
    components: {
        commonFormRow,
    },
    created() {
        const splitDate = (this.rowData?.value ?? '').split(/[-.]/);
        const formName = `${Math.floor(Math.random() * 100)}_${Date.now()}` || 'birthdayField';

        this.rowDataCopy = JSON.parse(JSON.stringify(this.rowData));
        this.dayField = createFieldConfig({
            name: 'day',
            formName,
            fieldType: 'Number',
            label: this.$t('form.fields.date'),
            value: (splitDate?.[2] ?? ''),
            view: Object.assign(
                JSON.parse(JSON.stringify(this.rowData.view)),
                {
                    inputLength: {
                        max: 2
                    }
                }
            ),
            state: this.rowDataCopy.state,
            validateSettings: [
                {
                    name: "required",
                    value: true,
                    text: ''
                },
                {
                    name: "minValue",
                    value: 1,
                    text: ''
                },
                {
                    name: "maxValue",
                    value: 31, // set 31 as default during  data verification this value will be changed
                    text: ''
                },
            ]
        });
        this.monthField = createFieldConfig({
            name: 'month',
            formName,
            fieldType: 'Number',
            label: this.$t('form.fields.month'),
            value: (splitDate?.[1] ?? ''),
            view: Object.assign(
                JSON.parse(JSON.stringify(this.rowData.view)),
                {
                    inputLength: {
                        max: 2
                    }
                }
            ),
            state: this.rowDataCopy.state,
            validateSettings: [
                {
                    name: "required",
                    value: true,
                    text: ''
                },
                {
                    name: "minValue",
                    value: 1,
                    text: ''
                },
                {
                    name: "maxValue",
                    value: 12,
                    text: ''
                },
            ]
        });
        this.yearField = createFieldConfig({
            name: 'year',
            formName,
            fieldType: 'Number',
            label: this.$t('form.fields.year'),
            value: (splitDate?.[0] ?? ''),
            view: Object.assign(
                JSON.parse(JSON.stringify(this.rowData.view)),
                {
                    inputLength: {
                        max: 4
                    }
                }
            ),
            state: this.rowDataCopy.state,
            validateSettings: [
                {
                    name: "required",
                    value: true,
                    text: ''
                },
                {
                    name: "minValue",
                    value: (new Date()).getFullYear() - 100,
                    text: ''
                },
                {
                    name: "maxValue",
                    value: (new Date()).getFullYear(),
                    text: ''
                },
            ]

        })
    },
    beforeDestroy() {
        // this.dayField = null
        // this.monthField = null
        // this.yearField = null
        // this.rowDataCopy = null
    }
}
