export default {
    title: 'Реєстрація',
    inputPhone: 'Підтверди свій номер телефону, щоб зареєструватися на сайті',
    codeToPhone: 'Код підтвердження відправлений на номер',
    confirmedPhone: {
        number: 'Номер',
        success: 'успішно',
        confirmed: 'підтверджений '
    },
    inputAnotherNumber: 'Ввести інший номер телефону',
    fieldsTitle: {
        personalData: 'Персональні дані',
        birthDate: 'Дата народження',
        gender: 'Стать',
        loginData: 'Дані для входу в акаунт'
    },
    more14Years: 'Так, мені більше 14 років',
    goCaption: {
        text01: 'Натискаючи кнопку «ПРОДОВЖИТИ», я погоджуюсь з',
        text02: 'Правилами програми лояльності Creators Club',
        text03: 'і даю свою згоду на обробку моїх персональних даних відповідно до',
        text04: 'Політики конфіденційності adidas.'
    },
    buttons: {
        sendCode: 'Надіслати код',
        confirm: 'Підтвердити',
        go: 'Продовжити'
    },
    resend:'Відправити ще раз',
    enterNewPhone: 'Ввести інший номер телефону',
    moreAdidas: {
        text01: 'Хочеш дізнатися більше про ADIDAS UNIVERSE?',
        text02: 'Читай далі про програму лояльності'
    },
    passDescription: 'Пароль повинен містити щонайменше {length} знаків'
}
