//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import fieldMixin from "./fieldMixin";
import MaskInput from "./components/MaskInput";

export default {
    name: "Number",
    mixins: [fieldMixin],
    data: function () {
        return {
            numberPattern: /^[0-9]/,
            zeroCharNumberPattern: /^[1-9]/,
        }
    },
    methods: {
        setVal: function (e) {
            const val = e.target.value;
            let newVal = '';
            var i = 0;
            while (i < val.length) {
                const notFirstChart = i === 1;
                const pattern = this.numberPattern;
                if(notFirstChart) {
                    if (+val.charAt(i) == 0 && +val.charAt(i - 1) == 0) {
                        i++;
                        continue;
                    }
                }
                newVal = newVal + (pattern.test(val.charAt(i)) ? val.charAt(i) : '')
                i++;
            }
            newVal !== val && (e.target.value = newVal)
            this.updateVal(newVal)

        }
    },
    components: {
        MaskInput
    }
}
