//
//
//
//
//
//

import CommonSubtitle from "../CommonSubtitle";
export default {
    name: "SectionSubtitle",
    components: {CommonSubtitle}
}
