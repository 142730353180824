
export default {
    name: "CommonTitle",
    functional: true,

    render: function (createElement, {data, props, children}) {
        return createElement('div', {
            class: ['common-title', data.staticClass],

        }, [children]);
    }
}
