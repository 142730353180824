//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
    name: "RadioItem",
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        name: {
            type: String,
            required: true
        },
        value: {
            type: [Object, String, Number],
            default: null,
            validator: (p) => {
                return ['string', 'number', 'object'].indexOf(typeof p) !== -1 || p === null;
            },
        },
        item: {
            type: Object,
            required: true
        },
        text: {
            type: String,
            default: ''
        },
        isChecked: {
            type: Boolean,
            default: false
        }
    },
}
