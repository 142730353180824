//
//
//
//
//
//
//
//
//
//
//
//
//

import notFound from "@/components/pages/search/PageError"
import ProductCarousel from "@/widgets/productCarousel/Index";

export default {
    name: "NotFound",
    components: {ProductCarousel, notFound},
    data() {
        return {
            widgetData: null
        }
    },
    async mounted() {
        let widgetId = 'widget_we_recommend__automatic';
        await this.$axios.post(`${API.main}/widgets`,[
            widgetId
        ]).then(function (response) {
            this.widgetData = { data: response.data[widgetId] }
        }.bind(this))
    }
}
