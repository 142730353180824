export default {
    Home: "Головна",
    home: "Головна",
    storefinder: "Пошук магазину",
    cart: "Кошик",
    "order-tracker": "Відстежити замовлення",
    favorites: "Обрані моделі",
    registration: "Реєстрація",
    login: "Вхід в профіль",
}
