
export default {
    name: "CommonRow",
    functional: true,
    props: {
        // Set horizontal align (start, center, end, space-between, space-around, space-evenly)
        justify: {
            type: String,
            default: 'start'
        },

        // Set vertical align (start, center, end, baseline, stretch)
        verticalAlign: {
            type: String,
            default: 'start'
        },

        // Set element height to 100%
        fillHeight: {
            type: Boolean,
            default: false
        },

        // Allow elements wrapping
        wrap: {
            type: Boolean,
            default: false
        },

        // Set row direction to column
        column: {
            type: Boolean,
            default: false
        }
    },
    render: function (createElement, {data, props, children, listeners}) {
        const align = () => {
            if (props.verticalAlign === 'center') return 'center';
            if (props.verticalAlign === 'end') return 'flex-end';
            if (props.verticalAlign === 'baseline') return 'baseline';
            if (props.verticalAlign === 'stretch') return 'stretch';
            return 'flex-start'
        };
        const justify = () => {
            if (props.justify === 'center') return 'center';
            if (props.justify === 'end') return 'flex-end';
            if (props.justify === 'space-between') return 'space-between';
            if (props.justify === 'space-around') return 'space-around';
            if (props.justify === 'space-evenly') return 'space-evenly';
            return 'flex-start'
        };
        const elementData = {
            class: [
                'common-row',
                data.staticClass,
                {
                    'common-row--wrap': props.wrap,
                    'common-row--column': props.column,
                    'common-row--fill-height': props.fillHeight
                }
            ],
            style: {
                alignItems: align(),
                justifyContent: justify()
            },
            on: Object.assign({},listeners,{
                click: listeners.click || ((e) => e),
            }),
            ...data
        };
        return createElement('div', elementData, children);
    }
}
