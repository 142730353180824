//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import fieldMixin from "./fieldMixin";

export default {
    name: "Email",
    mixins: [fieldMixin],
}
