import types from './mutation-types';
import _set from '~/utils/_set';

export const namespaced = true;
export const state = () => {
    return {
        rows: {},
        validateFormNow: false,

    }
};

const isFilled = function (rowData) {
    return typeof rowData.value === 'boolean' || !!(rowData.value && (rowData.value).toString().length > 0);
}
export const mutations = {
    [types.ADD_ROW_TO_FORM](state, {id, row}) {
        const rowParse = JSON.parse(JSON.stringify(row));
        _set(rowParse, 'state.filled', isFilled(rowParse));
        state.rows = {...state.rows, [id]: rowParse};
        return row
    },
    [types.UPDATE_ROW_DATA](state, {id, row}) {
        const rowData = JSON.parse(JSON.stringify(row));
        _set(rowData, 'state.filled', isFilled(rowData));
        state.rows = {...state.rows, [id]: rowData}
    },
    [types.REMOVE_ROW](state, id) {
        delete state.rows[id]
    },
    [types.VALIDATE_FORM](state, data) {
        state.validateFormNow = data
    }
}

export const getters = {
    rowsArr: function (state) {
        return Object.values(state.rows)
    },
    count: (state, {rowsArr}) => rowsArr.length,
    getOneRow: ({rows}) => id => rows[id],
    getValidateFormNow: ({validateFormNow}) => validateFormNow,
    rows: ({rows}) => rows,
    keyValueData(state, getters) {
        return getters.rowsArr.reduce(function (acc, item,) {
            acc[item.name] = item.value;
            return acc;
        }, {})
    },
    getKeyValueDataForFieldsList(state, getters) {
        // fieldsList - array of field names
        return fieldsList => {
            return getters.rowsArr.reduce(function (acc, item,) {
                fieldsList.includes(item.name) && (acc[item.name] = item.value);
                return acc;
            }, {})
        }
    },
    allValidated: (state, {rowsArr}) => rowsArr.every((row) => (row.state.validated)),
    allRequiredFieldNames: (state, {rowsArr}) => rowsArr.filter((row) => ((row?.state?.requiredForSubmit ?? true))).map((item) => (item.name)),
    allRequiredValidated: (state, {rowsArr}) => rowsArr.every((row) => {
        return (row?.state?.requiredForSubmit ?? true) ? row.state.validated : true
    }),
    allRequiredAndNotEmptyValidated: (state, {rowsArr}) => rowsArr.every((row) => {
        const isRequireForSubmit = (row?.state?.requiredForSubmit ?? true);
        if(isRequireForSubmit){
            return row.state.validated;
        }else if(row?.value) {
            return row.state.validated;
        }
        return true;
    }),
    formValidating: (state, {rowsArr}) => rowsArr.some((row) => row.state.validating),
};

export const actions = {
    [types.ADD_ROW]({state, dispatch, commit}, row) {
        commit(types.ADD_ROW_TO_FORM, {
            id: row.id,
            row: JSON.parse(JSON.stringify(row))
        });
    },
    setValidateFormState({state, getters, dispatch, commit}, val = true) {
        commit(types.VALIDATE_FORM, val)
    },

};
