
import icons from "./components/icons";
import common from "./mixin/common";

export default {
    name: "EyeButton",
    mixins: [common],
    props: {
        // Set close eye view
        close: {
            type: Boolean,
            default: false
        }
    },
    render: function (createElement, {data, props, children, listeners}) {
        const isLink = props.to && props.to.trim();
        const wrapperTag = isLink ? 'nuxt-link' : 'button';

        const iconElement = createElement('div', {
            domProps: {innerHTML: props.close ? icons['eyeClose'] : icons['eye']},
            class: 'button-eye__icon'
        });
        const textElement = createElement('div', {class: 'button-eye__text-wrapper'}, [
            createElement('div', {class: 'button-eye__text'}, children)
        ]);
        const button = createElement(wrapperTag, {
            class: [
                'button-eye__btn',
                {'button-eye__btn--disabled': props.disabled}
            ],
            attrs: {
                to: isLink && props.to,
                disabled: !isLink && props.disabled,
                'aria-label': props.ariaLabel
            },
            on: listeners
        }, [
            iconElement,
            textElement
        ]);
        return createElement('div', {
            class: [
                'button-eye',
                data.staticClass
            ],
        }, [button]);
    }
}
