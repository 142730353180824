//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import fieldMixin from "./fieldMixin";

export default {
    name: "Input",
    mixins: [fieldMixin],
}
