
export const state = () => ({
    storesList: [],
    currentStore: {},
    pageInfo: {}
});

export const getters = {
    getActiveStore: ({ currentStore }) => currentStore,
    getStoresList: ({ storesList }) => storesList,
    hasMorePage: ({ pageInfo }) => pageInfo.totalPages > (pageInfo.number + 1),
    getCurrentPage: ({ pageInfo }) => pageInfo.number,
};

export const actions = {
    async fetchStoresData ({commit}, params) {
        let lang = this.$i18n.locale;
        try {
            let link = `${API.main}/store-finder/stores-near-location?businessModel=Own%20Retail&lifecycleStatus=Open&ssmFields=LOCATION%2CBUSINESS_HOURS%2CCAPACITY_SERVICES%2CCONSUMER&branding=adidas%2Cnone%2CMultibrand&category=Permanent%20Selling%20Store%2CTemporary%20Selling%20Store&page=${params.page}&pageSize=10`;
            let data = await this.$axios.$post(link,params.body, {
                auth: {username:'adidas', password: 'omnicore'},
                headers: {'Accept-Language': Helpers.getLocaleIso(this.$i18n, lang)}
            });
            if (params.page === 0) {
                commit(types.CLEAR_STORE_LIST);
            }
            if (data.page.totalElements > 0) {
                commit(types.SET_STORE_LIST, data.content);
            }
            commit(types.SET_STORES_PAGE_INFO, data.page);
        } catch (err) {
            throw err
        }
    },
    async fetchActiveStore ({commit}, ssmId) {
        let lang = this.$i18n.locale;
        try {
            let data = await this.$axios.$get(`${API.main}/store-finder/get-store?ssmId=${ssmId}&ssmFields=LOCATION%2CIDENTIFIERS%2CBUSINESS_HOURS%2CCAPACITY_SERVICES%2CCONSUMER%2CRELATIONSHIPS`, {
                auth: {username:'adidas', password: 'omnicore'},
                headers: {'Accept-Language': Helpers.getLocaleIso(this.$i18n, lang)}
            });
            commit(types.SET_ACTIVE_STORE, data);
        } catch (err) {
            throw err
        }
    },
};

export const mutations = {
    [types.CLEAR_STORE_LIST] (state) {
        state.storesList = [];
    },
    [types.SET_STORE_LIST] (state, data) {
        state.storesList = state.storesList.concat(data);
    },
    [types.SET_ACTIVE_STORE] (state, data) {
        state.currentStore = data
    },
    [types.SET_STORES_PAGE_INFO] (state, data) {
        state.pageInfo = data
    },
};
