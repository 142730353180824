import { mapGetters } from "vuex";

export default {

    computed: {
        ...mapGetters({
            breadcrumbs: "dynamicPage/breadcrumbsData",
        }),

        breadcrumbListStructureData() {
            if (this.breadcrumbs && this.breadcrumbs.length) {
                return this.generateBreadcrumbsStructure(this.breadcrumbs);
            } else {
                const pathArray = this.$route.path.split("/");
                pathArray.shift();
                if (pathArray.length && pathArray[0] === "account") {
                    return "";
                }
                const generatedArr = this.getPagesRouteBreadcrumbs(pathArray);
                if (generatedArr.length) {
                    generatedArr.unshift({
                        url: "/",
                        label: this.$t("breadcrumbs.Home"),
                    });
                    return this.generateBreadcrumbsStructure(generatedArr);
                }
            }
            return "";
        },
    },

    methods: {
        getPagesRouteBreadcrumbs(pathArray) {
            return pathArray.reduce((breadcrumbArray, path, idx) => {
                breadcrumbArray.push({
                    url: breadcrumbArray[idx - 1]
                        ? breadcrumbArray[idx - 1].to + "/" + path
                        : "/" + path,
                    label: this.$t(`breadcrumbs.${path}`),
                });
                return breadcrumbArray;
            }, []);
        },

        generateBreadcrumbsStructure(breadcrumbs) {
            if (!breadcrumbs || !breadcrumbs.length) {
                return "";
            }
            const items = breadcrumbs.map((item, idx) => {
                const breadItem = {};
                breadItem["@type"] = "ListItem";
                breadItem["position"] = idx + 1;
                breadItem["name"] = item.label;
                if (idx === 0) {
                    breadItem["item"] = `${ this.$config?.baseURL }`;
                } else if ((breadcrumbs.length - 1) !== idx) {
                    breadItem["item"] = `${ this.$config?.baseURL }${ item.url }`;
                }
                return breadItem;
            });
            return {
                type: "application/ld+json",
                json: {
                    "@context": "https://schema.org",
                    "@type": "BreadcrumbList",
                    "itemListElement": items
                }
            };
        }
    },
};
