import { appConfig } from "~/utils/config";
import logoUrl from "-!file-loader?esModule=false!~/assets/img/adidas-logo.svg?raw";

export default {
    data() {
        return {
            logoStructuredData: {
                type: "application/ld+json",
                json: {
                    "@context": "https://schema.org/",
                    "@type": "OnlineStore",
                    "url": `${ appConfig?.mainInfo?.globalInfo?.url }`,
                    "name": "adidas",
                    "alternateName": ["адидас","адідас"],
                    "brand": "adidas",
                    "description": `${ this.$t('static.schema.description') }`,
                    "address": {
                        "@type": "PostalAddress",
                        "addressLocality": `${ this.$t('static.schema.addressLocality') }`,
                        "streetAddress": `${ this.$t('static.schema.streetAddress') }`,
                        "postalCode":"03067",
                        "addressCountry":"UA"
                    },
                    "contactPoint": [{
                        "@type":"ContactPoint",
                        "telephone": "+380800212232",
                        "email": "support@ua.adidas.com",
                        "contactType":"customer service"
                    }],
                    "logo": `${ this.$config?.baseURL }${ logoUrl }`,
                    "sameAs":[
                        `${ appConfig?.mainInfo?.socialBlock?.instagram?.link }`,
                        `${ appConfig?.mainInfo?.socialBlock?.facebook?.link }`,
                        `${ appConfig?.mainInfo?.socialBlock?.youtube?.link }`,
                    ]
                }
            }
        };
    }
};
