//
//
//
//
//
//
//
//

    import HeaderMobileMenu from '@/widgets/header/HeaderMain/components/HeaderMobileMenu/index';
    import HeaderIconMenu from './HeaderIconMenu';
    import HeaderIconFavorite from './HeaderIconFavorite';

export default {
    name: "HeaderMobileContainer",
    components: {
        HeaderMobileMenu,
        HeaderIconMenu,
        HeaderIconFavorite,
    }
}
