
function getMediaSrc(id, extension = 'jpg', size, quality = 100) {
    return `${AppConfig.image.mediaUrl}/w_${size}/q_${quality}/${id}.${extension}`
}

export default {
    getMediaStructure(imageId, extension, title, alt, {desktop, desktop2x, tablet, tablet2x, mobile, mobile2x}, quality = 100) {
        return {
            src: {
                desktop: {
                    '1x': getMediaSrc(imageId, extension, desktop, quality),
                    '2x': getMediaSrc(imageId, extension, desktop2x || desktop, quality)
                },
                tablet: {
                    '1x': getMediaSrc(imageId, extension, tablet, quality),
                    '2x': getMediaSrc(imageId, extension, tablet2x || tablet, quality)
                },
                mobile: {
                    '1x': getMediaSrc(imageId, extension, mobile, quality),
                    '2x': getMediaSrc(imageId, extension, mobile2x || mobile, quality)
                }
            },
            title: title,
            alt: alt,
        }
    },
}
