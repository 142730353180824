//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MainHeader from "~/widgets/header";
import thankYou from "@/components/popups/SubscribePopup/thankYou";
import subscribePopupCallback from "@/mixins/subscribePopupCallback";
import logoStructuredData from "~/mixins/structuredData/logoStructuredData";
import breadcrumbListStructureData from "~/mixins/structuredData/breadcrumbListStructureData";
import searchActionStructuredData from "~/mixins/structuredData/searchActionStructuredData";
import LazyHydrate from "vue-lazy-hydration";
import LiveChat from "../components/common/LiveChat";
import { mapGetters } from "vuex";
import FooterSmall from "@/widgets/footerSmall/index";
import LoginAndCart from "~/widgets/loginAndCart/index";
import MainSubscribe from "~/widgets/subscribe/index";
import MainFooter from "~/widgets/footer";
import PopupsWrapper from "~/plugins/VPopup/wrapper";
import InfoPopup from "~/components/common/infoPopup";
import axios from "~/plugins/axios";
import Bus from "~/plugins/VPopup/utils/bus";


export default {
    middleware: ['fetch-dynamic-page-data'],

    head() {
        return {
            htmlAttrs: {
                lang: this?.$i18n?.localeProperties?.["iso-639-1"] ? this.$i18n?.localeProperties?.["iso-639-1"] : "en"
            },
            title: this.pageTitle,
            meta: this.metaWithAppStore.map(el => {
                const data = {};
                for (let k in el){
                    data[k] = el[k]
                }
                data.hid = data.name || data.property || '';
                return data
            }),
            link: this.metaLinks.map(el => {
                const data = {};
                for (let k in el){
                    data[k] = el[k]
                }
                return data
            }),
            script: this.computedStructuredDataScripts
        };
    },

    components: {
        LiveChat,
        LazyHydrate,
        FooterSmall,
        LoginAndCart,
        MainSubscribe,
        MainFooter,
        MainHeader,
        PopupsWrapper,
    },

    mixins: [
        subscribePopupCallback,
        logoStructuredData,
        breadcrumbListStructureData,
        searchActionStructuredData,
    ],

    data() {
        return {
            liveChatOpen: false,
        };
    },

    computed: {
        ...mapGetters({
            pageType: "dynamicPage/pageType",
            metaLinks: 'dynamicPage/metaLinks',
            metaTags: 'dynamicPage/metaTags',
            pageTitle: 'dynamicPage/pageTitle',
            isGuest: 'user/isGuest'
        }),

        fullLink() {
            return `https://www.adidas.ua${this.$route.path}`
        },
        metaWithAppStore() {
            return this.metaTags.concat({
                name:"apple-itunes-app",
                content:`app-id=6476918828, app-argument=${this.fullLink}`,
                hid:"apple-itunes-app",
            })
        },
        computedStructuredDataScripts() {
            return this.pageType === "main" ?
                [
                    this.logoStructuredData,
                    this.searchActionStructuredData,
                ] :
                [
                    this.logoStructuredData,
                    this.breadcrumbListStructureData,
                ];
        },

        itsCart() {
            return !!["cart"].find(i => i === this.$route.name);
        },
        itsCheckout() {
            return !!["delivery", "payment", "confirmation"].find(i => i === this.$route.name);
        },
        route() {
            return this.$route.name;
        },
        currentLang() {
            return this.$i18n.locale;
        },
    },

    methods: {
        async getESToken() {
            return new Promise((resolve, reject) => {
                this.$axios.post(`${API.main}/customer/appinbox`).then((response) => {
                    resolve(response.data.token);
                }).catch((e) => {
                    resolve("");
                });
            });
        },
        showThankYouPopup() {
            if (+this.$route.query.showIsConfirmedPopup === 1) {
                this.$popups.open({
                    component: thankYou
                });
            } else if (this.$route.query.wishListConfirmationPopup === "1") {
                setTimeout(() => {
                    this.$popups.close();
                    this.$popups.open({
                        component: InfoPopup,
                        props: {
                            title: { text: this.$t("waitList.successEmailSubscribe.title"), },
                            description: { text: this.$t("waitList.successEmailSubscribe.description"), },
                            submitButton: {
                                text: this.$t("waitList.successEmailSubscribe.btnText"),
                                clickCallback: this.$popups.close,
                            }
                        }
                    });
                }, 0);
            }
        },
        initES() {
            if (process.browser) {
                this.$nextTick(function () {
                    window.eS('init', {APP_INBOX: true}, {getAuthTokenCallback: () =>this.getESToken(),  language: this.currentLang, })
                });
            }
        },
        reInitES() {
            if (process.browser) {
                window['eS']('reInitAppInbox', {
                    language: this.currentLang,
                    timeZone: 'Europe/Kiev',
                    getAuthTokenCallback: () =>this.getESToken()
                });
            }
        }
    },

    watch: {
        route: {
            handler() {
                this.showThankYouPopup();
            }
        },
        isGuest: {
            handler() {
                this.reInitES()
            }
        }
    },

    mounted() {
        this.$store.dispatch("user/fetchUserData");
        this.showThankYouPopup();
        setTimeout(() => {
            // this.$liveChat && this.$liveChat.init();
            this.liveChatOpen = true;
        }, 12000);
        this.initES();
    }
};
